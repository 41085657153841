import { ProfileAddPaymentObjectPayload } from 'state/account/account.services';

import { detectCardType, getCreditCardEnding, getMonthNum, getNameParts } from 'util/payments';
import { removeLastSpace } from 'util/string';

interface MappedPayment {
    cardActive: boolean;
    cardFirstName: string;
    cardLastName: string;
    cardMiddleName?: string;
    defaultCard: 'Y' | 'N' | boolean;
    cardMonthNum: number | string;
    epostPatientNum: string | number;
    cardMonthDesc: string;
    cardNumber: string;
    cardSeqNum: string;
    secureCardNumber: string;
    cardTypeNum: string;
    cardType: string;
    cardYear: string;
    pciToken: string;
    cardExpiration: string;
    cardName: string;
    cardIsExpired: boolean;
    forceApproval: boolean;
    fsaCard?: boolean;
    securityCode?: string;
    spendingLimit?: number | string;
    conversionLink?: string | null;
    companyCard?: string | null;
    lastUsed?: string | null;
}

interface MappedEasyRefillPayment extends MappedPayment {
    epostPatientNum: string | number;
}

export const EasyRefillPaymentMapper = (
    values: ProfileAddPaymentObjectPayload,
    epostPatientNum: string | number,
    pciToken: string,
    hasPaymentData: boolean
): MappedEasyRefillPayment => {
    const names = removeLastSpace(values.nameOnCard).split(' ');
    const { firstName, middleName, lastName } = getNameParts(names) || {};

    // Prepare the credit card object
    const creditCard: MappedEasyRefillPayment = {
        cardActive: true,
        cardFirstName: firstName || '',
        cardLastName: lastName || '',
        cardMiddleName: middleName || '',
        defaultCard: hasPaymentData ? false : true,
        epostPatientNum,
        cardMonthNum: String(getMonthNum(values.expMonth)),
        cardMonthDesc: values.expMonth,
        cardNumber: pciToken,
        cardSeqNum: '-1',
        secureCardNumber: `${getCreditCardEnding(values.cardNumber, '**************')}`,
        cardTypeNum: `${detectCardType(values.cardNumber)?.index}`,
        cardType: `${detectCardType(values.cardNumber)?.key}`,
        cardYear: values.expYear.toString(),
        pciToken,
        cardExpiration: `${getMonthNum(values.expMonth)}/${values.expYear.toString()}`,
        cardName: values.nameOnCard,
        cardIsExpired: false,
        forceApproval: true,
        fsaCard: values.fsaCard,
        securityCode: '',
        spendingLimit: String(149.99),
        conversionLink: null,
        companyCard: null,
        lastUsed: null
    };

    return creditCard;
};

export const PaymentMapper = (
    values: ProfileAddPaymentObjectPayload,
    mode: 'easyRefill' | 'account',
    additionalData: {
        epostPatientNum?: string | number;
        pciToken: string;
        hasPaymentData: boolean;
        hasMembership?: boolean;
    }
): MappedPayment => {
    const names = removeLastSpace(values.nameOnCard).split(' ');
    const { firstName, middleName, lastName } = getNameParts(names) || {};

    switch (mode) {
        case 'easyRefill':
            return EasyRefillPaymentMapper(
                values,
                additionalData.epostPatientNum!,
                additionalData.pciToken,
                additionalData.hasPaymentData
            );

        case 'account':
            if (additionalData.hasMembership) {
                return {
                    cardSeqNum: values.cardNumber.slice(0, 6),
                    cardYear: values.expYear.toString(),
                    epostPatientNum: additionalData.epostPatientNum!,
                    cardNumber: additionalData.pciToken,
                    cardTypeNum: detectCardType(values.cardNumber)?.index || '',
                    cardFirstName: firstName || '',
                    cardLastName: lastName || '',
                    cardMiddleName: middleName || '',
                    securityCode: undefined,
                    cardActive: true,
                    forceApproval: true,
                    spendingLimit: 149.99,
                    secureCardNumber: '',
                    conversionLink: null,
                    pciToken: additionalData.pciToken,
                    companyCard: null,
                    fsaCard: values.fsaCard,
                    lastUsed: null,
                    cardType: `${detectCardType(values.cardNumber)?.key || ''}`,
                    cardMonthNum: Number(getMonthNum(values.expMonth)),
                    cardMonthDesc: values.expMonth,
                    cardExpiration: `${getMonthNum(values.expMonth)}/${values.expYear.toString()}`,
                    cardName: values.nameOnCard,
                    cardIsExpired: false,
                    defaultCard: additionalData.hasPaymentData ? 'N' : 'Y'
                };
            } else {
                return {
                    cardActive: true,
                    cardFirstName: getNameParts(names)?.firstName || '',
                    cardLastName: getNameParts(names)?.lastName || '',
                    cardMiddleName: getNameParts(names)?.middleName || '',
                    // For BRD01 patients or Insured patients, the property is a boolean instead of a string,
                    // this was causing a bug, making every new payment as a default everytime that the user calls the API.
                    defaultCard: additionalData.hasPaymentData ? false : true,
                    epostPatientNum: additionalData.epostPatientNum!,
                    cardMonthNum: Number(getMonthNum(values.expMonth)),
                    cardMonthDesc: values.expMonth,
                    cardNumber: additionalData.pciToken,
                    cardSeqNum: '-1',
                    secureCardNumber: `${getCreditCardEnding(values.cardNumber, '**************')}`,
                    cardTypeNum: `${detectCardType(values?.cardNumber)?.index}`,
                    cardType: `${detectCardType(values?.cardNumber)?.key}`,
                    cardYear: values.expYear.toString(),
                    pciToken: additionalData.pciToken,
                    cardExpiration: `${getMonthNum(values.expMonth)}/${values.expYear.toString()}`,
                    cardName: values.nameOnCard,
                    cardIsExpired: false,
                    forceApproval: true,
                    fsaCard: values.fsaCard,
                    securityCode: '',
                    spendingLimit: 149.99,
                    conversionLink: null,
                    companyCard: null,
                    lastUsed: null
                };
            }

        default:
            throw new Error(`Unsupported mode: ${mode}`);
    }
};
