import { noop } from 'lodash';
import React, { forwardRef, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'ui-kit-v2/icon/icon';
import TextInputComponent from 'ui-kit-v2/text-input/text-input';

import { transformTextToId } from 'util/string';

import { usePlatform } from 'hooks/usePlatform';

import { DropdownProps } from './dropdown.props';
import './dropdown.styles.scss';

const DropdownComponent = forwardRef<HTMLInputElement, DropdownProps>(
    (
        {
            id,
            options,
            onSearch,
            onSelect,
            label,
            name,
            error,
            success,
            optionNotFoundText,
            contentLeft,
            contentRight,
            onClear,
            variant = 'default',
            onlyNumbers = false,
            onlyLetters = false,
            disabled = false,
            readOnly = false,
            canSearch = false,
            defaultValue = null,
            ...props
        },
        ref
    ) => {
        const { t } = useTranslation();
        const { value, onChange, onBlur, ...restProps } = props;
        const [open, setOpen] = useState(false);
        const [selectedValue, setSelectedValue] = useState<string | number | object | null>(
            defaultValue?.toString() || null
        );
        const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
        const [searchTerm, setSearchTerm] = useState<string>('');
        const [focusedIndex, setFocusedIndex] = useState<number>(-1);
        const optionRefs = useRef<(HTMLLIElement | null)[]>([]);
        const dropdownId = useMemo(() => name || transformTextToId(label), [name, label]);
        const dropdownRef = useRef<HTMLDivElement>(null);
        const selectRef = useRef<HTMLSelectElement>(null);

        const { isMobileDevice } = usePlatform();

        const toggleDropdown = useCallback(() => {
            if (!readOnly && !disabled) {
                setOpen((prev) => !prev);
            }
        }, [readOnly, disabled]);

        const openDropdown = useCallback(() => {
            if (selectedValue) {
                const selectedIndex = options.findIndex((option) => {
                    if (typeof selectedValue === 'object') {
                        return JSON.stringify(option.value) === JSON.stringify(selectedValue);
                    }
                    return option.value.toString() === selectedValue.toString();
                });

                setFocusedIndex(selectedIndex);

                setTimeout(() => {
                    optionRefs.current[selectedIndex]?.scrollIntoView({ block: 'nearest' });
                }, 0);
            } else {
                setFocusedIndex(-1);
            }
            setOpen(true);
        }, [selectedValue, options]);

        const closeDropdown = useCallback(() => {
            setOpen(false);
        }, []);

        const handleClearValues = () => {
            setSelectedValue(null);
            setSelectedLabel(null);
            setSearchTerm('');
            closeDropdown();
            onClear?.();
        };

        const handleSelect = useCallback(
            (value: string | number | object, label: ReactNode) => {
                const labelText = extractTextFromReactNode(label);
                setSelectedValue(value);
                setSelectedLabel(labelText.trim());

                const syntheticEvent = {
                    target: {
                        name: name,
                        value: value
                    }
                };

                onChange?.(syntheticEvent as any);
                onSelect?.(value);
                closeDropdown();
            },
            [onChange, onSelect, closeDropdown, name]
        );

        const highlightMatch = (label: ReactNode, searchTerm?: string): ReactNode => {
            if (!searchTerm) return label;

            const recursivelyHighlight = (node: ReactNode): ReactNode => {
                if (typeof node === 'string') {
                    const regex = new RegExp(`(${searchTerm})`, 'gi');
                    const parts = node.split(regex);
                    return parts.map((part, index) => (regex.test(part) ? <strong key={index}>{part}</strong> : part));
                }

                if (Array.isArray(node)) {
                    return node.map((child, index) => <span key={index}>{recursivelyHighlight(child)}</span>);
                }

                if (typeof node === 'object' && node !== null && 'props' in node) {
                    return React.cloneElement(node, { ...node.props }, recursivelyHighlight(node.props.children));
                }

                return node;
            };

            return recursivelyHighlight(label);
        };

        const handleInputChange = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                if (canSearch) {
                    const query = event.target.value;
                    setSearchTerm(query);
                    onSearch?.(query);
                }
            },
            [canSearch, onSearch]
        );

        const extractTextFromReactNode = (node: ReactNode): string => {
            if (typeof node === 'string') {
                return node;
            }

            if (Array.isArray(node)) {
                return node.map(extractTextFromReactNode).join(' ');
            }

            if (typeof node === 'object' && node !== null && 'props' in node) {
                return extractTextFromReactNode(node.props.children);
            }

            if (typeof node === 'number') {
                return String(node);
            }

            return '';
        };

        const filteredOptions = useMemo(() => {
            if (!canSearch || searchTerm.trim() === '') {
                return options;
            }

            return options.filter((option) => {
                const labelText = extractTextFromReactNode(option.label).toLowerCase();
                const optionValue = option.value.toString().toLowerCase();
                const searchTermLower = searchTerm.toLowerCase();

                return labelText.includes(searchTermLower) || optionValue.includes(searchTermLower);
            });
        }, [options, searchTerm, canSearch]);

        const handleBlur = useCallback(
            (event: React.FocusEvent<HTMLInputElement>) => {
                const optionPartialMatch = options.find((option) => {
                    const labelText = extractTextFromReactNode(option.label).toLowerCase();
                    const optionValue = option.value.toString().toLowerCase();
                    const searchTermLower = searchTerm.toLowerCase();

                    return labelText.includes(searchTermLower) || optionValue.includes(searchTermLower);
                });

                if (optionPartialMatch && searchTerm) {
                    handleSelect(optionPartialMatch.value, optionPartialMatch.label);
                }

                const syntheticBlurEvent = {
                    target: {
                        name: name,
                        value: optionPartialMatch?.value || null
                    }
                };

                onBlur?.(syntheticBlurEvent as any);

                if (!dropdownRef.current?.contains(event.relatedTarget as Node)) {
                    closeDropdown();
                }

                setSearchTerm('');
            },
            [closeDropdown, options, searchTerm, selectedValue, handleSelect, onBlur, name]
        );

        const handleKeyDown = useCallback(
            (event: React.KeyboardEvent) => {
                if (event.key === 'ArrowDown') {
                    event.preventDefault();
                    if (!open) {
                        openDropdown();
                    }
                    setFocusedIndex((prevIndex) => {
                        const newIndex = prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0;
                        optionRefs.current[newIndex]?.scrollIntoView({ block: 'nearest' });
                        return newIndex;
                    });
                } else if (event.key === 'ArrowUp') {
                    event.preventDefault();
                    if (!open) {
                        openDropdown();
                    }
                    setFocusedIndex((prevIndex) => {
                        const newIndex = prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1;
                        optionRefs.current[newIndex]?.scrollIntoView({ block: 'nearest' });
                        return newIndex;
                    });
                } else if (event.key === 'Enter' && focusedIndex >= 0 && open) {
                    event.preventDefault();
                    const option = filteredOptions[focusedIndex];
                    if (option) {
                        handleSelect(option.value.toString(), option.label);
                    }
                }
            },
            [filteredOptions, focusedIndex, handleSelect, open, openDropdown]
        );

        useEffect(() => {
            const handleTabKey = (event: KeyboardEvent) => {
                if (event.key === 'Tab') {
                    setTimeout(() => {
                        if (!dropdownRef.current?.contains(document.activeElement)) {
                            closeDropdown();
                        }
                    }, 0);
                }
            };

            const handleFocusOut = (event: FocusEvent) => {
                if (!dropdownRef.current?.contains(event.relatedTarget as Node)) {
                    closeDropdown();
                }
            };

            if (open) {
                document.addEventListener('keydown', handleTabKey);
                document.addEventListener('focusout', handleFocusOut);
            }

            return () => {
                document.removeEventListener('keydown', handleTabKey);
                document.removeEventListener('focusout', handleFocusOut);
            };
        }, [open, closeDropdown]);

        useEffect(() => {
            const handleOutsideClick = (event: MouseEvent) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                    closeDropdown();
                }
            };

            if (open) {
                document.addEventListener('mousedown', handleOutsideClick);
            } else {
                document.removeEventListener('mousedown', handleOutsideClick);
            }

            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }, [open, closeDropdown]);

        useEffect(() => {
            if (defaultValue && !selectedValue) {
                const defaultOption = options.find((option) => option.value === defaultValue);
                if (defaultOption) {
                    setSelectedValue(defaultOption.value.toString());

                    const defaultLabelText = extractTextFromReactNode(defaultOption.label);
                    setSelectedLabel(defaultLabelText);
                }
            }
        }, [defaultValue, selectedValue, options]);

        useEffect(() => {
            if (defaultValue && !selectedValue) {
                const defaultOption = options.find((option) => {
                    if (typeof defaultValue === 'object') {
                        return JSON.stringify(option.value) === JSON.stringify(defaultValue);
                    }

                    return option.value === defaultValue;
                });

                if (defaultOption) {
                    setSelectedValue(defaultOption.value);

                    const defaultLabelText = extractTextFromReactNode(defaultOption.label);
                    setSelectedLabel(defaultLabelText);
                }
            }
        }, [defaultValue, selectedValue, options]);

        const handleInputClick = useCallback(() => {
            if (isMobileDevice && selectRef.current) {
                selectRef.current.click(); // Trigger native select on mobile
            }
        }, [isMobileDevice]);

        if (isMobileDevice && !canSearch) {
            return (
                <div className="dropdown-component">
                    <TextInputComponent
                        aria-labelledby={label ? `${dropdownId}-label` : undefined}
                        ref={ref}
                        id={dropdownId}
                        label={label}
                        name={name}
                        value={
                            canSearch && open
                                ? searchTerm
                                : selectedLabel ||
                                  (typeof selectedValue === 'number' ? selectedValue.toString() : '') ||
                                  ''
                        }
                        onFocus={handleInputClick}
                        onChange={canSearch ? handleInputChange : noop}
                        onBlur={handleBlur}
                        error={error}
                        success={success}
                        onlyLetters={onlyLetters}
                        onlyNumbers={onlyNumbers}
                        disabled={disabled}
                        contentLeft={contentLeft}
                        contentRight={
                            contentRight
                                ? {
                                      ...contentRight,
                                      children: (
                                          <div className="dropdown-component__contentRight">
                                              {onSearch && (searchTerm || selectedLabel) && (
                                                  <Icon
                                                      className="dropdown-component__contentRight__close"
                                                      icon="circle-close"
                                                      onClick={handleClearValues}
                                                  />
                                              )}
                                              {contentRight.children}
                                          </div>
                                      )
                                  }
                                : {
                                      children: <Icon icon={open ? 'chevron-up' : 'chevron-down'} />,
                                      onClick: toggleDropdown
                                  }
                        }
                        variant={variant}
                        {...restProps}
                    />
                    <select
                        ref={selectRef}
                        className="dropdown-component__native-select"
                        value={typeof selectedValue === 'object' ? JSON.stringify(selectedValue) : selectedValue || ''}
                        onChange={(event) => {
                            const selectedOption = options.find((option) =>
                                typeof option.value === 'object'
                                    ? JSON.stringify(option.value) === event.target.value
                                    : option.value.toString() === event.target.value
                            );
                            if (selectedOption) {
                                handleSelect(selectedOption.value, selectedOption.label);
                            }
                        }}
                        disabled={disabled}
                    >
                        {options.map((option, index) => (
                            <option
                                key={index}
                                value={typeof option.value === 'object' ? JSON.stringify(option.value) : option.value}
                            >
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            );
        }

        return (
            <div
                ref={dropdownRef}
                className={`dropdown-component ${disabled || readOnly ? 'dropdown-component--disabled' : ''} ${
                    !label ? 'dropdown-component--no-label' : ''
                } ${canSearch ? 'dropdown-component--can-search' : ''} ${props.className}`}
                onKeyDown={handleKeyDown}
                role="combobox"
                aria-expanded={open}
                aria-label={dropdownId}
                aria-controls={`${dropdownId}-options`}
                aria-haspopup="listbox"
                tabIndex={-1}
            >
                <TextInputComponent
                    data-field-name={name}
                    name={name}
                    ref={ref}
                    id={dropdownId}
                    label={label}
                    value={
                        canSearch && open
                            ? searchTerm
                            : selectedLabel || (typeof selectedValue === 'number' ? selectedValue.toString() : '') || ''
                    }
                    onFocus={openDropdown}
                    onClick={openDropdown}
                    onChange={canSearch ? handleInputChange : noop}
                    onBlur={handleBlur}
                    error={!open && error}
                    success={success}
                    disabled={disabled}
                    contentLeft={contentLeft}
                    onlyLetters={onlyLetters}
                    onlyNumbers={onlyNumbers}
                    contentRight={
                        contentRight
                            ? {
                                  ...contentRight,
                                  children: (
                                      <div className="dropdown-component__contentRight">
                                          {onSearch && (searchTerm || selectedLabel) && (
                                              <Icon
                                                  className="dropdown-component__contentRight__close"
                                                  icon="circle-close"
                                                  onClick={handleClearValues}
                                              />
                                          )}
                                          {contentRight.children}
                                      </div>
                                  )
                              }
                            : {
                                  children: <Icon icon={open ? 'chevron-up' : 'chevron-down'} />,
                                  onClick: toggleDropdown
                              }
                    }
                    variant={variant}
                    tabIndex={disabled ? 0 : undefined}
                    {...restProps}
                />

                <ul
                    tabIndex={disabled ? 0 : undefined}
                    id={`${dropdownId}-options`}
                    className={`dropdown-component__options ${open ? 'dropdown-component__options--open' : ''}`}
                    role="listbox"
                    aria-labelledby={dropdownId}
                >
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((option, index) => (
                            <li
                                key={index}
                                ref={(el) => (optionRefs.current[index] = el)}
                                role="option"
                                tabIndex={-1}
                                aria-selected={
                                    typeof selectedValue === 'object'
                                        ? JSON.stringify(selectedValue) === JSON.stringify(option.value)
                                        : selectedValue === option.value
                                }
                                onClick={() => handleSelect(option.value, option.label)}
                                className={`dropdown-component__option ${focusedIndex === index ? 'focused' : ''}`}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' || event.key === ' ') {
                                        handleSelect(option.value, option.label);
                                    }
                                }}
                            >
                                {highlightMatch(option.label, searchTerm)}
                            </li>
                        ))
                    ) : (
                        <li className="dropdown-component__option dropdown-component__no-options">
                            {optionNotFoundText ? optionNotFoundText : t('uiComponents.dropdownComponent.noOptions')}
                        </li>
                    )}
                </ul>
            </div>
        );
    }
);

export default DropdownComponent;
