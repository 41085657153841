/**
 * No op function that can be used in place of empty anonymous functions
 */

export function noop() {
    //todo nothing
}
export type UnknownFunction = (...args: unknown[]) => void;
/**
 * Delay function that can be used in situations where a setTimeout is required
 */
export function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

//do a match of the keys of the target object with the source object
type GenericObject = { [key: string]: unknown };

export function parseObject<T extends GenericObject>(targetObject: T, sourceObject: GenericObject): T {
    const findMatchingKey = (key: string, obj: GenericObject): string | undefined => {
        const lowerKey = key.toLowerCase();
        return Object.keys(obj).find((k) => k.toLowerCase() === lowerKey);
    };

    const recursiveParse = (target: GenericObject, source: GenericObject): GenericObject => {
        const parsedObject: GenericObject = {};

        for (const targetKey in target) {
            const targetValue = target[targetKey];

            if (typeof targetValue === 'object' && targetValue !== null && !Array.isArray(targetValue)) {
                parsedObject[targetKey] = recursiveParse(targetValue as GenericObject, source);
            } else {
                const sourceKey = findMatchingKey(targetKey, source);
                if (sourceKey !== undefined) {
                    parsedObject[targetKey] = source[sourceKey];
                } else {
                    parsedObject[targetKey] = targetValue;
                }
            }
        }

        return parsedObject;
    };

    return recursiveParse(targetObject, sourceObject) as T;
}

export function filterUndefined<T>(value: T | undefined): value is T {
    return value !== undefined;
}
