import { BIRDI_PLANS } from 'enums/plans';
import { FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';

import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';
import { drugDescriptionRoutine, drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { DrugDescriptionObjectPayload } from 'state/drug/drug.services';

import { formatPrice } from 'schema/price.schema';

import { PrescriptionTypes, RxDetails } from 'types/prescription';

import { UnknownFunction } from 'util/function';

const useBirdiPrice = () => {
    const dispatch = useDispatch();

    const getPrice = (
        values: Partial<PrescriptionTypes>,
        actions: FormikHelpers<Partial<PrescriptionTypes>>,
        handlePriceSuccess: UnknownFunction,
        handleDescriptionSuccess: UnknownFunction,
        handleBirdiSelect?: UnknownFunction,
        handleBrd02?: UnknownFunction
    ) => {
        // Convert values into a RxDetails object, since that is what it expects
        const prescription: Partial<RxDetails> = {
            dispensedProductNumber: values.ndc,
            fillQuantity: values.qty,
            fillDaysSupply: '',
            epostPatientNum: values.dependentEpostPatientNum
        };

        // Fetch the drug price.
        dispatch(
            drugDiscountPriceRoutine.trigger({
                prescriptions: [prescription],
                onSuccess: (drugPriceResponse: DrugWithDiscountPrice) => {
                    // Update the submitting state of the prescription info form
                    // in order to reset the loading state of the submit button.
                    actions && actions.setSubmitting(false);

                    // If the price is not included in the response or is an empty string, then set the value to "NA".

                    const priceValue = !isNaN(Number(drugPriceResponse.price))
                        ? formatPrice(drugPriceResponse.price)
                        : drugPriceResponse.price;

                    const isBirdiSelect = drugPriceResponse.isBirdiSelect;
                    const isBrd02 = drugPriceResponse.planAlias === BIRDI_PLANS.BRD_02;
                    // need to provide successHandler to set showPrice, SelectedDrug and scrallTo
                    handlePriceSuccess && handlePriceSuccess(priceValue);
                    handleBirdiSelect && handleBirdiSelect(isBirdiSelect);
                    handleBrd02 && handleBrd02(isBrd02);
                },
                onFailure: () => {
                    // On failure, we still scroll down, but we show an error message.
                    actions && actions.setSubmitting(false);
                    handlePriceSuccess && handlePriceSuccess();
                }
            })
        );

        // Fetch the drug description.
        if (values.gpi) {
            dispatch(
                drugDescriptionRoutine.trigger({
                    gpi: values.gpi, //selectedGpi,
                    onSuccess: (response: DrugDescriptionObjectPayload) => {
                        handleDescriptionSuccess && handleDescriptionSuccess(response);
                    },
                    onFailure: () => {
                        handleDescriptionSuccess && handleDescriptionSuccess();
                    }
                })
            );
        }
    };

    return { getPrice };
};

export default useBirdiPrice;
