import { createSelector } from '@reduxjs/toolkit';

import { accountProfileSelector } from 'state/account/account.selectors';
import { ProfileObjectPayload } from 'state/account/account.services';
import { RootState } from 'state/store';

import { mapCaregiverAndFamilyMembers } from 'util/depentent';

export const familyProfileSelector = (state: RootState) => state.familyProfileReducer;

export const familyProfileIsLoadingSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.isFamilyAccountLoading
);

export const dependentsApiStatusSelector = createSelector(
    familyProfileSelector,
    ({ dependentsApiStatus }) => dependentsApiStatus
);

export const familyProfileDependentsSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.dependents
);

export const familyProfileFamilyPlansMapSelector = createSelector(familyProfileSelector, (profileAccount) => {
    return profileAccount.dependents.reduce((acc, dependent) => {
        return {
            ...acc,
            [dependent.ePostPatientNum]: dependent.planAlias
        };
    }, {});
});

export const familyProfileCaregiversSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.caregivers
);

export const familyIsCaregiverLoadingSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.isCaregiverLoading
);

export const familyProfileDependentToAddSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.dependentToAdd
);

export const familyProfileDependentsActiveSelector = createSelector(familyProfileSelector, (profileAccount) =>
    profileAccount.dependents.filter((dependent) => !dependent.dateRevokeAccess)
);

export const familyMembersPlansSelector = createSelector(
    [familyProfileDependentsSelector, accountProfileSelector],
    (familyMembersData, profileObject) => {
        if (familyMembersData.length > 0) {
            const familyMembers = mapCaregiverAndFamilyMembers(
                profileObject as ProfileObjectPayload,
                familyMembersData,
                true
            );
            return familyMembers;
        } else {
            return null;
        }
    }
);

export const removeMemberIsLoadingSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.isRemoveMemberLoading
);

export const sendInvitationIsLoadingSelector = createSelector(
    familyProfileSelector,
    (profileAccount) => profileAccount.isResendInvitationLoading
);
