import classNames from 'classnames';
import React from 'react';

import { ThemeColors } from 'types/ui-kit';

import { iconPaths } from './icon.paths';
import './icon.styles.scss';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
    icon: keyof typeof iconPaths;
    color?: ThemeColors;
    width?: string;
    height?: string;
    viewBox?: string;
}

const getPathClass = (path: React.ReactElement) => {
    const hasFill = path.props.fill && path.props.fill !== 'none';
    const hasStroke = path.props.stroke && path.props.stroke !== 'none';

    return classNames({
        'icon-path-fill': hasFill,
        'icon-path-stroke': hasStroke
    });
};

const Icon = (props: IconProps) => {
    const { color = 'currentcolor', icon, width = '24', height = '24', viewBox = '0 0 24 24' } = props;
    const iconPath = iconPaths[icon] as React.ReactElement<React.SVGProps<SVGSVGElement>>;

    const colorClass = color !== 'currentcolor' ? `custom-color-${color}` : undefined;

    const pathsWithClass = React.Children.map(
        React.isValidElement(iconPath) && iconPath.props?.children ? iconPath.props.children : iconPath,
        (child) => {
            if (React.isValidElement(child)) {
                const pathClass = getPathClass(child);
                return React.cloneElement(child, {
                    className: classNames(colorClass, pathClass)
                });
            }
            return child;
        }
    );

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBox}
            aria-hidden="true"
            className="icon-component"
            fill="none"
            preserveAspectRatio="xMidYMid meet"
            {...props}
        >
            {pathsWithClass}
        </svg>
    );
};

export default Icon;
