export interface DropdownProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'placeholder' | 'onSelect' | 'onChange' | 'onBlur'> {
    label: string;
    options: DropdownOption[];
    onSelect: (value: any) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSearch?: (query: string) => void;
    onClear?: () => void;
    canSearch?: boolean;
    error?: string | boolean;
    success?: boolean;
    variant?: 'default' | 'alt';
    optionNotFoundText?: string;
    contentLeft?: {
        children: React.ReactNode;
        onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        tooltip?: string;
    };
    contentRight?: {
        children: React.ReactNode;
        onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        tooltip?: string;
    };
    onlyNumbers?: boolean;
    onlyLetters?: boolean;
}
export interface DropdownOption {
    key?: string | number;
    value: string | Record<string, any> | number;
    label: string | React.ReactNode;
}

export const toDropdownOption = <T extends Record<string, any>>(
    objOrArray: T[],
    keyField: keyof T,
    valueField: keyof T | 'self',
    labelField: keyof T
): DropdownOption[] => {
    // Function to transform a single object into DropdownOption
    const transformObject = (obj: T): DropdownOption => {
        return {
            key: obj[keyField] as string | number,
            value: valueField === 'self' ? obj : obj[valueField],
            label: obj[labelField] as string
        };
    };

    return objOrArray.map((obj) => transformObject(obj));
};
