import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { CreditCardPayload } from 'types/card';
import { PaymentHistoryLine, PaymentHistoryV2Response } from 'types/payment-history';

import { paymentsV2GetPaymentHistoryRoutine, paymentsV2PostPatientBalanceFamilyRoutine } from './payments.routines';

export interface PaymentsState {
    isLoadingTransactions?: boolean;
    isMakingPayment?: boolean;
    selectedPaymentMethod?: CreditCardPayload;
    paymentHistory?: PaymentHistoryLine[];
    paymentHistoryV2?: PaymentHistoryV2Response;
}

export const initialState: PaymentsState = {
    isLoadingTransactions: false
};

const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        setSelectedPaymentMethod: (state, { payload }: PayloadAction<CreditCardPayload | undefined>) =>
            produce(state, (draftState) => {
                draftState.selectedPaymentMethod = payload;
            })
    },
    extraReducers: ({ addCase }) => {
        /**
         * Pay Balance V2
         */
        addCase(paymentsV2PostPatientBalanceFamilyRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isMakingPayment = true;
            })
        );
        addCase(paymentsV2PostPatientBalanceFamilyRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isMakingPayment = false;
            })
        );
        addCase(paymentsV2PostPatientBalanceFamilyRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isMakingPayment = false;
            })
        );

        /**
         * Payment History V2
         */
        addCase(paymentsV2GetPaymentHistoryRoutine.TRIGGER, (state) => {
            return produce(state, (draftState) => {
                draftState.isLoadingTransactions = true;
            });
        });

        addCase(
            paymentsV2GetPaymentHistoryRoutine.SUCCESS,
            (state, { payload }: PayloadAction<PaymentHistoryV2Response>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingTransactions = false;
                    draftState.paymentHistoryV2 = {
                        ...draftState.paymentHistoryV2,
                        ...payload
                    };
                })
        );

        addCase(paymentsV2GetPaymentHistoryRoutine.FAILURE, (state) => {
            return produce(state, (draftState) => {
                draftState.isLoadingTransactions = false;
            });
        });
    }
});

export const paymentsActions = paymentsSlice.actions;

export default paymentsSlice.reducer;
