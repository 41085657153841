import { useEffect, useRef } from 'react';

import { SpinnerProps } from './spinner.props';
import './spinner.style.scss';

const Spinner = ({
    isVisible,
    t,
    className,
    color,
    fullOverlay = true,
    isSmall = false,
    isDotsSpinner = false,
    focusOnShow = false
}: SpinnerProps) => {
    const spinnerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (spinnerRef.current && focusOnShow) {
            spinnerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [focusOnShow]);

    if (isDotsSpinner) {
        return (
            <div className={`spinner-container ${isVisible ? '' : 'd-none'}`}>
                <div className={`spinner-dots ${className ? className : ''}`}>{t('components.spinner.loading')}</div>
            </div>
        );
    }

    return (
        <div ref={spinnerRef} className={`${isVisible ? '' : 'd-none'} ${fullOverlay ? 'fullOverlay' : ''}`}>
            <div
                className={`spinner-border  ${color ? `spinner-border--${color}` : ''} ${
                    isSmall ? 'spinner-border-sm' : ''
                } ${className}`}
                role="status"
            >
                <span className={`sr-only`}>{t('components.spinner.loading')}</span>
            </div>
        </div>
    );
};

export default Spinner;
