import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { accountProfileSelector } from 'state/account/account.selectors';

import { getPatientsInCart } from 'util/cart';

type CartPayloadParams = {
    EpostPatientNum?: string;
    FirstName?: string;
    LastName?: string;
};

export type PatientsInCart = {
    epostPatientNum: string;
    firstName?: string;
    lastName?: string;
};

export function usePatientsInCart<T extends CartPayloadParams>(cart?: T[]): [PatientsInCart[], string] {
    const profileObject = useSelector(accountProfileSelector);
    const [patientsInCart, setPatientsInCart] = useState<PatientsInCart[]>([]);

    const flatPatientsInCart = patientsInCart.map((patient) => patient.epostPatientNum).join('-');

    useEffect(() => {
        const patients = getPatientsInCart(profileObject, cart);
        const flatPatients = patients.map((patient) => patient.epostPatientNum).join('-');

        if (flatPatients !== flatPatientsInCart) {
            setPatientsInCart(patients);
        }
    }, [cart, profileObject, flatPatientsInCart]);

    return [patientsInCart, flatPatientsInCart];
}
