import { ApiStatus } from 'enums/api-status';
import { useSelector } from 'react-redux';

import { RootState } from 'state/store';

export function useApiStatus(selector: (state: RootState) => ApiStatus) {
    const apiStatus = useSelector(selector);

    return {
        isIdle: apiStatus === ApiStatus.IDLE,
        isLoading: apiStatus === ApiStatus.LOADING,
        isSuccess: apiStatus === ApiStatus.SUCCESS,
        isError: apiStatus === ApiStatus.ERROR
    };
}
