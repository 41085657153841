import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

// UI Kit
import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import {
    AllergiesModalContentV2,
    HealthConditionsModalContentV2
} from 'components/health-profile-v2/health-profile.component';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';
import ColumnSectionEditModeToggle, {
    ColumnSectionEditModeToggleRef
} from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

// Modal
import { HealthConditionsWarningModalContent } from 'pages/secure/profile/health-profile';

// States
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { medicalConditionsFetchHealthConditionsForPatientRoutine } from 'state/medical-conditions/medical-conditions.routines';

import { ExtendedCartObjectPayload } from 'types/cart';

import { getPhoneNumber } from 'util/globalVariables';

import { useHealthConditionsByPatientNum } from 'hooks/useHealthConditionsByPatientNum';
import { usePatientsInCart } from 'hooks/usePatientsInCart';

import './health-profile.style.scss';

interface HealthProfileProps {
    cart?: ExtendedCartObjectPayload[];
}

const HealthProfile = ({ cart }: HealthProfileProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [hasFetchedConditions, setHasFetchedConditions] = useState<boolean>(false);
    const columnSectionRef = useRef<ColumnSectionEditModeToggleRef>(null);
    const [usersInCart, flatUsersInCart] = usePatientsInCart(cart);
    const { isMissingHealthConditions, ...healthConditionsByPatientNumber } = useHealthConditionsByPatientNum(
        usersInCart.map((user) => user.epostPatientNum)
    );

    const isToggleOpen = hasFetchedConditions && isMissingHealthConditions;

    const handleUpdateConditionsClick = useCallback(
        ({ action, update }: HealthProfileBubbleUpdateEvent) => {
            if (!update.ePostPatientNum) return;

            const healthConditions = healthConditionsByPatientNumber[update.ePostPatientNum];

            if (!healthConditions) return;

            const { userHasNotSubmittedAllergies, userHasNotSubmittedConditions } = healthConditions;

            dispatch(
                openModal({
                    showClose: false,
                    type: 'primary',
                    size: 'lg',
                    className: 'modal-health-profile-warning',
                    headerContent: <BirdiModalHeaderDanger headerText={t('modals.healthConditions.title')} />,
                    bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                    ctas: [
                        {
                            label: t('modals.healthConditions.submit'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                                dispatch(action(update));
                                if (
                                    columnSectionRef.current &&
                                    !userHasNotSubmittedConditions &&
                                    !userHasNotSubmittedAllergies
                                ) {
                                    columnSectionRef.current.handleSaveChangesButtonClick();
                                }
                            },
                            dataGALocation: 'HealthProfileWarning'
                        }
                    ]
                })
            );
        },
        [dispatch, healthConditionsByPatientNumber, t]
    );

    const handleToggleHealthConditionsClick = useCallback(
        (epostPatientNumber: string) => () => {
            dispatch(
                openModal({
                    showClose: true,
                    className: 'prescription-modal',
                    bodyContent: (
                        <HealthConditionsModalContentV2
                            title={t('modals.prescription.addHealthCondition.title')}
                            subTitle={t('modals.prescription.addAllergy.subTitle', {
                                phoneNumber: getPhoneNumber({})
                            })}
                            onUpdateHealthConditions={handleUpdateConditionsClick}
                            submitLabel={t('modals.prescription.addHealthCondition.submit')}
                            ePostPatientNum={epostPatientNumber}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, handleUpdateConditionsClick, t]
    );

    const handleToggleAllergiesClick = useCallback(
        (ePostPatientNum: string) => () => {
            dispatch(
                openModal({
                    showClose: true,
                    className: 'prescription-modal',
                    bodyContent: (
                        <AllergiesModalContentV2
                            title={t('modals.prescription.addAllergy.title')}
                            subTitle={t('modals.prescription.addAllergy.subTitle', {
                                phoneNumber: getPhoneNumber({})
                            })}
                            onUpdateHealthConditions={handleUpdateConditionsClick}
                            freeformConditionsLabel={t('components.healthConditions.labels.freeformAllergiesLabel')}
                            submitLabel={t('modals.prescription.addAllergy.submit')}
                            ePostPatientNum={ePostPatientNum}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, handleUpdateConditionsClick, t]
    );

    // Fetch health conditions
    useEffect(() => {
        usersInCart.forEach((user) => {
            const healthConditions = healthConditionsByPatientNumber[user.epostPatientNum];

            if (!healthConditions) {
                dispatch(
                    medicalConditionsFetchHealthConditionsForPatientRoutine.trigger({
                        ePostPatientNumber: user.epostPatientNum
                    })
                );
            }
        });

        setHasFetchedConditions(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, flatUsersInCart]);

    const healthProfileContent = useMemo(() => {
        return usersInCart.map((user) => {
            const healthConditions = healthConditionsByPatientNumber[user.epostPatientNum];

            if (!healthConditions) {
                return null;
            }

            const {
                userHasNotSubmittedAllergies,
                userHasNotSubmittedConditions,
                existingConditions,
                existingAllergies
            } = healthConditions;

            return (
                <div key={user.epostPatientNum} className="health-profile">
                    {usersInCart.length > 1 && (
                        <div className="health-profile__patient-name">
                            {user.firstName} {user.lastName}
                        </div>
                    )}
                    {userHasNotSubmittedConditions || userHasNotSubmittedAllergies ? (
                        <ToastBox variant="warning" icon="warning">
                            <Trans i18nKey={'components.medicineCabinetCart.healthProfile.emptyHealthConditions'} />
                        </ToastBox>
                    ) : (
                        <div className="health-profile__intro">
                            {t('components.medicineCabinetCart.healthProfile.intro')}
                        </div>
                    )}
                    <div className="health-profile__conditions">
                        <h5 className="health-profile__conditions-title">
                            {t('components.medicineCabinetCart.healthProfile.healthConditions')}
                        </h5>

                        <div className="health-profile__conditions-actions">
                            <Button
                                plusIcon
                                IconType="secondary"
                                className="column-section__button btn-bold"
                                variant="text-blue-light"
                                label={t('components.medicineCabinetCart.healthProfile.addHealthConditions')}
                                type="button"
                                onClick={handleToggleHealthConditionsClick(user.epostPatientNum)}
                            />
                        </div>

                        {!userHasNotSubmittedConditions && (
                            <div className="health-profile__conditions-details">
                                <HealthConditionPills conditions={existingConditions} />
                            </div>
                        )}
                    </div>
                    <div className="health-profile__conditions">
                        <h5 className="health-profile__conditions-title">
                            {t('components.medicineCabinetCart.healthProfile.allergies')}
                        </h5>

                        <div className="health-profile__conditions-actions">
                            <Button
                                plusIcon
                                IconType="secondary"
                                className="column-section__button btn-bold"
                                variant="text-blue-light"
                                label={t('components.medicineCabinetCart.healthProfile.addAllergies')}
                                type="button"
                                onClick={handleToggleAllergiesClick(user.epostPatientNum)}
                            />
                        </div>

                        {!userHasNotSubmittedAllergies && (
                            <div className="health-profile__conditions-details">
                                <HealthConditionPills conditions={existingAllergies} />
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    }, [
        usersInCart,
        t,
        healthConditionsByPatientNumber,
        handleToggleAllergiesClick,
        handleToggleHealthConditionsClick
    ]);

    return (
        <ColumnSectionEditModeToggle
            chevronButton
            ref={columnSectionRef}
            bodyClassName="health-profile__content"
            className=""
            editModeContent={healthProfileContent}
            headerClassName="health-profile__header"
            title={t('components.medicineCabinetCart.healthProfile.title')}
            isToggleOpen={isToggleOpen}
        />
    );
};

export default HealthProfile;
