import { createRoutine } from 'redux-saga-routines';

import {
    PaymentHistoryRequestPayload,
    PaymentHistoryV2Response,
    PayPatientBalanceFamilyErrors,
    PostPatientBalanceFamilyPayload
} from 'types/payment-history';

export const paymentsV2PostPatientBalanceFamilyRoutine = createRoutine('PAYMENTS/V2/POST_PATIENT_BALANCE_FAMILY', {
    trigger: (payload: PostPatientBalanceFamilyPayload) => payload,
    failure: (response: PayPatientBalanceFamilyErrors) => response
});

export const paymentsV2GetPaymentHistoryRoutine = createRoutine('PAYMENTS/V2/GET_PAYMENT_HISTORY', {
    trigger: (payload: PaymentHistoryRequestPayload) => payload,
    success: (response: PaymentHistoryV2Response) => response
});
