import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import Button from 'ui-kit/button/button';
import FormCheckbox from 'ui-kit/form-checkbox/form-checkbox';
import FormSelect from 'ui-kit/form-select/form-select';
import Text from 'ui-kit/text/text';

import { AddressVerificationForm } from 'components/address-verification-modal/AddressVerificationModal.component';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { AddressParts } from 'state/usps/usps.reducers';

import { addressTypeOptions, stateOptions } from 'const/options';

import { ADDRESS_SCHEMA } from 'schema/address';

import { AddressPayload } from 'types/account';

import { getPhoneNumber } from 'util/globalVariables';
import { AddressValidateResponse } from 'util/usps';

import { useAddressVerification } from 'hooks/useAddressVerification';
import { useGlobalLink } from 'hooks/useGlobalLink';

export interface AddAddressFormProps {
    handleFormCancel: () => void;
    handleFormSubmit: (values: AddressPayload) => void;
    defaultValues?: AddressFormSchema;
    centerFormSubmit?: boolean;
    isAddressVerifying?: boolean;
    submitButtonText?: string;
    showCancel?: boolean;
    showSetAsDefault?: boolean;
    instructions?: string;
    title?: string;
    trackFormInteraction?: boolean;
}

export interface AddressFormSchema {
    addressTypeDesc?: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zipcode: string;
    setAsDefault?: boolean;
    addressSeqNum?: string;
}

export const AddressVerificationFailureModalContent = ({ translation }: { translation: TFunction<'translation'> }) => {
    return <BirdiModalContentAlt subTitle={translation('modals.addressVerificationFailure.body')} />;
};

export const AddressVerificationAPIFailureModalContent = ({
    translation
}: {
    translation: TFunction<'translation'>;
}) => {
    return (
        <BirdiModalContentAlt
            subTitle={translation('modals.addressVerificationFailure.details', {
                phoneNumber: getPhoneNumber({ isEnd: true })
            })}
        />
    );
};

export interface AddressFormFieldsProps {
    t: any;
    localesBaseKey?: string;
    handleChange: ((event: React.ChangeEvent<any>) => void) | undefined;
    handleBlur: React.FocusEventHandler<HTMLInputElement> | undefined;
    values: any;
    errors: any;
    touched: any;
    hideAddressType?: boolean;
    showSetAsDefault?: boolean;
    trackFormInteraction?: boolean;
}

export const AddressFormFields = ({
    t,
    localesBaseKey,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    hideAddressType,
    showSetAsDefault,
    trackFormInteraction = false
}: AddressFormFieldsProps): ReactElement => {
    if (!localesBaseKey) {
        localesBaseKey = 'pages.profile';
    }

    const globalLink = useGlobalLink();

    return (
        <>
            {!hideAddressType && (
                <Row>
                    <Col>
                        <Field
                            id="profile-setup-address-type"
                            name="addressTypeDesc"
                            options={addressTypeOptions}
                            component={FormSelect}
                            onBlur={handleBlur}
                            placeholder={t('profile.addressType')}
                            value={values.addressTypeDesc}
                            errors={
                                errors?.addressTypeDesc
                                    ? t('forms.errorMessages.requiredField', {
                                          label: t('profile.addressType')
                                      })
                                    : undefined
                            }
                            touched={touched.addressTypeDesc}
                            onFocus={() =>
                                trackFormInteraction && globalLink.handleFieldFocus(t('profile.addressType'))
                            }
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Text
                        name="address1"
                        label={t(`${localesBaseKey}.addAddress.address1`)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={
                            errors?.address1
                                ? errors?.address1.trim() === 'Address Not Found.'
                                    ? t('forms.errorMessages.invalidAddress')
                                    : t('forms.errorMessages.requiredField', {
                                          label: t(`${localesBaseKey}.addAddress.address1`)
                                      })
                                : undefined
                        }
                        touched={touched.address1}
                        defaultValue={values.address1}
                        onFocus={() =>
                            trackFormInteraction &&
                            globalLink.handleFieldFocus(t(`${localesBaseKey}.addAddress.address1`))
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text
                        name="address2"
                        label={t(`${localesBaseKey}.addAddress.address2`)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={
                            errors?.address2
                                ? errors?.address2 === 'Address2 required.'
                                    ? t('forms.errorMessages.invalidAddress2')
                                    : errors?.address2
                                : undefined
                        }
                        touched={touched.address2}
                        defaultValue={values.address2}
                        onFocus={() =>
                            trackFormInteraction &&
                            globalLink.handleFieldFocus(t(`${localesBaseKey}.addAddress.address2`))
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={4}>
                    <Text
                        name="city"
                        label={t(`${localesBaseKey}.addAddress.city`)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={
                            errors?.city
                                ? errors.city === 'Invalid City.'
                                    ? t('forms.errorMessages.invalidCity')
                                    : t('forms.errorMessages.requiredField', {
                                          label: t(`${localesBaseKey}.addAddress.city`)
                                      })
                                : undefined
                        }
                        touched={touched.city}
                        defaultValue={values.city}
                        onFocus={() =>
                            trackFormInteraction && globalLink.handleFieldFocus(t(`${localesBaseKey}.addAddress.city`))
                        }
                    />
                </Col>
                <Col sm={12} lg={4}>
                    <Field
                        id="state"
                        name="state"
                        options={stateOptions}
                        component={FormSelect}
                        value={values.state}
                        placeholder={t(`${localesBaseKey}.addAddress.state`)}
                        errors={
                            errors?.state
                                ? errors.state === 'Invalid State.'
                                    ? t('forms.errorMessages.invalidState')
                                    : t('forms.errorMessages.requiredField', {
                                          label: t(`${localesBaseKey}.addAddress.state`)
                                      })
                                : undefined
                        }
                        touched={touched.state}
                        onFocus={() =>
                            trackFormInteraction && globalLink.handleFieldFocus(t(`${localesBaseKey}.addAddress.state`))
                        }
                    />
                </Col>
                <Col sm={12} lg={4}>
                    <Text
                        name="zipcode"
                        label={t(`${localesBaseKey}.addAddress.zipCode`)}
                        onChange={handleChange}
                        errors={
                            errors?.zipcode
                                ? errors.zipcode === 'Invalid Zip Code.'
                                    ? t('forms.errorMessages.invalidZipCode')
                                    : t('forms.errorMessages.requiredField', {
                                          label: t(`${localesBaseKey}.addAddress.zipCode`)
                                      })
                                : undefined
                        }
                        maxLength={10}
                        touched={touched.zipcode}
                        defaultValue={values.zipcode}
                        onFocus={() =>
                            trackFormInteraction &&
                            globalLink.handleFieldFocus(t(`${localesBaseKey}.addAddress.zipCode`))
                        }
                    />
                </Col>
            </Row>
            {showSetAsDefault && (
                <Row>
                    <Col>
                        <Field
                            id="setAsDefault"
                            name="setAsDefault"
                            component={FormCheckbox}
                            checkedValue={true}
                            value={values.setAsDefault}
                            label={t(`${localesBaseKey}.addAddress.setAsDefault`)}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};

export default function AddAddressForm({
    handleFormSubmit,
    handleFormCancel,
    defaultValues = {
        addressTypeDesc: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        setAsDefault: false
    },
    centerFormSubmit,
    submitButtonText = 'forms.addAddress.submitButton',
    showCancel = true,
    instructions = 'pages.profile.shippingAddress.addressDetails',
    title,
    showSetAsDefault,
    trackFormInteraction = false
}: AddAddressFormProps) {
    const { t } = useTranslation();
    const [needsVerification, setNeedsVerification] = useState(false);
    const { isBusy, verifyAddress } = useAddressVerification();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formName = 'AddAddressForm';
    const [originalAddress, setOriginalAddress] = useState(null);
    const [correctedAddress, setCorrectedAddress] = useState(null);
    const [preVerificationValues, setPreVerificationValues] = useState(defaultValues);
    const [preVerificationHelpers, setPreVerificationHelpers] = useState(null);

    const onSubmitAddAddress = (values, helpers) => {
        setIsSubmitting(true);
        handleFormSubmit({
            ...values,
            active: true,
            address3: '',
            addressNote: '',
            addressSeqNum: values.addressSeqNum || '',
            addressTypeDesc: values.addressTypeDesc.toUpperCase(),
            addressTypeNum: addressTypeOptions.filter((typeOption) => typeOption.value === values.addressTypeDesc)[0]
                .typeNum,
            country: 'USA',
            currentShipping: false,
            defaultBill: values.setAsDefault
                ? values.setAsDefault
                : values.defaultAddress
                ? values.defaultAddress
                : false,
            defaultPhoneNumber: '',
            defaultPhoneSeq: '',
            defaultAddress: values.setAsDefault
                ? values.setAsDefault
                : values.defaultAddress
                ? values.defaultAddress
                : false,
            endDate: '',
            epostPatientNum: '',
            messageErrorText: values.messageErrorText ? values.messageErrorText : undefined,
            messageStatus: values.messageStatus ? values.messageStatus : undefined,
            messageText: values.messageText ? values.messageText : undefined,
            startDate: '',
            stateNum: values.stateNum ? values.stateNum : undefined,
            zipcodeFour: '',
            defaultShip: values.setAsDefault
                ? values.setAsDefault
                : values.defaultAddress
                ? values.defaultAddress
                : false,
            onFailure: (errors) => {
                setIsSubmitting(false);
                helpers.setErrors(errors);
            }
        });
    };

    const onVerificatonAddressChoice = (address: AddressParts) => {
        if (address) {
            const valuesWithChosenAddress = {
                ...preVerificationValues,
                ...address
            };
            onCloseValidationChoice();
            onSubmitAddAddress(valuesWithChosenAddress, preVerificationHelpers);
        }
    };

    const onCloseValidationChoice = () => {
        setNeedsVerification(false);
        setOriginalAddress(null);
        setCorrectedAddress(null);
    };

    const onValidateAddress = (values: AddressFormSchema, helpers: FormikHelpers<AddressFormSchema>) => {
        setPreVerificationValues(values);
        setPreVerificationHelpers(helpers);
        // Address needs to be validated prior to dispatching `accountRegisterRoutine`
        const address: AddressParts = {
            street1: values.address1 ? values.address1 : '',
            street2: values.address2,
            city: values.city ? values.city : '',
            state: values.state ? values.state : '',
            zip: values.zipcode ? values.zipcode : ''
        };

        verifyAddress({
            address,
            onSuccess: (validationResponse: AddressValidateResponse) => {
                helpers.setSubmitting(false);
                if (validationResponse.responseCode === 'suggested') {
                    setOriginalAddress(validationResponse.currentAddress);
                    setCorrectedAddress(validationResponse.updatedAddress);
                    setNeedsVerification(true);
                } else {
                    onSubmitAddAddress(values, helpers);
                }
            },
            onFailure: (validationResponse: AddressValidateResponse) => {
                // Reset the form to allow updates.
                helpers.setSubmitting(false);
                const errors = {};
                if (validationResponse.responseMessage) {
                    const errorField = validationResponse.responseField
                        ? validationResponse.responseField?.toLowerCase()
                        : 'address1';

                    errors[errorField] = validationResponse.responseMessage;
                    helpers.setErrors(errors);
                }
            }
        });
    };

    return (
        <>
            {needsVerification && (
                <>
                    <AddressVerificationForm
                        originalAddress={originalAddress}
                        correctedAddress={correctedAddress}
                        onSuccess={onVerificatonAddressChoice}
                    />
                    <div className="text-center">
                        <Button
                            label={t('modals.addressVerification.cancel')}
                            className="md-full mt-1 mt-md-4 md-pad-y-2 d-inline"
                            type="button"
                            variant="text-blue"
                            onClick={onCloseValidationChoice}
                            dataGAFormName={formName}
                            dataGALocation="AddressVerificationForm"
                        />
                    </div>
                </>
            )}
            <div className={needsVerification ? 'd-none' : ''}>
                {title && (
                    <div className="d-flex flex-column align-items-center text-center">
                        <h1>{title}</h1>
                        <div className="spacer" />
                    </div>
                )}
                <p className="profile-form-instructions text-left">{t(instructions)}</p>
                <Formik
                    initialValues={defaultValues}
                    validationSchema={ADDRESS_SCHEMA}
                    onSubmit={(values, helpers) => {
                        onValidateAddress(values, helpers);
                    }}
                >
                    {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                        <Form id="add-address-form" data-ga-form-name={formName} autoComplete="off">
                            <AddressFormFields
                                t={t}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                errors={errors}
                                touched={touched}
                                showSetAsDefault={showSetAsDefault}
                                trackFormInteraction={trackFormInteraction}
                            />
                            <Row
                                className={`${
                                    !centerFormSubmit
                                        ? 'd-flex justify-content-between mt-0'
                                        : 'flex-column mt-3 text-center'
                                }`}
                            >
                                <Col className={!centerFormSubmit ? 'col-lg-6 col-sm-12' : ''}>
                                    <Button
                                        async
                                        label={t(submitButtonText)}
                                        className="md-full"
                                        type="submit"
                                        variant="primary"
                                        onClick={handleSubmit}
                                        disabled={isBusy || isSubmitting}
                                        isBusy={isBusy || isSubmitting}
                                        dataGAFormName={formName}
                                        dataGALocation="AddressVerificationForm"
                                    />
                                </Col>
                                {showCancel && (
                                    <Col
                                        className={`${
                                            !centerFormSubmit
                                                ? 'col-lg-6 col-sm-12 d-flex justify-content-end mt-0 sm-full'
                                                : 'mt-3'
                                        }`}
                                    >
                                        <Button
                                            label={t('forms.addAddress.cancelButton')}
                                            className="md-full md-pad-y-2"
                                            type="button"
                                            variant="text"
                                            onClick={handleFormCancel}
                                            dataGAFormName={formName}
                                            dataGALocation="AddAddressForm"
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}
