import { useDispatch, useSelector } from 'react-redux';

import { AddressParts } from 'state/usps/usps.reducers';
import { verifyAddressRoutine, verifyZipRoutine } from 'state/usps/usps.routines';
import { uspsStateSelector } from 'state/usps/usps.selectors';

import { stateOptions } from 'const/options';

import { convertToTitleCase } from 'util/string';
import { AddressValidateResponse, ZipValidateResponse } from 'util/usps';

export interface VerifyAddressProps {
    address: AddressParts;
    onSuccess?: (validationResponse: AddressValidateResponse) => void;
    onFailure?: (validationResponse: AddressValidateResponse) => void;
}

export interface VerifyZipProps {
    zip: string;
    onSuccess?: (validationResponse: ZipValidateResponse) => void;
    onFailure?: (validationResponse: ZipValidateResponse) => void;
}

const statesMap: Record<string, string> = stateOptions.reduce((acc, { label, value }) => {
    acc[value] = label;
    return acc;
}, {} as Record<string, string>);

export const useAddressVerification = () => {
    const { isBusy, verifiedAddress } = useSelector(uspsStateSelector);

    const dispatch = useDispatch();

    const verifyAddress = ({ address, onSuccess, onFailure }: VerifyAddressProps) => {
        if (isBusy) return;
        dispatch(verifyAddressRoutine({ address, onSuccess, onFailure }));
    };

    const verifyZip = ({ zip, onSuccess, onFailure }: VerifyZipProps) => {
        if (isBusy) return;
        dispatch(verifyZipRoutine({ zip, onSuccess, onFailure }));
    };

    const getFullStateName = (shortState: string): string => {
        const state = statesMap[shortState.toUpperCase()];
        return state ? convertToTitleCase(state) : shortState;
    };

    const getShortStateName = (fullState: string): string => {
        const foundState = Object.entries(statesMap).find(
            ([_, fullName]) => fullName.toLowerCase() === fullState.toLowerCase()
        );
        return foundState ? foundState[0] : fullState;
    };

    const isGuamOrPuertoRico = (state: string): boolean => {
        return ['guam', 'puerto rico', 'pr'].includes(state.toLowerCase());
    };

    return {
        isBusy,
        verifiedAddress,
        isGuamOrPuertoRico,
        verifyAddress,
        verifyZip,
        getFullStateName,
        getShortStateName
    };
};
