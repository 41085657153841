import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Ui Kit
import ButtonComponent from 'ui-kit-v2/button/button';
import { IconProps } from 'ui-kit-v2/icon/icon';

import { ButtonProps } from 'ui-kit/button/button.props';
import Spinner from 'ui-kit/spinner/spinner';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { PaymentAddNew } from 'components/payment-add-new/payment-add-new';

// Pages
import { FailureUpdateProfileModalContent } from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';

// State
import {
    accountGetAllCreditCardsRoutine,
    accountRemoveCreditCardRoutine,
    accountUpdateCreditCardRoutine
} from 'state/account/account.routines';
import { accountIsLoadingPaymentMethodsSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    easyRefillGetPatientPaymentCardsRoutine,
    easyRefillUpdatePaymentCardsRoutine
} from 'state/easy-refill/easy-refill.routines';
import { closeModalComponent, openModalComponent, setModalStep } from 'state/modal/modal.reducer';

import { CreditCardPayload } from 'types/card';
import { PaymentCardProps, PaymentMethodsProps, UpdatePrimaryCreditCardOptions } from 'types/payment';

// Utils
import { noop } from 'util/function';
import { getCreditCardEnding } from 'util/payments';
import { convertToTitleCase } from 'util/string';

// Hooks
import useWindowDimensions from 'hooks/useWindowDimensions';

import PaymentCard from './payment-card/payment-card.component';
// Styles
import './payment-methods.style.scss';

//
// --- PaymentMethods Component ---

const PaymentMethods: React.FC<PaymentMethodsProps> = (props) => {
    const {
        onCardRemoved = noop,
        onCardSelectionChange = noop,
        onDefaultCardChanged = noop,
        withNewPaymentButton,
        paymentData,
        paymentRequiredMessage,
        removeCardLinkClassName,
        setAsDefaultCardLinkClassName,
        selectedCardSeqNum,
        showLabel = false,
        showRemoveCardLink = false,
        showSelectCardRadioInput = false,
        showSetDefaultLink = false,
        isEasyRefill = false,
        customContainerClasses = 'container-payment-methods display-columns',
        hasMembership = false,
        hasMembershipFilters = false,
        isMembershipSettings = false,
        isBusy = false,
        isModal = false,
        hasMultipleColumns = false
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const isInnerWidthViewportRef = React.useRef<boolean>(width < 1350 ? true : false);
    const isLoadingPaymentMethods = useSelector(accountIsLoadingPaymentMethodsSelector);
    const isLoadingAccountPaymentMethods = useSelector(accountIsLoadingPaymentMethodsSelector);
    const isLoading = isLoadingPaymentMethods || isLoadingAccountPaymentMethods;

    const sortDefaultPayment: CreditCardPayload[] = useMemo(() => {
        // Add defaultCard boolean to first payment method automatically.
        if (hasMembershipFilters) {
            return sortBy(paymentData, 'defaultMembershipPayment').reverse();
        } else {
            return sortBy(paymentData, 'defaultCard').reverse();
        }
    }, [hasMembershipFilters, paymentData]);

    const updatePrimaryCreditCard = React.useCallback(
        (newPrimaryCard: CreditCardPayload, opts: UpdatePrimaryCreditCardOptions = {}) => {
            const { showPrimaryPaymentUpdatedModal = false } = opts;

            if (!newPrimaryCard) {
                return;
            }

            if (isEasyRefill) {
                dispatch(
                    easyRefillUpdatePaymentCardsRoutine.trigger({
                        cardToUpdate: newPrimaryCard,
                        onSuccess: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: showPrimaryPaymentUpdatedModal ? (
                                        <BirdiModalContent
                                            icon={'success'}
                                            title={t('modals.updateProfile.title')}
                                            body={t('modals.primaryPaymentMethodUpdated.body', {
                                                paymentMethod: `<strong>${t('pages.profile.payment.cardTypeAndNum', {
                                                    cardNumber: getCreditCardEnding(newPrimaryCard.secureCardNumber),
                                                    cardType: newPrimaryCard?.cardType
                                                })}</strong>`
                                            })}
                                        />
                                    ) : (
                                        <BirdiModalContent
                                            icon={'default'}
                                            title={t('modals.paymentMethods.success.title')}
                                            body={t('modals.paymentMethods.success.description')}
                                        />
                                    ),
                                    onClose: () => {
                                        dispatch(easyRefillGetPatientPaymentCardsRoutine.trigger());
                                    },
                                    ctas: [
                                        {
                                            label: t('modals.paymentMethods.success.cta'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                                dispatch(easyRefillGetPatientPaymentCardsRoutine.trigger());
                                            }
                                        }
                                    ]
                                })
                            );

                            onDefaultCardChanged(newPrimaryCard);
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    type: 'danger',
                                    size: 'lg',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            icon="alert"
                                            headerText={t('modals.updateProfile.error')}
                                        />
                                    ),
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.payment')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                                dispatch(easyRefillGetPatientPaymentCardsRoutine.trigger());
                                            }
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
            } else {
                dispatch(
                    accountUpdateCreditCardRoutine.trigger({
                        ...newPrimaryCard,
                        onSuccess: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: showPrimaryPaymentUpdatedModal ? (
                                        <BirdiModalContent
                                            icon={'default'}
                                            title={t('modals.updateProfile.title')}
                                            body={t('modals.primaryPaymentMethodUpdated.body', {
                                                paymentMethod: `<strong>${t('pages.profile.payment.cardTypeAndNum', {
                                                    cardNumber: getCreditCardEnding(newPrimaryCard.secureCardNumber),
                                                    cardType: newPrimaryCard?.cardType
                                                })}</strong>`
                                            })}
                                        />
                                    ) : (
                                        <BirdiModalContent
                                            icon={'default'}
                                            title={t('modals.paymentMethods.success.title')}
                                            body={t('modals.paymentMethods.success.description')}
                                        />
                                    ),
                                    onClose: () => {
                                        dispatch(accountGetAllCreditCardsRoutine.trigger());
                                    },
                                    ctas: [
                                        {
                                            label: t('modals.paymentMethods.success.cta'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                                dispatch(accountGetAllCreditCardsRoutine.trigger());
                                            }
                                        }
                                    ]
                                })
                            );

                            onDefaultCardChanged(newPrimaryCard);
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: false,
                                    type: 'danger',
                                    size: 'lg',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            headerText={t('modals.removePaymentModal.error.title')}
                                            icon="alert"
                                        />
                                    ),
                                    bodyContent: (
                                        <BirdiModalContentAlt
                                            subTitle={t('modals.removePaymentModal.error.description')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            label: t('modals.removePaymentModal.error.cta'),
                                            variant: 'primary'
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
            }
        },
        [dispatch, onDefaultCardChanged, t]
    );

    const removeCreditCard = React.useCallback(
        (cardToRemove: CreditCardPayload) => {
            dispatch(
                accountRemoveCreditCardRoutine.trigger({
                    removalItem: cardToRemove,
                    onSuccess: (data: CreditCardPayload[]) => {
                        if (!hasMembership && cardToRemove?.defaultCard) {
                            // DRX869 - if the user deletes the primary card, select the next card on the list as the primary
                            // The returned data still contains the removed item, so we can't just pull the first item
                            const newPrimaryCard = data.find(
                                (payment: CreditCardPayload) =>
                                    payment.cardActive === true && payment.defaultCard !== true
                            );

                            if (newPrimaryCard) {
                                updatePrimaryCreditCard(newPrimaryCard, { showPrimaryPaymentUpdatedModal: true });
                            }
                        }

                        onCardRemoved(cardToRemove);
                    },
                    onFailure: () => {
                        dispatch(
                            openModal({
                                showClose: false,
                                type: 'danger',
                                size: 'lg',
                                headerContent: (
                                    <BirdiModalHeaderDanger
                                        headerText={t('modals.removePaymentModal.error.title')}
                                        icon="alert"
                                    />
                                ),
                                bodyContent: (
                                    <BirdiModalContentAlt subTitle={t('modals.removePaymentModal.error.description')} />
                                ),
                                ctas: [
                                    {
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        label: t('modals.removePaymentModal.error.cta'),
                                        variant: 'primary'
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        },
        [dispatch, onCardRemoved, updatePrimaryCreditCard]
    );

    const handleAddNewPaymentClick = React.useCallback<NonNullable<ButtonProps['onClick']>>(() => {
        if (isModal) {
            dispatch(setModalStep({ modalStep: 2 }));
        } else
            dispatch(
                openModalComponent({
                    hasDefaultFooter: false,
                    hasModalHeader: false,
                    isCloseable: false,
                    isCentered: true,
                    hasCustomContent: true,
                    content: <PaymentAddNew mode={isEasyRefill ? 'easyRefill' : 'account'} />,
                    customDialogClassName: 'payment-method-v2-modal',
                    onClose: () => {
                        dispatch(closeModalComponent());
                    }
                })
            );
    }, [dispatch, isEasyRefill, isModal]);

    const handleRemoveCardLinkClick = React.useCallback(
        (cardToRemove: CreditCardPayload): PaymentCardProps['onRemoveCardLinkClick'] =>
            () => {
                if (!cardToRemove) {
                    return;
                }
                dispatch(
                    openModal({
                        showClose: true,
                        type: 'danger',
                        size: 'lg',
                        headerContent: (
                            <BirdiModalHeaderDanger headerText={t('modals.removePaymentModal.title')} icon="alert" />
                        ),
                        bodyContent: (
                            <BirdiModalContentAlt
                                subTitle={t('modals.removePaymentModal.body', {
                                    paymentMethod: `<strong>${t('pages.profile.payment.cardTypeAndNum', {
                                        cardNumber: getCreditCardEnding(cardToRemove.secureCardNumber),
                                        cardType: cardToRemove.cardType
                                    })}</strong>`
                                })}
                            />
                        ),
                        ctas: [
                            {
                                onClick: () => {
                                    removeCreditCard(cardToRemove);
                                    dispatch(closeModal({}));
                                },
                                label: 'Continue',
                                variant: 'primary'
                            },
                            {
                                onClick: () => {
                                    dispatch(closeModal({}));
                                },
                                label: 'Cancel',
                                variant: 'ghost'
                            }
                        ]
                    })
                );
            },
        [dispatch, removeCreditCard, t]
    );

    const handleSelectCardRadioInputChange = React.useCallback(
        (card: CreditCardPayload): PaymentCardProps['onSelectCardRadioInputChange'] =>
            () => {
                onCardSelectionChange(card);
            },
        [onCardSelectionChange]
    );

    const handleSetDefaultClick = React.useCallback(
        (card: CreditCardPayload): PaymentCardProps['onSetDefaultClick'] =>
            () => {
                updatePrimaryCreditCard(card);
            },
        [updatePrimaryCreditCard]
    );

    const hasPaymentData = paymentData?.length > 0;

    React.useEffect(() => {
        if (width < 1350) {
            isInnerWidthViewportRef.current = true;
        } else {
            isInnerWidthViewportRef.current = false;
        }
    }, [width]);

    const paymentMethodsClass = classNames('payment-methods', {
        'one-col': !hasMultipleColumns,
        'two-col': hasMultipleColumns
    });

    return (
        <section className={customContainerClasses}>
            <div className={paymentMethodsClass}>
                {hasPaymentData &&
                    sortDefaultPayment
                        .filter((filterCard) => filterCard.cardActive && filterCard.cardIsExpired !== true)
                        .map((card, index) => {
                            return (
                                <PaymentCard
                                    card={card}
                                    cardHolder={convertToTitleCase(card.cardName)}
                                    cardType={card.cardType}
                                    endingDigits={getCreditCardEnding(card.secureCardNumber)}
                                    expiryMonth={card.cardMonthNum}
                                    expiryYear={card.cardYear.slice(-2)}
                                    isDefaultCard={
                                        !hasMembershipFilters
                                            ? Boolean(card.defaultCard)
                                            : Boolean(card.defaultMembershipPayment)
                                    }
                                    isSelectCardRadioInputChecked={
                                        !!selectedCardSeqNum && card.cardSeqNum === selectedCardSeqNum
                                    }
                                    key={`payment-card-${card.secureCardNumber}-${index}`}
                                    onRemoveCardLinkClick={handleRemoveCardLinkClick(card)}
                                    onSelectCardRadioInputChange={handleSelectCardRadioInputChange(card)}
                                    onSetDefaultClick={handleSetDefaultClick(card)}
                                    removeCardLinkClassName={removeCardLinkClassName}
                                    setAsDefaultCardLinkClassName={setAsDefaultCardLinkClassName}
                                    showLabel={showLabel}
                                    showRemoveCardLink={showRemoveCardLink}
                                    showSelectCardRadioInput={showSelectCardRadioInput}
                                    showSetDefaultLink={showSetDefaultLink}
                                    isInnerWidthViewport={isInnerWidthViewportRef}
                                    isMembershipSettings={isMembershipSettings}
                                    isBusy={isBusy}
                                    isDefaultMembershipCard={Boolean(card.defaultMembershipPayment)}
                                />
                            );
                        })}
            </div>
            {isLoading && isModal && (
                <div className="payment-methods__loading">
                    <Spinner t={t} isVisible fullOverlay={false} color="silver" focusOnShow />
                </div>
            )}
            {withNewPaymentButton && (
                <ButtonComponent
                    label={t('pages.profile.membership.manageMembership.addNewPayment')}
                    onClick={handleAddNewPaymentClick}
                    iconLeft={{ icon: 'plus', viewBox: '0 0 24 24', height: '24', width: '24' } as IconProps}
                    variant="dashed"
                />
            )}
            {!hasPaymentData && paymentRequiredMessage && <div className="error">{paymentRequiredMessage}</div>}
        </section>
    );
};

export default PaymentMethods;
