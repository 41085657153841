import { PayloadAction } from '@reduxjs/toolkit';
import { BIRDI_PLANS } from 'enums/plans';
import { AWS_CONFIG_POOLID, AWS_CONFIG_REGION } from 'gatsby-env-variables';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
    accountAcCodeSelector,
    accountHasInsuranceSelector,
    accountIsLoggedInSelector,
    accountProfilEPostPatientNumSelector,
    accountProfileSelector,
    accountProfilIsCaregiverSelector
} from 'state/account/account.selectors';
import AccountService, { ProfileObjectPayload } from 'state/account/account.services';
import { addTransferPrescriptionMemberPlanSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import {
    autoRefillAcCodeSelector,
    autoRefillAccountHasInsuranceSelector,
    autoRefillePostPatientNumberSelector,
    autoRefillFamilyPlansMapSelector,
    autoRefillIsCaregiverSelector,
    autoRefillPricingZipCodeSelector
} from 'state/auto-refill/auto-refill.selectors';
import { cartItemsSelector } from 'state/cart/cart.selectors';
import { DrugWithDiscountPrice, saveDrugPrice } from 'state/drug/drug.reducers';
import {
    drugDescriptionRoutine,
    drugDetailsLookupRoutine,
    drugDiscountPriceRoutine,
    drugFormLookupRoutine,
    drugListRoutine,
    drugLookupRoutine
} from 'state/drug/drug.routines';
import { drugsWithDiscountSelector } from 'state/drug/drug.selectors';
import DrugService, {
    DiscountLookupValues,
    drugDescriptionRoutinePayload,
    drugDetailsRoutinePayload,
    DrugDiscountPricePayload,
    DrugDiscountPriceResponse,
    drugDiscountPriceRoutinePayload,
    drugListRoutinePayload,
    drugLookupRoutinePayload
} from 'state/drug/drug.services';
import {
    easyRefillAccountHasInsuranceSelector,
    easyRefillCartItemsSelector,
    easyRefillEpostPatientNumSelector,
    easyRefillFamilyPlansMapSelector,
    easyRefillIsCaregiverSelector,
    easyRefillPlanAliasSelector,
    easyRefillPricingZipCodeSelector
} from 'state/easy-refill/easy-refill.selectors';
import { familyProfileFamilyPlansMapSelector } from 'state/family-profile/family-profile.selectors';
import { medicineCabinetPricingZipCodeSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

import { RefillRxs } from 'types/order-prescription';

import { birdiPriceRxLineErrorCodes, RxLineErrorCode } from 'util/cart';
import { findDrugInHistory, processDiscountPrice } from 'util/drug';
import { TrackError } from 'util/google_optimize/optimize_helper';
import { doesPlanAllowsPricing } from 'util/pricing';
import { baseEffectHandler } from 'util/sagas/sagas';

export default function* drugSaga() {
    yield takeLatest(drugListRoutine.TRIGGER, function* (action: PayloadAction<drugListRoutinePayload>) {
        const insuranceName: string = yield select(accountAcCodeSelector);
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now perform the drug lookup.
        yield baseEffectHandler({
            service: DrugService.drugList().get,
            data: {
                clientAcCode: insuranceName
            },
            *onResponse(response) {
                // Dispatch the success action in order to update the state of
                // the lookup component and show the returned results.
                yield put(drugListRoutine.success({ insuranceName: insuranceName, formulary: response }));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response);
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugListRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugListRoutine', error.message);
                }
                // Dispatch the failure action to update the state of the lookup
                // component and hide the dropdown.
                yield put(drugListRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(drugLookupRoutine.TRIGGER, function* (action: PayloadAction<drugLookupRoutinePayload>) {
        const insuranceName: string = yield select(accountAcCodeSelector);
        const selectedMemberPlan: number = yield select(addTransferPrescriptionMemberPlanSelector);
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now perform the drug lookup.
        yield baseEffectHandler({
            service: DrugService.drugLookup().get,
            data: {
                drugName: action.payload.drugName,
                clientAcCode: selectedMemberPlan || insuranceName
            },
            *onResponse(response) {
                // Dispatch the success action in order to update the state of
                // the lookup component and show the returned results.
                yield put(drugLookupRoutine.success(response));
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugLookupRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugLookupRoutine', error.message);
                }
                // Dispatch the failure action to update the state of the lookup
                // component and hide the dropdown.
                yield put(drugLookupRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(drugDetailsLookupRoutine.TRIGGER, function* (action: PayloadAction<drugDetailsRoutinePayload>) {
        const insuranceName: string = yield select(accountAcCodeSelector);
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now lookup the drug details.
        yield baseEffectHandler({
            service: DrugService.drugDetailsLookup().get,
            data: {
                drugName: action.payload.drugName,
                gpi: action.payload.gpi,
                clientAcCode: insuranceName
            },
            *onResponse(response) {
                // Dispatch the success action in order to add the drug
                // details to state and update the drug lookup status.
                yield put(drugDetailsLookupRoutine.success(response));

                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response.dosageForms);
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugDetailsLookupRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugDetailsLookupRoutine', error.message);
                }
                // Dispatch the failure action to set the lookup status back to
                // IDLE.
                yield put(drugDetailsLookupRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(drugFormLookupRoutine.TRIGGER, function* (action: PayloadAction<drugDetailsRoutinePayload>) {
        const insuranceName: string = yield select(accountAcCodeSelector);
        const selectedMemberPlan: number = yield select(addTransferPrescriptionMemberPlanSelector);

        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now lookup the drug forms.
        yield baseEffectHandler({
            service: DrugService.drugFormLookup().get,
            data: {
                drugName: action.payload.drugName,
                gpi: action.payload.gpi,
                clientAcCode: selectedMemberPlan || insuranceName
            },
            *onResponse(response) {
                // Dispatch the success action in order to add the drug
                // details to state and update the drug lookup status.
                yield put(drugFormLookupRoutine.success(response));

                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response.dosageForms);
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugFormLookupRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugFormLookupRoutine', error.message);
                }
                // Dispatch the failure action to set the lookup status back to
                // IDLE.
                yield put(drugFormLookupRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(
        drugDiscountPriceRoutine.TRIGGER,
        function* (action: PayloadAction<drugDiscountPriceRoutinePayload>) {
            const { prescriptions, forceBirdiInsurance, location, unAuthArea, planAlias } = action.payload;

            const isLoggedIn: boolean = yield select(accountIsLoggedInSelector);
            const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
            const accessType = isLoggedIn ? 'logged' : unAuthArea === 'auto-refill' ? 'autoRefill' : 'easyRefill';

            const selectors = {
                logged: {
                    insuranceName: accountAcCodeSelector,
                    hasInsurance: accountHasInsuranceSelector,
                    isCaregiver: accountProfilIsCaregiverSelector,
                    familyPlansMap: familyProfileFamilyPlansMapSelector,
                    epostPatientNum: accountProfilEPostPatientNumSelector,
                    cartItems: cartItemsSelector,
                    zipCode: medicineCabinetPricingZipCodeSelector
                },
                easyRefill: {
                    insuranceName: easyRefillPlanAliasSelector,
                    hasInsurance: easyRefillAccountHasInsuranceSelector,
                    isCaregiver: easyRefillIsCaregiverSelector,
                    familyPlansMap: easyRefillFamilyPlansMapSelector,
                    epostPatientNum: easyRefillEpostPatientNumSelector,
                    cartItems: easyRefillCartItemsSelector,
                    zipCode: easyRefillPricingZipCodeSelector
                },
                autoRefill: {
                    insuranceName: autoRefillAcCodeSelector,
                    hasInsurance: autoRefillAccountHasInsuranceSelector,
                    isCaregiver: autoRefillIsCaregiverSelector,
                    familyPlansMap: autoRefillFamilyPlansMapSelector,
                    epostPatientNum: autoRefillePostPatientNumberSelector,
                    cartItems: cartItemsSelector, // Just to avoid error, since there is no cart inside auto-refill
                    zipCode: autoRefillPricingZipCodeSelector
                }
            };

            const hasInsurance: boolean = yield select(selectors[accessType].hasInsurance);
            const insuranceName: string = yield select(selectors[accessType].insuranceName);
            const isCaregiver: boolean = yield select(selectors[accessType].isCaregiver);
            const familyPlansMap: Record<string, string> = yield select(selectors[accessType].familyPlansMap);
            const epostPatientNum: string = yield select(selectors[accessType].epostPatientNum);
            const zipCode: string | null = yield select(selectors[accessType].zipCode);
            const selectedMemberPlan = yield select(addTransferPrescriptionMemberPlanSelector);
            const drugDiscountPriceHistory: DrugWithDiscountPrice[] = yield select(drugsWithDiscountSelector);

            const currentCartItem: RefillRxs[] = yield select(selectors[accessType].cartItems);

            // This action is always called following medicineCabinetGetAllP rescriptions.trigger,
            // but that action does not *always* run after the profileObject has been loaded.
            // So just check to make sure that profileObject exists before proceeding.
            // Whatever is dispatching this action is responsible for making sure that it
            // does so after the profileObject has been loaded.
            // Also, don't run this action if the user does have insurance.
            if (isLoggedIn && (!profileObject || (hasInsurance && !forceBirdiInsurance))) {
                return;
            }

            // Fetch the AWS credentials.
            yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

            const drugsForPricing: DiscountLookupValues[] = prescriptions
                .map((item) => {
                    const drugCode =
                        item.dispensedProductNumber !== ''
                            ? item.dispensedProductNumber
                            : item.writtenProductNumber !== ''
                            ? item.writtenProductNumber
                            : '';

                    // We default the plan name on the insurance name
                    let clientAcCode = insuranceName;

                    // If we directly receive a selectedMemberPlan we use this value instead
                    if (selectedMemberPlan) {
                        clientAcCode = selectedMemberPlan;
                    }

                    // But if we don't receive a plan and the user is a caregiver
                    // we look into the family members array the plan of the patient.
                    if (!selectedMemberPlan && isCaregiver && epostPatientNum !== item.epostPatientNum) {
                        clientAcCode = familyPlansMap[item.epostPatientNum] || BIRDI_PLANS.BRD_01;
                    }

                    // Birdi Price selection for insured accounts
                    // Find insurance rxLineErrors
                    const findRxLineError = currentCartItem?.find((f) => f.rxNumber === item.rxNumber)?.rxLineError;
                    // 40, 65, 70 errors
                    const hasCashPriceErrors = birdiPriceRxLineErrorCodes.includes(findRxLineError as RxLineErrorCode);
                    // change the clientAcCode to BRD01
                    if (hasInsurance && hasCashPriceErrors) {
                        clientAcCode = BIRDI_PLANS.BRD_01;
                    }

                    return {
                        drugCode,
                        quantity: item.fillQuantity,
                        daysSupply: item.fillDaysSupply,
                        // If we receive a static plan Alias we override any selection with that value.
                        clientAcCode: planAlias ?? clientAcCode,
                        memberNumber: isLoggedIn ? profileObject.cardholderID : '',
                        location: location,
                        zipCode,
                        gpi: item.genericProductIndicator,
                        rxNumber: item.rxNumber
                    };
                })
                // Ensure that only plans that allow pricing will render prices
                .filter((item) => doesPlanAllowsPricing(item.clientAcCode));

            // Call the drug discount price lookup for each of the drugs in parallel.
            const simultaneousRequests = 9;
            for (let i = 0; i < drugsForPricing.length; i += simultaneousRequests) {
                const rxSlice = drugsForPricing.slice(i, i + simultaneousRequests);

                yield all(
                    rxSlice.map((item) => {
                        const { rxNumber, ...data } = item;

                        // Ensure that we are not trying to fetch pricing for drugs
                        // that we already have the price for.
                        const foundInHistory = findDrugInHistory(item, drugDiscountPriceHistory);

                        if (foundInHistory) {
                            return put(drugDiscountPriceRoutine.success(foundInHistory));
                        }

                        // The following throws a TypeScript error ("No overload
                        // matches this call"). Not convinced that this is a legitimate
                        // issue.
                        // @ts-expect-error: Reasoning above.
                        return call(baseEffectHandler, {
                            service: DrugService.drugDiscountPriceLookup().get,
                            data,
                            *onResponse(response: DrugDiscountPriceResponse) {
                                // We map the object to be returned
                                const payload: DrugDiscountPricePayload = {
                                    response,
                                    rxNumber,
                                    zipCode: data.zipCode,
                                    drugCode: data.drugCode
                                };
                                const discountPrice: DrugWithDiscountPrice = processDiscountPrice(payload);

                                // Save the drugs in the history.
                                yield put(saveDrugPrice(discountPrice));

                                // Return the mapped object
                                yield put(drugDiscountPriceRoutine.success(discountPrice));

                                // For OnSuccess Events return the price data
                                const { onSuccess } = action.payload;
                                if (onSuccess) onSuccess(discountPrice);
                            },
                            *onError(error) {
                                yield put(drugDiscountPriceRoutine.failure(rxNumber));
                                const { onFailure } = action.payload;
                                if (onFailure) onFailure({ response: error });
                                // Log the error.
                                if (error.response?.data) {
                                    // This is an attempt to catch errors passed back from the API.
                                    if (error.response?.data?.status) {
                                        TrackError(
                                            'drug.sagas.ts',
                                            'drugDiscountPriceRoutine',
                                            error.response.data.status
                                        );
                                    } else {
                                        TrackError(
                                            'drug.sagas.ts',
                                            'drugDiscountPriceRoutine',
                                            error.response.data.message
                                        );
                                    }
                                } else if (error.message) {
                                    // This is an attempt to catch basic network errors.
                                    TrackError('drug.sagas.ts', 'drugDiscountPriceRoutine', error.message);
                                } else {
                                    TrackError('drug.sagas.ts', 'drugDiscountPriceRoutine', error);
                                }
                            }
                        });
                    })
                );
            }
        }
    );
    yield takeLatest(drugDescriptionRoutine.TRIGGER, function* (action: PayloadAction<drugDescriptionRoutinePayload>) {
        try {
            //CALL BEFORE ROUTINE
            yield put(drugDescriptionRoutine.request());

            // Get AWS credentials.
            yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

            // HANDLERS
            yield baseEffectHandler({
                service: DrugService.drugDescriptionLookup().get,
                data: action.payload,
                *onResponse(response) {
                    if (response.htmlDesc) {
                        yield put(drugDescriptionRoutine.success(response));
                        const { onSuccess } = action.payload;
                        // CALLBACK SUCCESS
                        if (onSuccess) onSuccess(response);
                    } else {
                        yield put(drugDescriptionRoutine.failure());
                        const { onFailure } = action.payload;
                        // CALLBACK ERROR
                        if (onFailure) onFailure();
                    }
                },
                *onError(error) {
                    if (error.response?.data?.message) {
                        TrackError('drug.sagas.ts', 'drugDescriptionRoutine', error.response.data.message);
                    } else if (error.message) {
                        TrackError('drug.sagas.ts', 'drugDescriptionRoutine', error.message);
                    }
                    // CALLBACK ERROR
                    yield put(drugDescriptionRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            });
        } catch (error) {
            // CALLBACK ERROR
            yield put(drugDescriptionRoutine.failure());
        }
    });
}
