import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect } from 'react';

// UI Kit
import BackgroundImage from 'ui-kit/background-image/background-image';
import PageSection from 'ui-kit/page-section/page-section';

// Components
import AlertBanner from 'components/alert-banner/alert-banner.component';
import BirdiModal from 'components/birdi-modal/birdi-modal';
import CookieBanner from 'components/cookie-banner/cookie-banner.component';
import Footer from 'components/footer/footer.component';
import InfoBanner from 'components/info-banner/info-banner.component';
import { PageLayoutProps } from 'components/layouts/page/page.props';
import ModalComponent from 'components/modal/modal.component';
import Navigation from 'components/navigation/navigation.component';
import SEO from 'components/seo/seo.component';

// Utils
import { InitOptimize } from 'util/google_optimize/optimize_helper';

// Hoc
import withBasicAuth from 'hoc/withBasicAuth';
import withSecurity from 'hoc/withSecurity';
import withSessionExpiration from 'hoc/withSessionExpiration';

// Providers
import { paragraphToComponent } from 'providers/paragraphs/paragraphs';

import './page.layout.scss';

const PageLayout = ({
    children,
    headerImage,
    headerImageStyle,
    headerImageClassName,
    isSecurePage,
    isLoggedIn,
    pageContentClassName,
    metaData,
    suppressApplicationPage,
    legalPaths,
    isShortFooter = false,
    hideSpecialtyAddress = false,
    hideHeaderMenu = false
}: PageLayoutProps) => {
    const data = AlertBannerAndMenusQuery();
    const replacePath = (path: string) => (path === `/` ? path : path.replace(/\/$/, ``));
    const pageLocation = replacePath(useLocation().pathname);
    const pageClasses = classNames('page');
    const pageContentClasses = classNames('page-content', pageContentClassName);

    // See if there is any Drupal Application Page content that matches this page URL
    const applicationPageParagraphs = data.allNodeApplicationPage.nodes.filter((node: any) => {
        if (node.field_ap_show_on_subpages) {
            // partial match
            return pageLocation.substring(0, node.path?.alias?.toString().length) === node.path?.alias?.toString();
        } else {
            // exact match
            return pageLocation === node.path?.alias?.toString();
        }
    });

    useEffect(() => {
        InitOptimize();
    }, []);

    return (
        <div className={pageClasses}>
            <SEO
                nodeTitle={metaData?.nodeTitle}
                robots={metaData?.robots}
                title={metaData?.title}
                abstract={metaData?.abstract}
                description={metaData?.description}
                keywords={metaData?.keywords}
                applicationPageMetaData={applicationPageParagraphs[0]?.field_meta_tags}
            >
                {children}
            </SEO>
            {data.allBlockContentAlertBanner.nodes[0] && (
                <AlertBanner
                    message={data.allBlockContentAlertBanner.nodes[0].field_alert_text}
                    link={data.allBlockContentAlertBanner.nodes[0].field_alert_link?.title}
                    linkLocation={data.allBlockContentAlertBanner.nodes[0].field_alert_link?.uri}
                    variant={
                        data.allBlockContentAlertBanner.nodes[0].field_alert_priority_level
                            ? data.allBlockContentAlertBanner.nodes[0].field_alert_priority_level
                            : 'dark'
                    }
                    permanent={
                        data.allBlockContentAlertBanner.nodes[0].field_alert_permanent
                            ? data.allBlockContentAlertBanner.nodes[0].field_alert_permanent
                            : true
                    }
                    showResponsive={data.allBlockContentAlertBanner.nodes[0].field_alert_show_responsive}
                />
            )}
            <Navigation isSecurePage={isSecurePage} isLoggedIn={isLoggedIn} showMainMenu={!hideHeaderMenu} />
            {headerImage && (
                <BackgroundImage image={headerImage} style={headerImageStyle} className={headerImageClassName} />
            )}
            <InfoBanner dismissible={true} icon={'alert'} permanent={true} className={'info-banner'} />

            <div className={pageContentClasses}>
                {children}
                {!suppressApplicationPage &&
                    applicationPageParagraphs[0]?.relationships?.field_application_page_content.map(
                        (paragraph: any, index: number) => (
                            <PageSection key={`application_page_section_${index}`}>
                                {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                            </PageSection>
                        )
                    )}
            </div>
            <BirdiModal />
            <ModalComponent />
            <Footer
                isShortFooter={isShortFooter}
                copyrightYear={new Date().getFullYear().toString()}
                aboutUsPaths={data.allMenuLinkContentFooter.nodes}
                socialMediaPaths={data.allMenuLinkContentFooterSocial.nodes}
                howCanWeHelpPaths={data.allMenuLinkContentFooterSupport.nodes}
                legalPaths={
                    legalPaths && legalPaths?.length > 0 ? legalPaths : data.allMenuLinkContentFooterLegal.nodes
                }
                solutionsPaths={data.allMenuLinkContentFooterCompany.nodes}
                getAppPaths={data.allMenuLinkContentFooterGetTheApp.nodes}
                contactPaths={data.allMenuLinkContentFooterContact.nodes}
                groupsPaths={data.allMenuLinkContentFooterGroupsBusiness.nodes}
                physicalAddress={data.allBlockContentAddresses.nodes[0]?.field_physical_address}
                mailingAddress={data.allBlockContentAddresses.nodes[0]?.field_mailing_address}
                specialtyMailingAddress={
                    hideSpecialtyAddress
                        ? null
                        : data.allBlockContentAddresses.nodes[0]?.field_specialty_mailing_address
                }
                specialtyPhysicalAddress={
                    hideSpecialtyAddress
                        ? null
                        : data.allBlockContentAddresses.nodes[0]?.field_specialty_physical_address
                }
            />
            <CookieBanner />
        </div>
    );
};

export default withBasicAuth(withSessionExpiration(withSecurity(PageLayout)));

export const AlertBannerAndMenusQuery = () => {
    const AlertBannerAndMenus = useStaticQuery<any>(graphql`
        {
            allBlockContentAlertBanner(
                sort: { fields: changed, order: DESC }
                filter: { field_alert_active: { eq: true } }
            ) {
                nodes {
                    field_alert_text
                    field_alert_priority_level
                    field_alert_permanent
                    field_alert_link {
                        title
                        uri: url
                    }
                    field_alert_show_responsive
                }
            }
            allBlockContentAddresses(sort: { fields: changed, order: DESC }) {
                nodes {
                    field_physical_address
                    field_mailing_address
                    field_specialty_mailing_address
                    field_specialty_physical_address
                }
            }
            allMenuLinkContentFooter(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    link {
                        url
                    }
                    label: title
                    langcode
                }
            }
            allMenuLinkContentFooterSocial(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    link {
                        url
                    }
                    label: title
                    langcode
                }
            }
            allMenuLinkContentFooterSupport(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    link {
                        url
                    }
                    label: title
                    field_menu_body {
                        processed
                    }
                    langcode
                }
            }
            allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    link {
                        url
                    }
                    label: title
                    langcode
                }
            }
            allMenuLinkContentFooterCompany(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    link {
                        url
                    }
                    label: title
                    langcode
                }
            }
            allMenuLinkContentFooterGetTheApp(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    badgeType: field_badge_type
                    link {
                        url
                    }
                    label: title
                    langcode
                }
            }
            allMenuLinkContentFooterContact(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    link {
                        url
                    }
                    label: title
                    field_menu_body {
                        processed
                    }
                    langcode
                }
            }
            allMenuLinkContentFooterGroupsBusiness(
                sort: { order: ASC, fields: weight }
                filter: { enabled: { eq: true } }
            ) {
                nodes {
                    link {
                        url
                    }
                    label: title
                    langcode
                }
            }
            allNodeApplicationPage {
                nodes {
                    title
                    id
                    drupal_internal__nid
                    relationships {
                        field_application_page_content {
                            ...supportedParagraphs
                        }
                    }
                    path {
                        langcode
                        alias
                    }
                    field_ap_show_on_subpages
                    field_meta_tags {
                        title
                        description
                        abstract
                        keywords
                    }
                }
            }
        }
    `);
    return AlertBannerAndMenus;
};
