import { GATSBY_API_BASE_URL } from 'gatsby-env-variables';

import { CancelOrderLinePayload, CartPayload } from 'types/cart';
import { Order } from 'types/order-prescription';

import axiosClient from 'util/axiosClient';

/**
 * Anything related to Cart in the system.
 */
export default class CartService {
    static startCart() {
        return {
            getAll: (): Promise<CartPayload[]> => {
                return axiosClient.get(`${GATSBY_API_BASE_URL}/api/Order/GetCart`).then((response: { data: Order }) => {
                    const { refillRxs, orderHeader, ...params } = response.data;

                    return [
                        {
                            Order: {
                                orderHeader: {
                                    ...orderHeader
                                },
                                refillRxs: refillRxs,
                                ...params
                            }
                        }
                    ] as CartPayload[];
                });
            },
            getAllV2: (): Promise<CartPayload[]> => {
                return axiosClient.get(`${GATSBY_API_BASE_URL}/api/v2/Order/GetCart`).then((response) => {
                    return response.data;
                });
            },
            post: (payload: { epostPatientNum: string; updatedCartObject: CartPayload }): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Order/StartOrder`, payload.updatedCartObject?.Order)
                    .then((response) => {
                        return {
                            EpostPatientNum: payload.updatedCartObject?.EpostPatientNum,
                            Order: response.data
                        };
                    });
            },
            postV2: (payload: { epostPatientNum: string; updatedCartObject: CartPayload }): Promise<any> => {
                return axiosClient
                    .post(
                        `${GATSBY_API_BASE_URL}/api/v2/Order/StartOrder?epostPatientNum=${payload.epostPatientNum}`,
                        payload.updatedCartObject
                    )
                    .then((response) => response.data);
            }
        };
    }
    static completeOrder() {
        return {
            post: (payload?: CartPayload[]): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Order/CompleteOrder`, payload && payload[0].Order)
                    .then((response) => {
                        return [{ Order: response.data }];
                    });
            },
            postV2: (payload?: CartPayload[]): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/v2/Order/CompleteOrder`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static updateRefillLines() {
        return {
            post: (payload?: Order): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Order/updateRefillLines`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static cancelOrderLine() {
        return {
            post: (payload?: Order): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Order/CancelOrderLine`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static cancelOrder() {
        return {
            post: (payload?: CancelOrderLinePayload): Promise<any> => {
                return axiosClient
                    .post(
                        `${GATSBY_API_BASE_URL}/api/Order/CancelOrderLine?rxNumber=${payload?.rxNumber}&lineId=${payload?.lineId}&orderNum=${payload?.orderNum}`
                    )
                    .then((response) => response.data);
            },
            postV2: (payload?: CancelOrderLinePayload): Promise<any> => {
                return axiosClient
                    .post(
                        `${GATSBY_API_BASE_URL}/api/v2/Order/CancelOrderLine?rxNumber=${payload?.rxNumber}&lineId=${payload?.lineId}&orderNum=${payload?.orderNum}&epostNumPatient=${payload?.epostNumPatient}`
                    )
                    .then((response) => response.data);
            }
        };
    }
    static updateCart() {
        return {
            post: (payload?: Order): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/v2/Order/UpdateCart/false`, payload)
                    .then((response) => {
                        return response;
                    });
            }
        };
    }
}
