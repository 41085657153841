import {
    LexRuntimeV2Client as LexRuntimeServiceClient,
    RecognizeTextCommand,
    RecognizeTextCommandInput
} from '@aws-sdk/client-lex-runtime-v2';
import {
    Avatar,
    ChatContainer,
    MainContainer,
    Message,
    MessageGroup,
    MessageInput,
    MessageList,
    TypingIndicator
} from '@chatscope/chat-ui-kit-react';
import ChatIcon from 'assets/icons/chat-icon.inline.svg';
import FillDownCircle from 'assets/icons/fill-down-circle.inline.svg';
import HamburgerMenu from 'assets/icons/hamburger.inline.svg';
import HelpIcon from 'assets/icons/help-icon.inline.svg';
import BirdiLogo from 'assets/icons/pill-logo.inline.svg';
import ThumbsDown from 'assets/icons/thumbs-down.inline.svg';
import ThumbsUp from 'assets/icons/thumbs-up.inline.svg';
import { Amplify } from 'aws-amplify';
// Chatbot
import AWS from 'aws-sdk/global';
import classNames from 'classnames';
import {
    ENABLE_AMPLIFY_CHATBOT,
    ENABLE_CHATBOT_FEEDBACK,
    ENABLE_CONVERSATIONAL_AI,
    REACT_APP_BOT_ALIAS_ID,
    REACT_APP_BOT_ID,
    REACT_APP_BOT_LOCALE_ID,
    REACT_APP_IDENTITY_POOL_ID,
    REACT_APP_QNACLIENTFILTER,
    REACT_APP_REGION
} from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useEffect, useRef, useState } from 'react';
// UI Kit & Components
import { Dropdown, Button as ReactButton } from 'react-bootstrap';
import { Message as AmplifyMessage } from 'react-chat-ui';
import { useDispatch, useSelector } from 'react-redux';

// Account
import { accountGetAwsCredsRoutine } from 'state/account/account.routines';
import {
    accountHasInsuranceSelector,
    accountIsLoggedInSelector,
    accountIsNavitusUserSelector,
    accountPlansSelector,
    accountProfileSelector,
    accountStateSelector
} from 'state/account/account.selectors';
import { startCartRoutine } from 'state/cart/cart.routines';
import { cartItemsSelector, cartOrderShippingAddressSelector } from 'state/cart/cart.selectors';
// States - Chatbot
import { setLexRuntime } from 'state/chatbot/chatbot.reducers';
import { chatbotLexRuntimeSelector } from 'state/chatbot/chatbot.selectors';
// MedicineCabinet
import { drugSelector } from 'state/drug/drug.selectors';
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';
import { medicineCabinetPrescriptionsSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

// Cart
import { CartPayload } from 'types/cart';
// Utils
import { RefillRxs } from 'types/order-prescription';

import config from 'util/aws-exports';
import { processCart } from 'util/cart';
import dynamicGreeting from 'util/dynamicGreeting';
import { TrackEvent, TrackGenericEvent } from 'util/google_optimize/optimize_helper';
import { removeHtmlTagsFromString } from 'util/string';

// Interfaces and Types
import {
    ChatbotFeedbackProps,
    ChatbotMessageButton,
    ChatbotParsedMessage,
    ChatBotProps,
    ChatbotResponseMessage,
    ChatbotResponseMessageList
} from './chatbot-v2.types';
// Styles
import './chatbot.style.scss';

// Helper function to parse the message stored inside the messages property.
// For non QnA bots, we receive an array of messages that contains the message
// for the user and in other cases, a list of action buttons.
const getChatbotParsedMessages = (messages: ChatbotResponseMessageList): ChatbotParsedMessage => {
    let textMessage = '';
    let buttons: ChatbotMessageButton[] = [];

    if (messages && Array.isArray(messages)) {
        // eslint-disable-next-line array-callback-return
        messages.map((message: ChatbotResponseMessage) => {
            switch (message.contentType) {
                case 'PlainText':
                case 'CustomPayload':
                    textMessage = message.content ? message.content : '';
                    break;
                case 'ImageResponseCard':
                    buttons = message.imageResponseCard?.buttons ? message.imageResponseCard?.buttons : [];
                    break;
            }
        });
    }

    // Convert \n into html line break.
    textMessage = textMessage.split('\n').join('<br />');

    // Ticket: DRX-2629
    // Remove text references
    // E.g. ...is ready for delivery【9†source】.
    textMessage = textMessage.replace(/【(.*?)】/gi, '');

    return {
        textMessage,
        buttons
    };
};

// Helper function to parse the message stored inside the app context property,
// usually sent by the QnA bot (Question and Answer):
// sessionState.sessionAttributes.appContext
const getChatbotParsedAppContext = (appContext: string): ChatbotParsedMessage => {
    const parsedAppContext = JSON.parse(appContext.replace('`', ''));

    let textMessage = '';
    let buttons: ChatbotMessageButton[] = [];

    if (parsedAppContext?.altMessages?.markdown) {
        textMessage = parsedAppContext.altMessages.markdown;
    }

    if (parsedAppContext?.responseCard?.genericAttachments) {
        buttons = parsedAppContext.responseCard.genericAttachments[0].buttons;
    }

    return {
        textMessage,
        buttons
    };
};

// Helper function to show the chatbot feedback actions (thumbs up and down)
const ChatbotFeedback = ({ onThumbsUp, onThumbsDown }: ChatbotFeedbackProps): ReactElement => {
    return (
        <div className="text-right">
            <ReactButton className="thumbsup bg-transparent p-0 border-0 no-min-width" onClick={onThumbsUp}>
                <ThumbsUp />
            </ReactButton>
            <ReactButton className="thumbsdown bg-transparent p-0 border-0 no-min-width" onClick={onThumbsDown}>
                <ThumbsDown />
            </ReactButton>
        </div>
    );
};

// Main component
const Chatbot = ({ uniqueID, isInCart, bottomHeight }: ChatBotProps): ReactElement => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>();

    const [sessionAttributes, setSessionAttributes] = useState({});
    const [messages, setMessages] = useState<any[]>([]);
    const [expanded, setExpanded] = useState(false);
    const [userAvatarText, setUserAvatarText] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const defaultMessage = ENABLE_CONVERSATIONAL_AI
        ? t('components.chatbot.defaultMessageConversationalAI', {
              greeting: dynamicGreeting(
                  t('components.chatbot.greeting.morning'),
                  t('components.chatbot.greeting.afternoon'),
                  t('components.chatbot.greeting.evening')
              )
          })
        : t('components.chatbot.defaultMessage');
    const defaultUnrecognizedMessage = t('components.chatbot.defaultUnrecognizedMessage');

    const { token } = useSelector(accountStateSelector);
    const isLoggedIn = useSelector(accountIsLoggedInSelector);
    const profileObject = useSelector(accountProfileSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const prescriptions = useSelector(medicineCabinetPrescriptionsSelector);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const cartItemsObject = useSelector(cartItemsSelector);
    const cardOrderShippingAddress = useSelector(cartOrderShippingAddressSelector);
    const lexRuntimeService = useSelector(chatbotLexRuntimeSelector);
    const isNavitus = useSelector(accountIsNavitusUserSelector);

    useEffect(() => {
        const initials = `${profileObject?.patientFirstName ? profileObject?.patientFirstName.charAt(0) : ''}${
            profileObject?.patientLastName ? profileObject?.patientLastName.charAt(0) : ''
        }`;
        setUserAvatarText(initials);
    }, [profileObject]);

    const handleSend = (messageText: string) => {
        submitMessage(messageText);
    };

    const addMessage = (message: AmplifyMessage) => {
        setMessages((currentMessages: AmplifyMessage[]) => {
            return [...currentMessages, message];
        });
    };

    const clearMessages = () => {
        setMessages([]);
        setInitialMessage();
    };

    /**
     * Ticket: DRX-2629
     * First version of Robin chat expects the first message to be sent to start the conversation.
     * As the Conversational AI responds to every interaction, we can't send the default message
     * as the first message. So, we add the Amplify response hardcoded to the messages state
     * to show the first message to the user.
     */
    const setInitialMessage = () => {
        let initialMessage: AmplifyMessage = {
            id: 1,
            message: {
                messages: [{ content: defaultMessage, contentType: 'PlainText' }]
            }
        };

        if (!ENABLE_CONVERSATIONAL_AI) {
            initialMessage = new AmplifyMessage({
                id: 1,
                message: {
                    message: defaultMessage
                }
            });
        }

        addMessage(initialMessage);
    };

    const submitMessage = async (input: string, addToHistory = true) => {
        if (input === '') return;

        // Added a way just to confirm what chatbot version is being used.
        if (input === 'chatbot --version') {
            let chatbotVersion = 'v2';

            if (ENABLE_CONVERSATIONAL_AI) {
                chatbotVersion = 'v2.2';
            }
            // eslint-disable-next-line no-console
            console.log(`Chatbot version: ${chatbotVersion}`);
        }

        setIsSubmitting(true);
        const inputToSend = input;
        const userMessage = new AmplifyMessage({
            id: 0,
            message: input
        });

        if (addToHistory) {
            addMessage(userMessage);
        }
        TrackEvent('chatbotQuestion', input);
        await communicateLex(inputToSend, function (data) {
            setIsSubmitting(false);
            inputRef?.current?.focus();

            const responseMessage = new AmplifyMessage({
                id: 1,
                message: data
            });
            setSessionAttributes(data.sessionState.sessionAttributes);
            addMessage(responseMessage);
            if (data.sessionAttributes?.trackEvent) {
                const trackEventData = JSON.parse(data.sessionAttributes.trackEvent);
                TrackGenericEvent(trackEventData);
            }
        });
    };

    const getLexExpireTime = async () =>
        new Promise((resolve, reject) => {
            if (lexRuntimeService) {
                lexRuntimeService.config.credentials().then((creds) => {
                    resolve(creds.expireTime);
                });
            } else {
                resolve(undefined);
            }
        });

    const getLex = async () =>
        new Promise((resolve, reject) => {
            const currenTime = new Date();
            getLexExpireTime().then((expireTime) => {
                if (expireTime && currenTime < expireTime) {
                    // credentials are still valid
                    resolve(lexRuntimeService);
                } else {
                    dispatch(
                        accountGetAwsCredsRoutine.trigger({
                            region: REACT_APP_REGION,
                            poolID: REACT_APP_IDENTITY_POOL_ID,
                            onSuccess: (awsCredMessage: string) => {
                                if (awsCredMessage !== 'Credentials already exist') {
                                    AWS.config.credentials.get(function (err) {
                                        if (err) {
                                            console.error('AWS Credentials Error: ', err);
                                            return;
                                        } else {
                                            Amplify.configure(config);
                                            const lex = new LexRuntimeServiceClient({
                                                region: config.aws_cognito_region,
                                                credentials: AWS.config.credentials
                                            });
                                            /**
                                             * Hotfix DRX-2223: We moved the lexRuntimeService from a local state to redux
                                             * because the chat was implemented into a place where the component is mounted
                                             * and unmounted each time that user opens the sidebar on mobile.
                                             *
                                             * Each time that this component is mounted, a call to AWS is made passing its
                                             * credentials. If we do a second call, the credentials are already created, and
                                             * assuming this, we already have the lexRuntimeService stored in the component.
                                             *
                                             * But, as the component is unmounted and mounted again, we loose this local
                                             * data from lexRuntimeService. This is the reason why we moved this info into a
                                             * redux state.
                                             */
                                            dispatch(setLexRuntime({ lexRuntimeService: lex }));
                                            resolve(lex);
                                        }
                                    });
                                } else {
                                    resolve(lexRuntimeService);
                                }
                            }
                        })
                    );
                }
            });
        });

    const communicateLex = async (message: string, lsdata: (data: unknown) => void) => {
        const newSessionAttributes = {
            ...sessionAttributes,
            localTimeZone: JSON.stringify(Intl.DateTimeFormat().resolvedOptions().timeZone),
            isLoggedIn: isLoggedIn ? 'true' : 'false',
            jwtToken: isLoggedIn ? token : '',
            memberType: isLoggedIn ? (accountHasInsurance ? 'INS' : 'BRD') : null,
            QNAClientFilter: isLoggedIn && isNavitus ? 'Navitus' : REACT_APP_QNACLIENTFILTER,
            trackEvent: undefined
        };

        const params: RecognizeTextCommandInput = {
            // RecognizeTextRequest
            botId: REACT_APP_BOT_ID, // required
            botAliasId: REACT_APP_BOT_ALIAS_ID, // required
            localeId: REACT_APP_BOT_LOCALE_ID, // required
            sessionId: uniqueID, // required
            text: removeHtmlTagsFromString(message), // required
            sessionState: { sessionAttributes: newSessionAttributes },
            activeContexts: [
                {
                    name: 'string',
                    timeToLive: {
                        timeToLiveInSeconds: 600,
                        turnsToLive: 10
                    },
                    parameters: {
                        string: 'string'
                    }
                }
            ]
        };

        const command = new RecognizeTextCommand(params);

        getLex().then((lex: unknown) => {
            lex.send(command)
                .then((data: unknown) => {
                    lsdata(data);
                })
                .catch((error: unknown) => {
                    lsdata(error);
                });
        });
    };

    const iconicTouch = () => {
        if (messages.length === 0) {
            setInitialMessage();

            // If we have Conversational AI enabled, we can't send Help as a message
            // because there is no behavior expected for this message.
            if (!ENABLE_CONVERSATIONAL_AI) {
                submitMessage('Help');
            }
        }
        setExpanded(true);
    };
    const minimizeTouch = () => {
        setExpanded(false);
    };

    useEffect(() => {
        if (messages.length > 0) {
            const messageWithCartModel = messages.find((message) => {
                if (typeof message.message === 'object') {
                    return message.message?.sessionAttributes?.cartModel;
                }

                return false;
            });

            if (messageWithCartModel) {
                const cartModel = JSON.parse(messageWithCartModel.message?.sessionAttributes?.cartModel);

                cartModel.refillRxs.map((rx: RefillRxs) => {
                    const prescription = prescriptions.find((p) => p.rxNumber == rx.rxNumber);

                    dispatch(
                        startCartRoutine.trigger({
                            rxNumber: rx.rxNumber,
                            epostPatientNum: prescription?.epostPatientNum,
                            onSuccess: (data: CartPayload[]) => {
                                dispatch(medicineCabinetGetAllPrescriptions.trigger({ showNewRxModal: false }));
                                processCart(
                                    data,
                                    accountHasInsurance,
                                    prescriptions,
                                    drugDiscountPrices,
                                    accountPlans,
                                    undefined,
                                    cardOrderShippingAddress?.zipcode || ''
                                );
                            }
                        })
                    );
                });

                delete messageWithCartModel.message?.sessionAttributes?.cartModel;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItemsObject, dispatch, messages]);

    useEffect(() => {
        if (!isLoggedIn) {
            // DRX-3723 (https://mincainc.atlassian.net/browse/DRX-3723)
            // Needs to manually clear messages after user logs out
            clearMessages();
        }
    }, [isLoggedIn]);

    const containerClass = classNames(
        {
            'robinChatbot-inCart': isInCart,
            robinChatbot: !isInCart,
            'chat-open': expanded
        },
        'mr-0',
        'd-print-none'
    );

    return (
        <>
            {ENABLE_AMPLIFY_CHATBOT && (
                <div className={containerClass} style={{ bottom: !isInCart ? bottomHeight : undefined }}>
                    <ReactButton
                        aria-label={t('components.chatbot.openRobinChat')}
                        className={`iconcons btn border-0 p-3 bg-cathams-blue no-min-width btn-primary no-min-width ${
                            expanded ? 'd-none' : 'd-inline-block'
                        }`}
                        onClick={iconicTouch}
                    >
                        <ChatIcon />
                    </ReactButton>
                    <div id="botcon" className={expanded ? 'shadow-lg d-flex flex-column' : 'd-none'}>
                        <div className="header bg-cathams-blue p-2">
                            <div className="d-inline-block first">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdown-basic"
                                        aria-label="Message options"
                                        className="d-inline-block text-white p-0 rounded-0 bg-transparent border-0 no-min-width"
                                    >
                                        <HamburgerMenu />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="p-0">
                                        <ReactButton
                                            aria-label="Clear chat"
                                            className="no-min-width dropdown-item text-dark rounded-0"
                                            onClick={clearMessages}
                                        >
                                            Clear Chat
                                        </ReactButton>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="d-inline-block title text-white align-bottom ml-2 font-weight-bold headerTitle">
                                {t('components.chatbot.chatWithRobin')}
                            </div>
                            <div className="float-right">
                                <ReactButton
                                    aria-label="Help"
                                    className="d-inline-block bg-transparent rounded-0 border-0 ml-2 p-0 pl-3 no-min-width"
                                    onClick={() => submitMessage('help')}
                                >
                                    <HelpIcon />
                                </ReactButton>
                                <ReactButton
                                    aria-label={t('components.chatbot.closeRobinChat')}
                                    className="d-inline-block bg-transparent rounded-0 border-0 ml-2 p-0 pl-3 pr-2 no-min-width"
                                    onClick={minimizeTouch}
                                >
                                    <FillDownCircle />
                                </ReactButton>
                            </div>
                        </div>

                        <MainContainer>
                            <ChatContainer>
                                <MessageList typingIndicator={isSubmitting ? <TypingIndicator /> : <></>}>
                                    {messages.map((entry, index) => {
                                        // If the message was sent by the user, the entry ID is equal to 0.
                                        if (entry.id === 0) {
                                            return (
                                                <Message
                                                    model={{
                                                        type: 'html',
                                                        direction: 'outgoing'
                                                    }}
                                                    key={`message_${index}`}
                                                >
                                                    {isLoggedIn && userAvatarText !== '' ? (
                                                        <Avatar>{userAvatarText}</Avatar>
                                                    ) : (
                                                        <Message.Header sender={'You'} />
                                                    )}
                                                    <Message.HtmlContent html={entry.message} />
                                                </Message>
                                            );
                                        } else {
                                            let contactLink = null;
                                            let messageObject: ChatbotParsedMessage = {
                                                textMessage: '',
                                                buttons: []
                                            };

                                            /**
                                             * Ticket: DRX-2629
                                             * If we have conversational AI feature enabled, we don't need to validate what bot is
                                             * sending the response, because in this case we do have just one bot. So, all the
                                             * messages will be present inside the "messages" object.
                                             */
                                            if (ENABLE_CONVERSATIONAL_AI) {
                                                messageObject = getChatbotParsedMessages(entry.message.messages);

                                                /**
                                                 * After ticket DRX-2629, this block is a roll back in casa of conversational AI presents
                                                 * some kind of issue.
                                                 *
                                                 *
                                                 * Check the recognizedBotMember value to see if it's an iteration from the Lex Chat v2.
                                                 *
                                                 * If it's not an iteration from the Chatbot, check if there is a message stored inside
                                                 * the object message.message.
                                                 */
                                            } else if (
                                                ENABLE_CONVERSATIONAL_AI !== true &&
                                                entry.message.recognizedBotMember
                                            ) {
                                                /**
                                                 * If the response did not came from Question and Answer bot, we will have to parse
                                                 * the object 'messages' that has the type ChatbotResponseMessage.
                                                 * You can check the types file for more information:
                                                 * gatsby/src/components/chatbot/chatbot-v2.types.d.ts
                                                 *
                                                 * If the response came from the Question and Answer bot, we will receive JSON as
                                                 * string to be parsed into the message and buttons list.
                                                 */
                                                if (entry.message.recognizedBotMember.botName.indexOf('QnABot') < 0) {
                                                    messageObject = getChatbotParsedMessages(entry.message.messages);
                                                } else {
                                                    messageObject = getChatbotParsedAppContext(
                                                        entry.message.sessionState.sessionAttributes?.appContext
                                                    );
                                                }
                                            } else {
                                                if (entry.message.message) {
                                                    messageObject = {
                                                        textMessage: entry.message.message,
                                                        buttons: []
                                                    };
                                                }
                                            }

                                            /**
                                             * If we didn't receive an iteration from any bot and did not receive any
                                             * other message, instead do delivery an empty message for the user, we fake
                                             * a response and add a button to be possible to go back to the options menu.
                                             */
                                            if (messageObject.textMessage === '' && messageObject.buttons.length < 1) {
                                                let buttonsToShow = [
                                                    {
                                                        text: 'Main menu',
                                                        value: 'Help'
                                                    }
                                                ];

                                                // Ticket: DRX-2629
                                                // Remove buttons for Conversational AI
                                                if (ENABLE_CONVERSATIONAL_AI) {
                                                    buttonsToShow = [];
                                                }

                                                messageObject = {
                                                    textMessage: defaultUnrecognizedMessage,
                                                    buttons: buttonsToShow
                                                };
                                            }

                                            if (
                                                messageObject.textMessage &&
                                                messageObject.textMessage.indexOf('Sign in') > 0
                                            ) {
                                                const links = messageObject.textMessage.split('1) Sign in -');
                                                messageObject.textMessage = links[0];
                                                contactLink = <a href={links[1]}>{links[1]}</a>;
                                            }

                                            return (
                                                <MessageGroup
                                                    key={`messagegroup_${index}`}
                                                    direction="incoming"
                                                    sentTime="just now"
                                                    avatarPosition="tl"
                                                >
                                                    <Avatar>
                                                        <BirdiLogo />
                                                    </Avatar>
                                                    <MessageGroup.Messages>
                                                        <Message
                                                            model={{
                                                                type: 'html',
                                                                direction: 'incoming'
                                                            }}
                                                        >
                                                            <Message.CustomContent>
                                                                <Message.HtmlContent html={messageObject.textMessage} />
                                                                {contactLink}
                                                                {index + 1 === messages.length &&
                                                                    messageObject.textMessage &&
                                                                    messageObject.textMessage.indexOf('feedback') < 0 &&
                                                                    index !== 0 &&
                                                                    ENABLE_CHATBOT_FEEDBACK && (
                                                                        <ChatbotFeedback
                                                                            onThumbsUp={() =>
                                                                                submitMessage('Thumbs up', false)
                                                                            }
                                                                            onThumbsDown={() =>
                                                                                submitMessage('Thumbs down', false)
                                                                            }
                                                                        />
                                                                    )}
                                                            </Message.CustomContent>
                                                        </Message>
                                                        {messageObject.buttons &&
                                                            messageObject.buttons.map((element, j) => {
                                                                return (
                                                                    <ReactButton
                                                                        key={j}
                                                                        className="submenu p-2 mt-2 text-uppercase"
                                                                        onClick={() =>
                                                                            submitMessage(element.value, false)
                                                                        }
                                                                        disabled={index + 1 !== messages.length}
                                                                    >
                                                                        {element.text}
                                                                    </ReactButton>
                                                                );
                                                            })}
                                                    </MessageGroup.Messages>
                                                </MessageGroup>
                                            );
                                        }
                                    })}
                                </MessageList>
                                <MessageInput
                                    attachButton={false}
                                    placeholder={'Type here'}
                                    onSend={handleSend}
                                    disabled={isSubmitting}
                                    ref={inputRef}
                                />
                            </ChatContainer>
                        </MainContainer>
                    </div>
                </div>
            )}
        </>
    );
};

export default Chatbot;
