import { useTranslation } from 'gatsby-plugin-react-i18next';
import { chunk, difference } from 'lodash';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import Button from 'ui-kit/button/button';
import { Checkbox } from 'ui-kit/checkbox';
import Text from 'ui-kit/text/text';

import {
    HealthConditionsProps,
    HealthConditonCheckboxChangedEvent
} from 'components/health-conditions/health-conditions.props';

import './health-conditions.style.scss';

const HealthConditionPill = ({ text }: { text: string }) => {
    const revisedText = text.toLowerCase() === 'copd' ? 'COPD' : text;
    return <div className="health-condition-pill">{revisedText}</div>;
};

export const HealthConditionPills = ({ conditions }: { conditions: string[] }): ReactElement => {
    return (
        <div className="health-condition-pills">
            {conditions &&
                conditions.map((condition) => (
                    <HealthConditionPill key={`health-condition-pill-${condition}`} text={condition} />
                ))}
        </div>
    );
};

const HealthConditions = ({
    conditionChoices,
    conditionType,
    existingConditions,
    freeformExistingConditions,
    onSubmit,
    freeformConditionsLabel,
    submitLabel,
    formName,
    isInModal,
    dataGaLocation
}: HealthConditionsProps) => {
    const { t } = useTranslation();

    const [hasEdits, setHasEdits] = useState<boolean | undefined>(undefined);
    const [newConditions, setNewConditions] = useState<string[]>([]);
    const [originalConditions, setOriginalConditions] = useState<string[]>([]);

    const [newFreeformConditions, setNewFreeformConditions] = useState<string>('');
    const [originalFreeFormConditions, setOriginalFreeformConditions] = useState<string>('');

    const conditionChunks = useMemo(() => chunk(conditionChoices, 3), [conditionChoices]);
    const isNone = (condition: string) => condition === 'None' || condition === 'none';

    const getCheckboxDefaultValue = ({ condition }: { condition: string }): boolean => {
        if (isNone(condition)) {
            return originalConditions.includes(condition) || newConditions.includes(condition);
        }
        return newConditions.includes(condition);
    };

    const getCheckboxDisabled = ({ condition }: { condition: string }): boolean => {
        if (isNone(condition) && newConditions.some((element) => element !== 'None' && element !== 'none')) {
            return true;
        } else {
            return originalConditions.find((con: string) => con === condition) ? true : false;
        }
    };

    const handleSubmit = () => {
        if (onSubmit)
            onSubmit({
                conditions: [...newConditions, ...existingConditions],
                freeformConditions: newFreeformConditions
            });
    };

    const handleCheckChanged = ({ condition, checked }: HealthConditonCheckboxChangedEvent) => {
        const nextNewConditions: string[] = [...newConditions.filter((con) => con !== condition)];

        if (checked) {
            setNewConditions([...nextNewConditions, condition]);
        } else {
            setNewConditions(nextNewConditions);
        }
    };

    const handleFreeformConditionsChange = ({ target: { value } }: React.ChangeEvent<any>) => {
        setNewFreeformConditions(value);
        canSubmit();
    };

    const canSubmit = () => {
        if (
            newConditions.length !== originalConditions.length ||
            difference(newConditions, originalConditions).length !== 0 ||
            newFreeformConditions !== originalFreeFormConditions
        ) {
            if (newConditions.length > 0 || (newFreeformConditions && newFreeformConditions.trim().length > 0)) {
                setHasEdits(true);
            } else {
                setHasEdits(false);
            }
        } else {
            setHasEdits(false);
        }
    };

    useEffect(() => {
        canSubmit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newConditions, newFreeformConditions]);

    useEffect(() => {
        if (
            newConditions.find((con: string) => con !== 'None') ||
            (newFreeformConditions && newFreeformConditions.length > 0)
        ) {
            if (newConditions.find((con: string) => con === 'None')) {
                setNewConditions(newConditions.filter((con: string) => con !== 'None'));
            }
        } else if (
            newConditions.find((con: string) => con !== 'none') ||
            (newFreeformConditions && newFreeformConditions.length > 0)
        ) {
            if (newConditions.find((con: string) => con === 'none')) {
                setNewConditions(newConditions.filter((con: string) => con !== 'none'));
            }
        }
    }, [hasEdits, newConditions, newFreeformConditions]);

    useEffect(() => {
        setNewFreeformConditions(freeformExistingConditions);
    }, [freeformExistingConditions]);

    useEffect(() => {
        if (freeformExistingConditions && freeformExistingConditions.length > 0) {
            //  dealing with the responses newline
            const formattedFreeformExistingConditions = freeformExistingConditions
                .split('\n')
                .reduce((acc, value, i, array) => acc + (i < array.length - 1 ? ', ' : '') + value);

            setNewFreeformConditions(formattedFreeformExistingConditions);
            setOriginalFreeformConditions(formattedFreeformExistingConditions);
        }
    }, [freeformExistingConditions]);

    useEffect(() => {
        if (existingConditions) {
            setNewConditions(existingConditions);
            setOriginalConditions(existingConditions);
        }
    }, [existingConditions]);

    const gaLocation = dataGaLocation ? dataGaLocation : !dataGaLocation && isInModal ? 'modal' : undefined;

    const label = (condition: string) => {
        if (condition.toLowerCase() === 'none') {
            return conditionType === 'allergy'
                ? t('components.healthConditions.labels.noAllergies')
                : t('components.healthConditions.labels.noHealthConditions');
        }

        if (condition.toLowerCase() === 'copd') {
            return 'COPD';
        }

        return condition;
    };

    return (
        <div data-ga-form-name={formName}>
            <Row>
                <Col>
                    <div className="condition-choices">
                        {conditionChunks &&
                            conditionChunks.map((conditions, rowIndex) => (
                                <Row key={`health-profile-condition-choices-row-${rowIndex}`}>
                                    {conditions &&
                                        conditions.map((condition: string, colIndex: number) => (
                                            <Col
                                                key={`health-profile-condition-choices-row-${rowIndex}-col-${colIndex}`}
                                                xs={12}
                                                lg={4}
                                            >
                                                <Checkbox
                                                    key={`health-profile-row-${rowIndex}-col-${colIndex}-checkbox-condition-${condition}`}
                                                    className="mb-4 chk-large"
                                                    label={label(condition)}
                                                    onCheckChanged={(isChecked: boolean) =>
                                                        handleCheckChanged({ condition, checked: isChecked })
                                                    }
                                                    defaultValue={getCheckboxDefaultValue({ condition })}
                                                    disabled={getCheckboxDisabled({ condition })}
                                                    id={`health-profile-type-${conditionType}-condition-${condition}`}
                                                    onClick={(isChecked: boolean) =>
                                                        handleCheckChanged({ condition, checked: isChecked })
                                                    }
                                                />
                                            </Col>
                                        ))}
                                </Row>
                            ))}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="mt-3">
                    <Text
                        label={freeformConditionsLabel ?? t('components.healthConditions.labels.freeformConditions')}
                        onChange={handleFreeformConditionsChange}
                        value={newFreeformConditions}
                        defaultValue={newFreeformConditions}
                        maxLength={255}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    <Button
                        className="d-block mx-auto mx-md-0 sm-full"
                        label={submitLabel}
                        onClick={handleSubmit}
                        type="button"
                        disabled={!hasEdits}
                        dataGAFormName={formName}
                        dataGALocation={gaLocation}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default HealthConditions;
