export const camelToPascalKeys = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map(camelToPascalKeys);
    } else if (obj !== null && typeof obj === 'object') {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            const pascalKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[pascalKey] = camelToPascalKeys(value);
            return acc;
        }, {} as Record<string, any>);
    }
    return obj;
};

export const pascalToCamelKeys = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map(pascalToCamelKeys);
    } else if (obj !== null && typeof obj === 'object') {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            const camelKey = key.charAt(0).toLowerCase() + key.slice(1);
            acc[camelKey] = pascalToCamelKeys(value);
            return acc;
        }, {} as Record<string, any>);
    }
    return obj;
};

export const transformError = (response: ApiResponse): CustomError => {
    return {
        message: response.messageErrorText || 'An error occurred.',
        code: response.messageStatus ? 'SUCCESS' : 'ERROR',
        data: {
            message: response.messageText,
            details: response.messageErrorText
        }
    };
};

export interface ApiResponse {
    messageErrorText?: string;
    messageStatus: boolean;
    messageText?: string;
}

export interface CustomError {
    message: string;
    code: string;
    data?: any;
}
