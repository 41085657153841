import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import ChevronThinIcon from 'ui-kit/icons/chevron-thin-icon.tsx/chevron-thin-icon';

import { accountProfilEPostPatientNumSelector, accountProfileSelector } from 'state/account/account.selectors';
import { Dependent } from 'state/family-profile/family-profile.reducers';

import { lowercaseAndCapitalize } from 'util/cart';

import './family-tabs.style.scss';

type Dependents = {
    ePostPatientNum: string;
    familyMemberFirstName: string;
};

type FamilyTabsProps = {
    activeTab: string | undefined;
    onTabItemChange: (dependent: string) => void;
    dependents: Dependent[];
    showEveryone?: boolean;
};

const FamilyTabs: React.FC<FamilyTabsProps> = ({ activeTab, onTabItemChange, dependents, showEveryone = true }) => {
    const caregiverEpostPatientNum = useSelector(accountProfilEPostPatientNumSelector);
    const profileObject = useSelector(accountProfileSelector);
    const listRef = useRef<HTMLUListElement>(null);
    const [showRightButton, setShowRightButton] = useState(false);
    const [showLeftButton, setShowLeftButton] = useState(false);

    if (!dependents) return;

    const caregiverMember: Dependents = {
        familyMemberFirstName: `${profileObject?.patientFirstName} (you)`,
        ePostPatientNum: caregiverEpostPatientNum || ''
    };

    const updatedDependents = [caregiverMember, ...dependents];

    const prevAction = () => {
        listRef.current?.scrollBy({
            behavior: 'smooth',
            left: -Math.floor((listRef.current.clientWidth * 2) / 3)
        });
    };
    const nextAction = () => {
        listRef.current?.scrollBy({
            behavior: 'smooth',
            left: Math.floor((listRef.current.clientWidth * 2) / 3)
        });
    };

    return (
        <div className="family-tabs">
            <ul
                ref={(ref) => {
                    if (ref) {
                        // @ts-expect-error TS thinks this is read-only. It is not.
                        listRef.current = ref;
                        setShowLeftButton(ref.scrollLeft > 0);
                        setShowRightButton(ref.scrollLeft < ref.scrollWidth - ref.clientWidth);
                    }
                }}
                className={classNames('tab-list d-flex')}
                role="tablist"
                onScroll={(event) => {
                    setShowLeftButton(event.currentTarget.scrollLeft > 0);
                    setShowRightButton(
                        event.currentTarget.scrollLeft <
                            event.currentTarget.scrollWidth - event.currentTarget.clientWidth
                    );
                }}
            >
                {showLeftButton && (
                    <li
                        key="tab-item-prev"
                        className="tab-item chevron-tab-icon tab-arrow-prev"
                        role="tab"
                        tabIndex={0}
                        onClick={prevAction}
                        onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                                prevAction();
                            }
                        }}
                    >
                        <ChevronThinIcon direction={'left'} />
                    </li>
                )}
                {showEveryone && (
                    <li
                        key={`tab-item-everyone`}
                        className={classNames('tab-item', activeTab ? 'inactive' : 'active')}
                        role="tab"
                        onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                                onTabItemChange('');
                            }
                        }}
                        onClick={() => onTabItemChange('')}
                    >
                        Everyone
                    </li>
                )}
                {updatedDependents.map((dependent, index) => (
                    <li
                        key={`tab-item-${index}`}
                        className={classNames(
                            'tab-item',
                            activeTab && activeTab === dependent.ePostPatientNum ? 'active' : 'inactive'
                        )}
                        role="tab"
                        tabIndex={0}
                        onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                                onTabItemChange(dependent.ePostPatientNum);
                            }
                        }}
                        onClick={() => onTabItemChange(dependent.ePostPatientNum)}
                    >
                        {lowercaseAndCapitalize(dependent.familyMemberFirstName)}
                    </li>
                ))}
                {showRightButton && (
                    <li
                        key="tab-item-next"
                        className="tab-item chevron-tab-icon tab-arrow-next"
                        role="tab"
                        tabIndex={0}
                        onClick={nextAction}
                        onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                                nextAction();
                            }
                        }}
                    >
                        <ChevronThinIcon direction={'right'} />
                    </li>
                )}
            </ul>
        </div>
    );
};

export default FamilyTabs;
