import { TFunction } from 'gatsby-plugin-react-i18next';

import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';
import { DiscountLookupValues, DrugDiscountPricePayload, DrugLookupObjectPayload } from 'state/drug/drug.services';

export function formatDrugName(t: TFunction<'translation'>, drug: DrugLookupObjectPayload): string {
    if (drug.brandDrugName && drug.brandDrugName !== drug.onSaleDrugName && drug.brandNameCode === 'G') {
        return t('prescriptionInfoForm.drugLookup.hasGeneric', {
            drugName: drug.onSaleDrugName,
            brandName: drug.brandDrugName
        });
    } else {
        return drug.onSaleDrugName ? drug.onSaleDrugName : drug.drugName;
    }
}

// Function that returns a drug found in the history
export const findDrugInHistory = (
    drug: DiscountLookupValues,
    drugHistory: DrugWithDiscountPrice[]
): DrugWithDiscountPrice | undefined => {
    return drugHistory.find(
        (item: DrugWithDiscountPrice) =>
            drug.drugCode === item.drugCode && drug.clientAcCode === item.planAlias && drug.zipCode === item.zipCode
    );
};

// Function that formats discount price payload
export const processDiscountPrice = (payload: DrugDiscountPricePayload): DrugWithDiscountPrice => {
    // Initialize values
    let priceValue = 'NA';
    let awpPriceValue = 'NA';

    // Ensure you get a valid price
    if (payload.response?.prescriptionResult[0].status !== 'DENIED') {
        // Get Price value
        priceValue = payload.response?.prescriptionResult[0]?.pricingInformation?.memberCost?.patientPayAmount
            ? payload.response.prescriptionResult[0].pricingInformation.memberCost.patientPayAmount
            : priceValue; // '8.25';

        // Get awp price
        awpPriceValue = payload.response?.prescriptionResult[0]?.pricingInformation?.awpPrice
            ? payload.response.prescriptionResult[0].pricingInformation.awpPrice
            : awpPriceValue; // '400.00';
    }

    return {
        price: priceValue,
        awpPrice: awpPriceValue,
        rxNumber: payload.rxNumber as string,
        planAlias: payload.response.member.memberIdent.clientAccountCode,
        isBirdiSelect: payload.response.isBirdiSelect,
        zipCode: payload.zipCode,
        drugCode: payload.drugCode
    };
};

// Check if the price exists for the given prescription number.
export const findPriceMatch = (
    drugCode: string,
    planAlias: string,
    drugDiscountPrices: DrugWithDiscountPrice[],
    zipcode?: string | null
): string => {
    // Check if the price exists for the given prescription number.
    const priceMatches = drugDiscountPrices.find((item) => {
        return zipcode
            ? item.drugCode === drugCode && item.planAlias === planAlias && item.zipCode === zipcode
            : item.drugCode === drugCode && item.planAlias === planAlias;
    });

    return priceMatches ? priceMatches.price : '';
};
