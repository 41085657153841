import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiStatus } from 'enums/api-status';
import produce from 'immer';

import { Allergies, HealthConditionsByPatientNumber, MedicalConditions } from 'types/medical-conditions';

import {
    medicalConditionsAddOrUpdateAllRoutine,
    medicalConditionsAddOrUpdateRoutine,
    medicalConditionsAllergiesAddOrUpdateRoutine,
    medicalConditionsAllergiesDetailsRoutine,
    medicalConditionsDetailsRoutine
} from './medical-conditions.routines';
import { GetMedicalConditionDetailsSaga } from './medical-conditions.sagas';

export type MedicalConditionsResponse = MedicalConditions;
export type AllergiesResponse = Allergies;

export type MedicalConditionsState = {
    healthConditions: {
        medicalConditions: MedicalConditionsResponse;
        allergies: AllergiesResponse;
    };
    healthConditionsByPatientNumber: HealthConditionsByPatientNumber;
    medicalConditions?: MedicalConditionsResponse;
    allergies?: AllergiesResponse;
    isLoadingHealthConditions: boolean;
    isLoadingAllergies: boolean;
    isLoadingMedicalConditions?: boolean;
    loadedHealthConditions: boolean;
    loadedAllergies: boolean;
};

const initialState: MedicalConditionsState = {
    isLoadingHealthConditions: false,
    isLoadingAllergies: false,
    healthConditionsByPatientNumber: {},
    healthConditions: {
        medicalConditions: {
            Asthma: false,
            COPD: false,
            Cholesterol: false,
            Diabetes: false,
            Glaucoma: false,
            Heart: false,
            Hypertension: false,
            Kidney: false,
            Stroke: false,
            Thyroid: false,
            None: false,
            Other: '',
            TemporaryOther: '',
            ePostPatientNum: ''
        },
        allergies: {
            None: false,
            Tetracyclines: false,
            Penicillin: false,
            Cephalosporins: false,
            Ampicillin: false,
            Sulfa: false,
            Erythromycin: false,
            Aspirin: false,
            Codeine: false,
            NSAIDs: false,
            Quinolones: false,
            Other: '',
            TemporaryOther: '',
            ePostPatientNum: ''
        }
    },
    loadedHealthConditions: false,
    loadedAllergies: false
};

const medicalConditionsSlice = createSlice({
    name: 'medicalConditions',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(
            medicalConditionsDetailsRoutine.TRIGGER,
            (state, { payload }: PayloadAction<GetMedicalConditionDetailsSaga>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingHealthConditions = true;

                    draftState.healthConditionsByPatientNumber = {
                        ...state.healthConditionsByPatientNumber,
                        [payload.epostPatientNumFamily]: {
                            ...(state.healthConditionsByPatientNumber[payload.epostPatientNumFamily] || {}),
                            medicalConditions: undefined,
                            medicalConditionsApiStatus: ApiStatus.LOADING
                        }
                    };
                })
        );
        addCase(
            medicalConditionsDetailsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MedicalConditionsResponse>) =>
                produce(state, (draftState) => {
                    draftState.healthConditions.medicalConditions = payload;

                    draftState.healthConditionsByPatientNumber = {
                        ...state.healthConditionsByPatientNumber,
                        [payload.ePostPatientNum]: {
                            ...(state.healthConditionsByPatientNumber[payload.ePostPatientNum] || {}),
                            medicalConditions: payload,
                            medicalConditionsApiStatus: ApiStatus.SUCCESS
                        }
                    };

                    draftState.isLoadingHealthConditions = false;
                    draftState.loadedHealthConditions = true;
                })
        );
        addCase(
            medicalConditionsDetailsRoutine.FAILURE,
            (state, { payload }: PayloadAction<MedicalConditionsResponse>) =>
                produce(state, (draftState) => {
                    draftState.medicalConditions = undefined;
                    draftState.isLoadingHealthConditions = false;
                    draftState.healthConditionsByPatientNumber = {
                        ...state.healthConditionsByPatientNumber,
                        [payload.ePostPatientNum]: {
                            ...(state.healthConditionsByPatientNumber[payload.ePostPatientNum] || {}),
                            medicalConditions: undefined,
                            medicalConditionsApiStatus: ApiStatus.ERROR
                        }
                    };
                })
        );
        addCase(medicalConditionsAddOrUpdateRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.medicalConditions = undefined;
                draftState.isLoadingHealthConditions = true;
            })
        );
        addCase(medicalConditionsAddOrUpdateRoutine.SUCCESS, (state, { payload }: PayloadAction<MedicalConditions>) =>
            produce(state, (draftState) => {
                draftState.healthConditions.medicalConditions = payload;
                draftState.healthConditionsByPatientNumber = {
                    ...state.healthConditionsByPatientNumber,
                    [payload.ePostPatientNum]: {
                        ...(state.healthConditionsByPatientNumber[payload.ePostPatientNum] || {}),
                        medicalConditions: payload
                    }
                };
                draftState.isLoadingHealthConditions = false;
            })
        );
        addCase(medicalConditionsAddOrUpdateRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.medicalConditions = undefined;
                draftState.isLoadingHealthConditions = false;
            })
        );
        addCase(
            medicalConditionsAllergiesDetailsRoutine.TRIGGER,
            (state, { payload }: PayloadAction<GetMedicalConditionDetailsSaga>) =>
                produce(state, (draftState) => {
                    draftState.allergies = undefined;
                    draftState.isLoadingAllergies = true;

                    draftState.healthConditionsByPatientNumber = {
                        ...state.healthConditionsByPatientNumber,
                        [payload.epostPatientNumFamily]: {
                            ...(state.healthConditionsByPatientNumber[payload.epostPatientNumFamily] || {}),
                            allergies: undefined,
                            allergiesApiStatus: ApiStatus.LOADING
                        }
                    };
                })
        );
        addCase(
            medicalConditionsAllergiesDetailsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AllergiesResponse>) =>
                produce(state, (draftState) => {
                    draftState.healthConditions.allergies = payload;
                    draftState.isLoadingAllergies = false;

                    draftState.healthConditionsByPatientNumber = {
                        ...state.healthConditionsByPatientNumber,
                        [payload.ePostPatientNum]: {
                            ...(state.healthConditionsByPatientNumber[payload.ePostPatientNum] || {}),
                            allergies: payload,
                            allergiesApiStatus: ApiStatus.SUCCESS
                        }
                    };
                })
        );
        addCase(
            medicalConditionsAllergiesDetailsRoutine.FAILURE,
            (state, { payload }: PayloadAction<AllergiesResponse>) =>
                produce(state, (draftState) => {
                    draftState.allergies = undefined;
                    draftState.isLoadingAllergies = false;

                    draftState.healthConditionsByPatientNumber = {
                        ...state.healthConditionsByPatientNumber,
                        [payload.ePostPatientNum]: {
                            ...(state.healthConditionsByPatientNumber[payload.ePostPatientNum] || {}),
                            allergies: undefined,
                            allergiesApiStatus: ApiStatus.ERROR
                        }
                    };
                })
        );
        addCase(medicalConditionsAllergiesAddOrUpdateRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.allergies = undefined;
                draftState.isLoadingAllergies = true;
            })
        );
        addCase(medicalConditionsAllergiesAddOrUpdateRoutine.SUCCESS, (state, { payload }: PayloadAction<Allergies>) =>
            produce(state, (draftState) => {
                draftState.healthConditions.allergies = payload;
                draftState.healthConditionsByPatientNumber = {
                    ...state.healthConditionsByPatientNumber,
                    [payload.ePostPatientNum]: {
                        ...(state.healthConditionsByPatientNumber[payload.ePostPatientNum] || {}),
                        allergies: payload
                    }
                };
                draftState.isLoadingAllergies = false;
            })
        );
        addCase(medicalConditionsAllergiesAddOrUpdateRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.allergies = undefined;
                draftState.isLoadingAllergies = false;
            })
        );

        addCase(medicalConditionsAddOrUpdateAllRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingMedicalConditions = true;
            })
        );
        addCase(medicalConditionsAddOrUpdateAllRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingMedicalConditions = false;
            })
        );
        addCase(medicalConditionsAddOrUpdateAllRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingMedicalConditions = false;
            })
        );
    }
});

export const medicalConditionsActions = medicalConditionsSlice.actions;
export default medicalConditionsSlice.reducer;
