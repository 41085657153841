import { PayloadAction } from '@reduxjs/toolkit';
import { ApiStatus } from 'enums/api-status';
import { RX_STATUS_PAYLOAD, RX_WEB_ELIGIBILITY_STATUS } from 'enums/prescription';
import _ from 'lodash';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import {
    accountCreditCardsSelector,
    accountIsLoadingPaymentMethodsSelector,
    accountIsLoggedInSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { ProfileObjectPayload } from 'state/account/account.services';
import { getCartRoutine } from 'state/cart/cart.routines';
import { cartApiStatusSelector } from 'state/cart/cart.selectors';
import {
    medicineCabinetGetAllPrescriptions,
    medicineCabinetGetStatusForRx,
    medicineCabinetLoadRoutine,
    medicineCabinetToggleAutoRefillAllRxs,
    medicineCabinetToggleAutoRefillForRx
} from 'state/medicine-cabinet/medicine-cabinet.routines';
import { medicineCabinetCachedSubStatusesSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';
import MedicineCabinetService, {
    RxStatusResponse,
    ToggleAutoFillRequest,
    ToggleAutoFillResponse
} from 'state/medicine-cabinet/medicine-cabinet.services';

import { CreditCardPayload } from 'types/card';
import { RxDetails } from 'types/prescription';

import { UnknownFunction } from 'util/function';
import { getRxDisplayStatus, getRxStatus } from 'util/payload-to-props';
import { isRxOnHold, isRxOrderedInProgress } from 'util/prescription';
import { baseEffectHandler } from 'util/sagas/sagas';

import { showNewPrescriptionModal } from './medicine-cabinet.reducers';
import { medicineCabinetPrescriptionsSelector } from './medicine-cabinet.selectors';

export default function* medicineCabinetSaga() {
    yield takeLatest(
        medicineCabinetGetAllPrescriptions.TRIGGER,
        function* (
            action: PayloadAction<{
                showNewRxModal: boolean;
                epostNumFamilyMember?: string;
                fetchRxSubStatus?: boolean;
                onSuccess?: UnknownFunction;
                onFailure?: UnknownFunction;
            }>
        ) {
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const profileObject: ProfileObjectPayload | undefined = yield select(accountProfileSelector);

            const { showNewRxModal, epostNumFamilyMember, fetchRxSubStatus } = action.payload;

            yield baseEffectHandler({
                service: MedicineCabinetService.allRxs().get,
                data: epostNumFamilyMember,
                isAuthenticatedService: true,
                isLoggedIn,
                *onResponse(data: RxDetails[]) {
                    try {
                        if (!data || !data.length) {
                            yield put(medicineCabinetGetAllPrescriptions.success([]));
                        }

                        const prescriptions: RxDetails[] = (data ?? []).map((prescription: RxDetails) => ({
                            ...prescription,
                            // realRxCardStatus contains the "real" status, ignoring if the
                            // prescription is in the cart.
                            realRxCardStatus: getRxStatus(prescription),
                            // rxCardStatus contains the status to display in
                            // the rx card, includes in cart status.
                            rxCardStatus: getRxDisplayStatus(prescription)
                        }));

                        //Diff check for rxs that are stored for modal vs sent from endpoint

                        //Extracts the rx numbers from the api
                        const newRxs: string[] = data
                            .filter(
                                (prescription: RxDetails) =>
                                    prescription.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.ELIGIBLE &&
                                    prescription.rxStatus === RX_STATUS_PAYLOAD.PROFILED
                            )
                            .map((prescription: RxDetails) => prescription.rxNumber);

                        if (newRxs.length > 0) {
                            //Get our cached storage for pass modals displayed. Contains rx numbers
                            const displayedRxsString = localStorage.getItem('newRxsDisplayed');
                            //If we have have data in local storage, then we need to apply some logic
                            if (displayedRxsString !== null) {
                                //Gets the chached modal data from localStorage
                                const displayRxData: { prescriptions: string[]; time: string } =
                                    JSON.parse(displayedRxsString);

                                //Use lodash difference to determine if the api sent over new Prescriptions from the ones we have cached
                                const diffRxs = _.difference(newRxs, displayRxData.prescriptions);
                                //Used to test if the date cache is two days old, so we can display a new modal reminding the user.
                                const storedDate = new Date(displayRxData.time);
                                storedDate.setDate(storedDate.getDate() + 2);
                                //If we are visting back to the page two days later and we have do not have new rx numbers from the api
                                //we should display a modal to the user.
                                if (storedDate.valueOf() < Date.now() && diffRxs.length <= 0) {
                                    const prescriptionNames = newRxs
                                        .map((rx) => data.find((pre) => pre.rxNumber === rx))
                                        .map((pre) => (pre ? pre.dispensedProductName : ''));
                                    if (
                                        prescriptionNames.length > 0 &&
                                        _.join(prescriptionNames, '').trim().length > 0
                                    ) {
                                        yield put(
                                            showNewPrescriptionModal({
                                                isRxLoaded: true,
                                                show: showNewRxModal,
                                                prescriptions: prescriptionNames
                                            })
                                        );
                                    }
                                    localStorage.setItem(
                                        'newRxsDisplayed',
                                        JSON.stringify({
                                            time: Date.now(),
                                            prescriptions: newRxs
                                        })
                                    );
                                    //If we do have some new rxs from the api, we should show that to the user, and update our cache state.
                                } else if (diffRxs.length > 0) {
                                    //Update out local state with new diff rxs and show the new ones to the user.
                                    localStorage.setItem(
                                        'newRxsDisplayed',
                                        JSON.stringify({
                                            ...displayRxData,
                                            prescriptions: [...displayRxData.prescriptions, ...diffRxs]
                                        })
                                    );

                                    const prescriptionNames = diffRxs
                                        .map((rx) => data.find((pre) => pre.rxNumber === rx))
                                        .map((pre) => (pre ? pre.dispensedProductName : ''));
                                    if (
                                        prescriptionNames.length > 0 &&
                                        _.join(prescriptionNames, '').trim().length > 0
                                    ) {
                                        yield put(
                                            showNewPrescriptionModal({
                                                isRxLoaded: true,
                                                show: showNewRxModal,
                                                prescriptions: prescriptionNames
                                            })
                                        );
                                    }
                                }
                            } else {
                                // If we have a fresh state of rxs, then we just display them to the user and store cache info
                                localStorage.setItem(
                                    'newRxsDisplayed',
                                    JSON.stringify({
                                        time: Date.now(),
                                        prescriptions: newRxs
                                    })
                                );
                                const prescriptionNames = newRxs
                                    .map((rx) => data.find((pre) => pre.rxNumber === rx))
                                    .map((pre) => (pre ? pre.dispensedProductName : ''));
                                if (prescriptionNames.length > 0 && _.join(prescriptionNames, '').trim().length > 0) {
                                    yield put(
                                        showNewPrescriptionModal({
                                            isRxLoaded: true,
                                            show: showNewRxModal,
                                            prescriptions: prescriptionNames
                                        })
                                    );
                                }
                            }
                        }

                        yield put(medicineCabinetGetAllPrescriptions.success(prescriptions));

                        if (fetchRxSubStatus && profileObject) {
                            const filteredRxNumbers = prescriptions
                                .filter((rx) => !isRxOnHold(rx) && isRxOrderedInProgress(rx))
                                .map((rx) => rx.rxNumber);

                            for (const rxNumber of filteredRxNumbers) {
                                yield put(
                                    medicineCabinetGetStatusForRx.trigger({
                                        rxNumber,
                                        epostNumFamilyMember
                                    })
                                );
                            }
                        }

                        const { onSuccess } = action.payload;
                        if (onSuccess) onSuccess();
                    } catch (error) {
                        yield put(medicineCabinetGetAllPrescriptions.failure(data));
                        const { onFailure } = action.payload;
                        if (onFailure) onFailure();
                    }
                },
                *onError(data) {
                    yield put(medicineCabinetGetAllPrescriptions.failure(data));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            });
        }
    );

    function* toggleAutoRefillForRxSaga(
        action: PayloadAction<{
            rxNumber: string;
            rxSeqNum: string;
            autoRefillEnabled: boolean;
            onSuccess?: () => void;
            onFailure?: () => void;
            isRenew?: boolean;
        }>
    ) {
        try {
            const { rxNumber, autoRefillEnabled, isRenew, onSuccess, onFailure } = action.payload;
            const currentPrescriptions: RxDetails[] = yield select(medicineCabinetPrescriptionsSelector);

            const data: ToggleAutoFillRequest = {
                RxNumber: rxNumber,
                AutoFillToggle: autoRefillEnabled
            };

            yield baseEffectHandler({
                service: MedicineCabinetService.toggleAutofillForRx().post,
                data,
                *onResponse(data: ToggleAutoFillResponse) {
                    if (!data.messageErrorText) {
                        // success
                        const currentRx = currentPrescriptions.find((rx) => rx.rxNumber === rxNumber);
                        const updatedRx = {
                            ...currentRx,
                            autoRefillEnabled: autoRefillEnabled,
                            // DRX-2084: if isRenew is true reset consentExpiration in order to remove renew button from UI
                            consentExpiration: isRenew ? '' : currentRx?.consentExpiration
                        };

                        const newRxs = currentPrescriptions.map((rx) => {
                            return rx.rxNumber === updatedRx.rxNumber ? updatedRx : rx;
                        });

                        yield put(medicineCabinetToggleAutoRefillForRx.success(newRxs));
                        if (onSuccess) onSuccess();
                    } else {
                        // error
                        yield put(medicineCabinetToggleAutoRefillForRx.failure(data));
                        if (onFailure) onFailure();
                    }
                    return data;
                },
                *onError(error) {
                    yield put(medicineCabinetToggleAutoRefillForRx.failure(error));
                }
            });
        } catch (error) {
            yield put(medicineCabinetToggleAutoRefillForRx.failure(error));
        }
    }

    yield takeLatest(medicineCabinetToggleAutoRefillForRx.TRIGGER, toggleAutoRefillForRxSaga);

    // This routine is not being used currently but might be used in the future.
    yield takeLatest(
        medicineCabinetToggleAutoRefillAllRxs.TRIGGER,
        function* (
            action: PayloadAction<{
                rxNumbers: ToggleAutoFillRequest[];
                onSuccess?: UnknownFunction;
                onFailure?: UnknownFunction;
                isRenew?: boolean;
            }>
        ) {
            try {
                const { rxNumbers, onSuccess, onFailure } = action.payload;

                yield all(
                    rxNumbers.map((rxAutoFillRequest) => {
                        const payloadForSaga: PayloadAction<{
                            rxNumber: string;
                            rxSeqNum: string;
                            autoRefillEnabled: boolean;
                            onSuccess?: () => void;
                            onFailure?: () => void;
                            isRenew?: boolean;
                        }> = {
                            payload: {
                                rxNumber: rxAutoFillRequest.RxNumber,
                                // @ts-expect-error interface is not correct, but routine is apparently
                                // not being triggered anywhere so I can't verify
                                rxSeqNum: rxAutoFillRequest.RxSeqNum,
                                autoRefillEnabled: rxAutoFillRequest.AutoFillToggle,
                                onSuccess,
                                onFailure,
                                isRenew: rxAutoFillRequest.isRenew
                            },
                            type: ''
                        };

                        const response = call(toggleAutoRefillForRxSaga, payloadForSaga);
                        return response;
                    })
                );
                if (onSuccess) onSuccess();
            } catch (error) {
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        }
    );

    yield takeEvery(
        medicineCabinetGetStatusForRx.TRIGGER,
        function* getSubStatusForRxSaga(
            action: PayloadAction<{
                rxNumber: string;
                epostNumFamilyMember?: string;
                onSuccess?: () => void;
                onFailure?: () => void;
            }>
        ) {
            const { rxNumber, epostNumFamilyMember } = action.payload;
            const cachedSubStatuses: {
                [key: string]: RxStatusResponse;
            } = yield select(medicineCabinetCachedSubStatusesSelector);
            const isCached = Object.keys(cachedSubStatuses).some((key) => key === rxNumber);
            if (isCached) {
                yield put(medicineCabinetGetStatusForRx.success(cachedSubStatuses[rxNumber]));
                return;
            }

            const response: RxStatusResponse = yield call(
                MedicineCabinetService.getStatusForRx().get,
                rxNumber,
                epostNumFamilyMember
            );
            if (response.messageErrorText) {
                yield put(medicineCabinetGetStatusForRx.failure({ ...response, rxNumber }));
            } else {
                yield put(medicineCabinetGetStatusForRx.success({ ...response, rxNumber }));
            }
        }
    );

    yield takeLatest(
        medicineCabinetLoadRoutine.TRIGGER,
        function* firstLoadMedicineCabinetSaga(
            action: PayloadAction<{
                selectedTab?: string;
                selectedDependent?: string;
                fetchRxSubStatus?: boolean;
            }>
        ) {
            try {
                const { selectedDependent, fetchRxSubStatus } = action.payload || {};

                if (selectedDependent) {
                    yield put(
                        medicineCabinetGetAllPrescriptions.trigger({
                            showNewRxModal: false,
                            epostNumFamilyMember: selectedDependent,
                            fetchRxSubStatus: fetchRxSubStatus
                        })
                    );

                    yield put(medicineCabinetLoadRoutine.success());
                    return;
                }

                const profileObject: ProfileObjectPayload | undefined = yield select(accountProfileSelector);

                if (!profileObject) return;

                const cartApiStatus: ApiStatus = yield select(cartApiStatusSelector);

                if (cartApiStatus !== ApiStatus.LOADING) {
                    yield put(getCartRoutine.trigger());
                }

                const paymentData: CreditCardPayload[] | undefined = yield select(accountCreditCardsSelector);
                const isLoadingPaymentData: boolean = yield select(accountIsLoadingPaymentMethodsSelector);

                if (!paymentData?.length && !isLoadingPaymentData) {
                    yield put(accountGetAllCreditCardsRoutine.trigger());
                }

                yield put(
                    medicineCabinetGetAllPrescriptions.trigger({
                        showNewRxModal: false,
                        epostNumFamilyMember: selectedDependent || profileObject.epostPatientNum,
                        fetchRxSubStatus: fetchRxSubStatus
                    })
                );

                yield put(medicineCabinetLoadRoutine.success());
            } catch (error) {
                yield put(medicineCabinetLoadRoutine.failure());
            }
        }
    );
}
