import React from 'react';

export const iconPaths = {
    blurb: (
        <path
            d="M 12 22 C 17.5229 22 22 17.5229 22 12 C 22 6.4771 17.5229 2 12 2 C 6.4771 2 2 6.4771 2 12 C 2 17.5229 6.4771 22 12 22 Z M 12 22 C 17.5229 22 22 17.5229 22 12 C 22 6.4771 17.5229 2 12 2 C 6.4771 2 2 6.4771 2 12 C 2 17.5229 6.4771 22 12 22 Z M 12 17 M 12 7 L 12 8 Z M 12 22 C 17.5229 22 22 17.5229 22 12 C 22 6.4771 17.5229 2 12 2 C 6.4771 2 2 6.4771 2 12 C 2 17.5229 6.4771 22 12 22 Z M 12 22 C 17.5229 22 22 17.5229 22 12 C 22 6.4771 17.5229 2 12 2 C 6.4771 2 2 6.4771 2 12 C 2 17.5229 6.4771 22 12 22 Z M 12 17 V 11 M 12 7 L 12 8 Z M 12 22 C 17.5229 22 22 17.5229 22 12 C 22 6.4771 17.5229 2 12 2 C 6.4771 2 2 6.4771 2 12 C 2 17.5229 6.4771 22 12 22 Z M 12 22 C 17.5229 22 22 17.5229 22 12 C 22 6.4771 17.5229 2 12 2 C 6.4771 2 2 6.4771 2 12 C 2 17.5229 6.4771 22 12 22 Z M 12 17 V 11 M 12 7 V 8 Z"
            stroke="#1A497F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    ),
    arrow: (
        <path
            d="M 6 17 L 1 12 M 1 12 L 6 7 M 1 12 L 25 12"
            stroke="#1A497F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    ),
    'small-arrow': (
        <path
            d="M6 11L1 6M1 6L6 1M1 6L15 6"
            stroke="#1A497F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    ),
    'eye-closed': (
        <path
            d="M17.7046 17.7125C16.0245 18.8042 14.0778 19.6153 12.0001 19.6153C7.27855 19.6153 3.23388 15.4268 1.45696 13.2755C1.17637 12.9256 1.02102 12.4711 1.02102 11.9999C1.02102 11.5286 1.17637 11.0741 1.45696 10.7243C2.43647 9.53838 4.10508 7.73352 6.1961 6.35239M9.46162 4.77432C10.278 4.52741 11.1275 4.38441 12.0001 4.38441C16.7216 4.38441 20.7662 8.57289 22.5432 10.7243C22.8238 11.0741 22.9792 11.5286 22.9792 11.9999C22.9792 12.4711 22.8238 12.9256 22.5432 13.2755C21.9802 13.9571 21.1896 14.8432 20.2219 15.7358M9.6067 9.60656C8.28493 10.9283 8.28493 13.0714 9.6067 14.3931C10.9285 15.7149 13.0715 15.7149 14.3933 14.3931M1 1L23 23"
            stroke="#9CAABA"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    ),
    eye: (
        <g>
            <path
                d="M22.5632 10.352C22.8443 10.7025 23 11.1578 23 11.63C23 12.1022 22.8443 12.5575 22.5632 12.908C20.7829 15.0635 16.7305 19.26 12 19.26C7.26947 19.26 3.21715 15.0635 1.43684 12.908C1.15565 12.5575 1 12.1022 1 11.63C1 11.1578 1.15565 10.7025 1.43684 10.352C3.21715 8.1965 7.26947 4 12 4C16.7305 4 20.7829 8.1965 22.5632 10.352Z"
                stroke="#9CAABA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.0001 15.021C13.8729 15.021 15.3911 13.5027 15.3911 11.6299C15.3911 9.75706 13.8729 8.23883 12.0001 8.23883C10.1272 8.23883 8.60899 9.75706 8.60899 11.6299C8.60899 13.5027 10.1272 15.021 12.0001 15.021Z"
                stroke="#9CAABA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    ),
    warning: (
        <path
            d="M12.25 9.97551V14.4776M12.25 18.15L12.25 17.7M13.6199 4.50363C13.4907 4.27672 13.2945 4.0863 13.0528 3.95333C12.8111 3.82036 12.5333 3.75 12.25 3.75C11.9667 3.75 11.6889 3.82036 11.4472 3.95333C11.2055 4.0863 11.0093 4.27672 10.8801 4.50363L2.41465 19.7414C2.29658 19.9522 2.24037 20.1867 2.25135 20.4228C2.26233 20.6587 2.34014 20.8881 2.47739 21.0894C2.61465 21.2907 2.80678 21.457 3.03556 21.5725C3.26433 21.6882 3.52214 21.7493 3.78453 21.75H20.7155C20.9779 21.7493 21.2357 21.6882 21.4644 21.5725C21.6933 21.457 21.8854 21.2907 22.0227 21.0894C22.1598 20.8881 22.2377 20.6587 22.2486 20.4228C22.2597 20.1867 22.2034 19.9522 22.0853 19.7414L13.6199 4.50363Z"
            stroke="#B85849"
            strokeWidth="2"
            strokeLinecap="round"
        />
    ),
    checkmark: (
        <>
            <g clipPath="url(#clip0_3801_28963)">
                <path
                    d="M16.9367 8.19238L10.1675 16.6539L6.78284 14.1155M12 23C18.0752 23 23 18.0752 23 12C23 5.92487 18.0752 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0752 5.92487 23 12 23Z"
                    stroke="#54CDA0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3801_28963">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </>
    ),
    'chevron-down': (
        <path d="M6 10L11.9697 16M18 10L12.0303 16" stroke="#9CAABA" strokeWidth="2" strokeLinecap="round" />
    ),
    'chevron-up': <path d="M6 10L11.9697 16M18 10L12.0303 16" stroke="#9CAABA" strokeWidth="2" strokeLinecap="round" />,
    calendar: (
        <path
            d="M5 12C4.44772 12 4 12.4477 4 13C4 13.5523 4.44772 14 5 14V12ZM7 14C7.55228 14 8 13.5523 8 13C8 12.4477 7.55228 12 7 12V14ZM11 12C10.4477 12 10 12.4477 10 13C10 13.5523 10.4477 14 11 14V12ZM13 14C13.5523 14 14 13.5523 14 13C14 12.4477 13.5523 12 13 12V14ZM17 12C16.4477 12 16 12.4477 16 13C16 13.5523 16.4477 14 17 14V12ZM19 14C19.5523 14 20 13.5523 20 13C20 12.4477 19.5523 12 19 12V14ZM5 16C4.44772 16 4 16.4477 4 17C4 17.5523 4.44772 18 5 18V16ZM7 18C7.55228 18 8 17.5523 8 17C8 16.4477 7.55228 16 7 16V18ZM11 16C10.4477 16 10 16.4477 10 17C10 17.5523 10.4477 18 11 18V16ZM13 18C13.5523 18 14 17.5523 14 17C14 16.4477 13.5523 16 13 16V18ZM17 16C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18V16ZM19 18C19.5523 18 20 17.5523 20 17C20 16.4477 19.5523 16 19 16V18ZM3 3H21V1H3V3ZM22 4V20H24V4H22ZM21 21H3V23H21V21ZM2 20V4H0V20H2ZM3 21C2.44772 21 2 20.5523 2 20H0C0 21.6569 1.34314 23 3 23V21ZM22 20C22 20.5523 21.5523 21 21 21V23C22.6569 23 24 21.6569 24 20H22ZM21 3C21.5523 3 22 3.44772 22 4H24C24 2.34315 22.6569 1 21 1V3ZM3 1C1.34315 1 0 2.34315 0 4H2C2 3.44772 2.44772 3 3 3V1ZM2 9H23V7H2V9ZM5 14H7V12H5V14ZM11 14H13V12H11V14ZM17 14H19V12H17V14ZM5 18H7V16H5V18ZM11 18H13V16H11V18ZM17 18H19V16H17V18Z"
            fill="#9CAABA"
        />
    ),
    rxfills: (
        <>
            <path
                d="M16.1263 10.3872C16.9542 9.60468 18.0546 9.17582 19.1937 9.19184C20.3328 9.20785 21.4207 9.66748 22.2263 10.473C23.0318 11.2785 23.4914 12.3665 23.5075 13.5056C23.5235 14.6447 23.0946 15.7451 22.312 16.5729L17.0401 21.8449C16.2169 22.6329 15.1186 23.0687 13.979 23.0594C12.8394 23.0502 11.7484 22.5968 10.938 21.7955C10.1275 20.9943 9.66167 19.9084 9.6395 18.769C9.61734 17.6296 10.0406 16.5265 10.8193 15.6943L16.1263 10.3872Z"
                stroke="#9CAABA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.7383 12.7773L19.9241 18.963"
                stroke="#9CAABA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.7164 6.71012C15.0601 3.42043 12.1564 0.941406 8.67377 0.941406C4.70749 0.941406 1.49219 4.15672 1.49219 8.123C1.49219 11.6097 3.97687 14.516 7.27218 15.1679"
                stroke="#9CAABA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.6133 5.45589L15.718 6.7105L17.0076 3.80273"
                stroke="#9CAABA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.95617 5.30469V8.35895L6.46484 9.56449"
                stroke="#9CAABA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    ),
    'empty-circle': (
        <>
            <mask id="path-1-inside-1_39591_3735" fill="white">
                <path d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5Z" />
            </mask>
            <path
                d="M8 15C4.41015 15 1.5 12.0899 1.5 8.5H-1.5C-1.5 13.7467 2.75329 18 8 18V15ZM14.5 8.5C14.5 12.0899 11.5899 15 8 15V18C13.2467 18 17.5 13.7467 17.5 8.5H14.5ZM8 2C11.5899 2 14.5 4.91015 14.5 8.5H17.5C17.5 3.25329 13.2467 -1 8 -1V2ZM8 -1C2.75329 -1 -1.5 3.25329 -1.5 8.5H1.5C1.5 4.91015 4.41015 2 8 2V-1Z"
                fill="#1A497F"
                mask="url(#path-1-inside-1_39591_3735)"
            />
        </>
    ),
    'small-alert': (
        <>
            <path
                d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5V0.5C12.4183 0.5 16 4.08172 16 8.5V8.5C16 12.9183 12.4183 16.5 8 16.5V16.5C3.58172 16.5 0 12.9183 0 8.5V8.5Z"
                fill="#B85849"
                style={{ fill: '#B85849', color: 'color(display-p3 0.7200 0.3431 0.2880)', fillOpacity: 1 }}
            />
            <path
                d="M8 4.83334V8.50498M8.00001 11.5L8 11.133"
                stroke="white"
                style={{ stroke: 'white', strokeOpacity: 1 }}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </>
    ),
    'small-success': (
        <>
            <mask
                id="path-1-outside-1_39787_1679"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
                fill="black"
            >
                <rect fill="white" width="20" height="20" />
                <path d="M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z" />
            </mask>
            <path
                d="M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
                fill="#D6F3E8"
            />
            <path
                d="M10 16.5C6.41015 16.5 3.5 13.5899 3.5 10H0.5C0.5 15.2467 4.75329 19.5 10 19.5V16.5ZM16.5 10C16.5 13.5899 13.5899 16.5 10 16.5V19.5C15.2467 19.5 19.5 15.2467 19.5 10H16.5ZM10 3.5C13.5899 3.5 16.5 6.41015 16.5 10H19.5C19.5 4.75329 15.2467 0.5 10 0.5V3.5ZM10 0.5C4.75329 0.5 0.5 4.75329 0.5 10H3.5C3.5 6.41015 6.41015 3.5 10 3.5V0.5Z"
                fill="white"
                mask="url(#path-1-outside-1_39787_1679)"
            />
            <path
                d="M13.3845 7.17944L8.87165 12.8205L6.61523 11.1282"
                stroke="#54CDA0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    ),
    'circle-close': (
        <path
            id="Vector"
            d="M19 5L5 19M5 5L19 19"
            stroke="#1A497F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    ),
    'big-search': (
        <>
            <path
                d="M1.66797 56.0002C1.66797 97.8243 46.9439 123.964 83.1648 103.052C99.975 93.347 110.33 75.4108 110.33 56.0002C110.33 14.176 65.0544 -11.9642 28.8336 8.948C12.0234 18.6533 1.66797 36.5895 1.66797 56.0002Z"
                fill="white"
            />
            <path
                d="M65.3817 97.3502C65.3817 98.6527 57.6395 99.7095 48.0853 99.7095C38.5311 99.7095 30.7891 98.6513 30.7891 97.3502C30.7891 96.049 38.5313 94.9922 48.0855 94.9922C57.6396 94.9922 65.3817 96.0475 65.3817 97.3502Z"
                fill="#F3F8FF"
            />
            <path
                d="M66.4258 67.4489L72.9007 60.9727L80.4528 68.5247L73.9778 75.001L66.4258 67.4489Z"
                fill="white"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M95.5586 81.4712C98.881 84.7932 97.3614 90.4659 92.8235 91.6823C90.7173 92.2468 88.4701 91.6448 86.9281 90.1031L73.9824 77.156C73.3861 76.5607 73.3861 75.5944 73.9824 74.9991L80.4505 68.5254C81.0463 67.929 82.013 67.929 82.6087 68.5254L95.5586 81.4712Z"
                fill="#5CC4ED"
            />
            <path
                d="M95.5586 81.4712C98.881 84.7932 97.3614 90.4659 92.8235 91.6823C90.7173 92.2468 88.4701 91.6448 86.9281 90.1031L73.9824 77.156C73.3861 76.5607 73.3861 75.5944 73.9824 74.9991L80.4505 68.5254C81.0463 67.929 82.013 67.929 82.6087 68.5254L95.5586 81.4712Z"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.0469 42.6345C16.0324 67.2993 42.7237 82.7305 64.0913 70.4106C74.0197 64.6862 80.1346 54.0949 80.1279 42.6345C80.1424 17.9697 53.451 2.53852 32.0835 14.8584C22.155 20.5828 16.0401 31.1741 16.0469 42.6345Z"
                fill="#5CC4ED"
            />
            <path
                d="M42.3035 67.1359C61.1644 71.5882 77.7721 53.9534 72.1974 35.3933C69.6102 26.7794 62.6241 20.2003 53.8708 18.134C35.0099 13.6817 18.4022 31.3165 23.9769 49.8766C26.564 58.4905 33.5502 65.0696 42.3035 67.1359Z"
                fill="white"
            />
            <path
                d="M42.3035 67.1359C61.1644 71.5882 77.7721 53.9534 72.1974 35.3933C69.6102 26.7794 62.6241 20.2003 53.8708 18.134C35.0099 13.6817 18.4022 31.3165 23.9769 49.8766C26.564 58.4905 33.5502 65.0696 42.3035 67.1359Z"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.0469 42.6345C16.0324 67.2993 42.7237 82.7305 64.0913 70.4106C74.0197 64.6862 80.1346 54.0949 80.1279 42.6345C80.1424 17.9697 53.451 2.53852 32.0835 14.8584C22.155 20.5828 16.0401 31.1741 16.0469 42.6345Z"
                stroke="#222A3F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    ),
    'small-search': (
        <>
            <g clipPath="url(#clip0_8694_3362)">
                <path
                    d="M0.953613 32C0.953613 55.8995 26.8256 70.8367 47.5232 58.8869C57.129 53.341 63.0464 43.0918 63.0464 32C63.0464 8.10043 37.1744 -6.83682 16.4768 5.113C6.87099 10.6589 0.953613 20.9081 0.953613 32Z"
                    fill="white"
                />
                <path
                    d="M37.3617 55.6279C37.3617 56.3722 32.9376 56.9761 27.4781 56.9761C22.0185 56.9761 17.5945 56.3715 17.5945 55.6279C17.5945 54.8844 22.0186 54.2805 27.4781 54.2805C32.9377 54.2805 37.3617 54.8836 37.3617 55.6279Z"
                    fill="#F3F8FF"
                />
                <path
                    d="M37.957 38.543L41.657 34.8423L45.9725 39.1577L42.2725 42.8585L37.957 38.543Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M54.6041 46.5553C56.5027 48.4536 55.6343 51.6951 53.0412 52.3902C51.8376 52.7128 50.5535 52.3688 49.6724 51.4878L42.2748 44.0895C41.9341 43.7493 41.9341 43.1971 42.2748 42.8569L45.9709 39.1577C46.3114 38.8169 46.8638 38.8169 47.2042 39.1577L54.6041 46.5553Z"
                    fill="#5CC4ED"
                />
                <path
                    d="M54.6041 46.5553C56.5027 48.4536 55.6343 51.6951 53.0412 52.3902C51.8376 52.7128 50.5535 52.3688 49.6724 51.4878L42.2748 44.0895C41.9341 43.7493 41.9341 43.1971 42.2748 42.8569L45.9709 39.1577C46.3114 38.8169 46.8638 38.8169 47.2042 39.1577L54.6041 46.5553Z"
                    stroke="#222A3F"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.16925 24.3626C9.16095 38.4567 24.4132 47.2746 36.6232 40.2346C42.2966 36.9635 45.7908 30.9114 45.787 24.3626C45.7953 10.2684 30.5431 1.45058 18.333 8.49051C12.6596 11.7616 9.16539 17.8137 9.16925 24.3626Z"
                    fill="#5CC4ED"
                />
                <path
                    d="M24.1731 38.3631C34.9508 40.9072 44.4409 30.8302 41.2554 20.2244C39.777 15.3022 35.7849 11.5427 30.783 10.362C20.0054 7.81784 10.5153 17.8949 13.7008 28.5006C15.1792 33.4228 19.1712 37.1824 24.1731 38.3631Z"
                    fill="white"
                />
                <path
                    d="M24.1731 38.3631C34.9508 40.9072 44.4409 30.8302 41.2554 20.2244C39.777 15.3022 35.7849 11.5427 30.783 10.362C20.0054 7.81784 10.5153 17.8949 13.7008 28.5006C15.1792 33.4228 19.1712 37.1824 24.1731 38.3631Z"
                    stroke="#222A3F"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.16925 24.3626C9.16095 38.4567 24.4132 47.2746 36.6232 40.2346C42.2966 36.9635 45.7908 30.9114 45.787 24.3626C45.7953 10.2684 30.5431 1.45058 18.333 8.49051C12.6596 11.7616 9.16539 17.8137 9.16925 24.3626Z"
                    stroke="#222A3F"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_8694_3362">
                    <rect width={64} height={64} fill="white" />
                </clipPath>
            </defs>
        </>
    ),
    pill: (
        <>
            <path
                d="M7.26289 2.55001C8.00906 1.7714 9.02108 1.33398 10.0763 1.33398C11.1315 1.33398 12.1436 1.7714 12.8897 2.55001C13.6359 3.32862 14.0551 4.38463 14.0551 5.48575C14.0551 6.58687 13.6359 7.64289 12.8897 8.42149L8.06954 13.4512C7.32337 14.2298 6.31136 14.6673 5.25612 14.6673C4.20088 14.6673 3.18887 14.2298 2.4427 13.4512C1.69654 12.6727 1.27734 11.6166 1.27734 10.5155C1.27734 9.4144 1.69654 8.35839 2.4427 7.57978L7.26289 2.55001Z"
                stroke="#1A497F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.72656 5.35742L10.2442 10.0715"
                stroke="#1A497F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    ),
    'information-circle': (
        <>
            <path
                d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z"
                stroke="#EF7B4B"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.992 17.199V11.151C10.992 10.511 11.152 10.351 11.792 10.351H12.192C12.832 10.351 12.992 10.511 12.992 11.151V17.199C12.992 17.839 12.832 17.999 12.192 17.999H11.792C11.152 17.999 10.992 17.839 10.992 17.199ZM10.848 7.77505C10.848 7.15105 11.232 6.62305 12 6.62305C12.768 6.62305 13.136 7.15105 13.136 7.77505C13.136 8.39905 12.768 8.91105 12 8.91105C11.232 8.91105 10.848 8.39905 10.848 7.77505Z"
                fill="#EF7B4B"
            />
        </>
    ),
    cog: (
        <path
            d="M234.875 18.78c-26.087 2.367-51.557 8.56-74.875 18.782 15.37 32.763 14.222 66.706-6.72 82.407-20.835 15.617-54.055 7.965-81.124-15.69-16.246 19.452-29.336 41.36-38.875 65.626 33.83 12.333 56.635 37.665 52.94 63.5-3.698 25.835-32.697 43.74-68.626 46.094 2.338 25.796 8.91 50.778 18.937 73.875 17.81-8.182 35.793-11.09 51.095-8.938 13.032 1.87 23.927 7.015 31.156 16.657 15.817 21.097 7.603 54.713-16.78 81.97 19.516 16.35 42.216 29.444 66.594 39.03 12.33-33.828 37.655-56.634 63.5-52.938 25.844 3.697 43.74 32.696 46.094 68.625 26.087-2.365 51.557-8.555 74.875-18.78-15.766-32.997-14.26-67.588 6.843-83.406 9.64-7.23 22.568-9.022 35.594-7.125 15.112 2.16 31.19 10.25 45.563 22.78 16.088-19.345 29.4-41.51 38.875-65.594-33.83-12.332-56.635-37.653-52.938-63.5 3.697-25.846 32.665-43.772 68.594-46.125-2.36-25.944-8.774-50.663-18.906-73.874-32.612 15.117-66.66 13.145-82.282-7.687-15.696-20.944-7.252-53.86 16.688-81-19.52-16.352-42.248-29.447-66.625-39.032-12.332 33.828-37.657 56.66-63.5 52.968-25.846-3.693-43.744-32.696-46.095-68.625zm21.656 95.126c79.626 0 144.376 64.752 144.376 144.375 0 79.626-64.75 144.376-144.375 144.376-79.624 0-144.374-64.75-144.374-144.375 0-79.624 64.75-144.374 144.375-144.374zm0 18.688c-69.524 0-125.686 56.162-125.686 125.687 0 69.526 56.162 125.69 125.687 125.69 69.526 0 125.69-56.164 125.69-125.69 0-69.522-56.164-125.686-125.69-125.686zm.033 15.125c61.094 0 110.625 49.53 110.625 110.624 0 61.095-49.53 110.625-110.625 110.625s-110.625-49.53-110.625-110.626c0-61.095 49.53-110.625 110.625-110.625z"
            stroke="#1A497F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    ),
    'help-info': (
        <>
            <g id="Icon" clipPath="url(#clip0_46571_9827)">
                <path
                    id="Vector"
                    d="M9.03846 9.03784C9.03846 8.45209 9.21231 7.87952 9.53803 7.39249C9.86373 6.90548 10.3267 6.52589 10.8683 6.30173C11.41 6.07758 12.006 6.01894 12.581 6.13321C13.156 6.24747 13.6842 6.52953 14.0987 6.94372C14.5133 7.3579 14.7956 7.88559 14.91 8.46006C15.0243 9.03455 14.9657 9.63001 14.7413 10.1712C14.5169 10.7123 14.137 11.1748 13.6495 11.5002C13.1621 11.8257 12.589 11.9994 12.0027 11.9994V13.9737M12.0027 17.5L12 18M12 23C18.0752 23 23 18.0752 23 12C23 5.92487 18.0752 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0752 5.92487 23 12 23Z"
                    stroke="#1A497F"
                    strokeOpacity="0.2"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_46571_9827">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </>
    ),
    plus: (
        <>
            <path
                d="M12 5.5V19.5M5 12.4569H19"
                stroke="#03A4DC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </>
    ),
    'plus-circle': (
        <>
            <g id="Add - Circle" clipPath="url(#clip0_39984_4015)">
                <path
                    id="Vector"
                    d="M12 6.92308V17.0769M6.92308 12H17.0769M12 23C18.0752 23 23 18.0752 23 12C23 5.92487 18.0752 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0752 5.92487 23 12 23Z"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_39984_4015">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </>
    )
};
