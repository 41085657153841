import { BIRDI_PLANS, PLANS_ALLOWING_PRICE } from 'enums/plans';

//
// --- Functions ---

// Validates if the plan allows pricing to be displayed
export const doesPlanAllowsPricing = (planAlias?: string | BIRDI_PLANS): boolean => {
    if (!planAlias) return false;
    return PLANS_ALLOWING_PRICE.includes(planAlias as BIRDI_PLANS);
};
