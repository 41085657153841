import classNames from 'classnames';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

import BirdiAccordion from 'ui-kit/accordion/accordion';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import HealthConditions, { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import { HealthConditionsSubmitEvent } from 'components/health-conditions/health-conditions.props';
import {
    HealthConditionRendererProps,
    HealthConditionsAddRxProps,
    HealthConditionsModalContentProps,
    HealthProfileConditionsUpdateEvent,
    HealthProfileProps
} from 'components/health-profile/health-profile.props';

import { accountUpdateAllergiesRoutine, accountUpdateMedicalConditionsRoutine } from 'state/account/account.routines';
import {
    medicalConditionsAddOrUpdateRoutine,
    medicalConditionsAllergiesAddOrUpdateRoutine
} from 'state/medical-conditions/medical-conditions.routines';

import { filterUndefined } from 'util/function';

import { useHealthConditions } from 'hooks/useHealthConditions';

import './health-profile.style.scss';

export const HealthConditionsModalContent = ({
    icon,
    title,
    subTitle,
    freeformConditionsLabel,
    submitLabel,
    onUpdateHealthConditions,
    isMembershipHealthConditions
}: HealthConditionsModalContentProps) => {
    const { conditionChoices, existingConditions, existingFreeformConditions, ePostPatientNum } =
        useHealthConditions(isMembershipHealthConditions);

    return (
        <BirdiModalContent
            icon={icon ?? 'none'}
            title={title}
            subTitle={subTitle}
            body={renderHealthConditions({
                isInModal: true,
                formName: 'healthConditionsModalContent',
                action: isMembershipHealthConditions
                    ? medicalConditionsAddOrUpdateRoutine
                    : accountUpdateMedicalConditionsRoutine,
                conditionChoices: conditionChoices.filter(filterUndefined),
                conditionType: 'condition',
                ePostPatientNum,
                existingConditions,
                existingFreeformConditions,
                freeformConditionsLabel,
                onUpdateHealthConditions,
                submitLabel
            })}
        />
    );
};

export const AllergiesModalContent = ({
    icon,
    title,
    subTitle,
    freeformConditionsLabel,
    submitLabel,
    onUpdateHealthConditions,
    isMembershipHealthConditions
}: HealthConditionsModalContentProps) => {
    const { allergyChoices, existingAllergies, existingFreeformAllergies, ePostPatientNum } =
        useHealthConditions(isMembershipHealthConditions);

    return (
        <BirdiModalContent
            icon={icon ?? 'none'}
            title={title}
            subTitle={subTitle}
            body={renderHealthConditions({
                isInModal: true,
                formName: 'allergiesModalContent',
                action: isMembershipHealthConditions
                    ? medicalConditionsAllergiesAddOrUpdateRoutine
                    : accountUpdateAllergiesRoutine,
                conditionChoices: allergyChoices.filter(filterUndefined),
                conditionType: 'allergy',
                ePostPatientNum,
                existingConditions: existingAllergies,
                existingFreeformConditions: existingFreeformAllergies,
                freeformConditionsLabel: freeformConditionsLabel,
                onUpdateHealthConditions,
                submitLabel
            })}
        />
    );
};

export const renderHealthConditions = ({
    action,
    ePostPatientNum,
    existingConditions,
    existingFreeformConditions,
    freeformConditionsLabel,
    conditionChoices,
    conditionType,
    onUpdateHealthConditions,
    submitLabel,
    formName,
    isInModal
}: HealthConditionRendererProps) => {
    const handleUpdateConditionsClick = ({
        action,
        e: { conditions, freeformConditions },
        choices
    }: HealthProfileConditionsUpdateEvent) => {
        //  set values on the update payload
        const formattedChoicesObject = choices.reduce<{ [condition: string]: false }>(
            (obj, cond) => ({
                ...obj,
                [cond]: false
            }),
            {}
        );
        const formattedConditionsObject = conditions.reduce<{ [condition: string]: true }>(
            (obj, cond) => ({
                ...obj,
                [cond]: true
            }),
            {}
        );

        const update = {
            ePostPatientNum: ePostPatientNum,
            Other: freeformConditions,
            TemporaryOther: freeformConditions,
            ...formattedChoicesObject,
            ...formattedConditionsObject
        };

        if (onUpdateHealthConditions) onUpdateHealthConditions({ action, update });
    };

    return (
        <HealthConditions
            existingConditions={existingConditions}
            conditionChoices={conditionChoices}
            conditionType={conditionType}
            freeformExistingConditions={existingFreeformConditions}
            freeformConditionsLabel={freeformConditionsLabel}
            onSubmit={(e: HealthConditionsSubmitEvent) => {
                handleUpdateConditionsClick({
                    action,
                    e,
                    choices: conditionChoices
                });
            }}
            submitLabel={submitLabel}
            formName={formName}
            isInModal={isInModal}
            dataGaLocation="HealthconditionsModal"
        />
    );
};

export const HealthConditionsContent = ({
    label,
    freeformConditionsLabel,
    submitLabel,
    onUpdateHealthConditions
}: HealthConditionsAddRxProps) => {
    const { conditionChoices, existingConditions, existingFreeformConditions, ePostPatientNum } = useHealthConditions();
    return (
        <Row className="d-flex flex-column mb-5">
            <div className="title-wrapper d-flex align-items-center mb-3">
                <div className="blue-half-pill mr-2" style={{ height: '0.8rem', width: '0.8rem' }} />
                <div>{label}</div>
            </div>
            <Col>
                {renderHealthConditions({
                    formName: 'addRXHealthConditions',
                    action: accountUpdateMedicalConditionsRoutine,
                    conditionChoices: conditionChoices.filter(filterUndefined),
                    conditionType: 'condition',
                    ePostPatientNum,
                    existingConditions,
                    existingFreeformConditions,
                    freeformConditionsLabel,
                    onUpdateHealthConditions,
                    submitLabel
                })}
            </Col>
        </Row>
    );
};

export const AllergiesContent = ({
    label,
    freeformConditionsLabel,
    submitLabel,
    onUpdateHealthConditions
}: HealthConditionsAddRxProps) => {
    const { allergyChoices, existingAllergies, existingFreeformAllergies, ePostPatientNum } = useHealthConditions();
    return (
        <Row className="d-flex flex-column mb-5">
            <div className="title-wrapper d-flex align-items-center mb-3">
                <div className="blue-half-pill mr-2" style={{ height: '0.8rem', width: '0.8rem' }} />
                <div>{label}</div>
            </div>
            <Col>
                {renderHealthConditions({
                    formName: 'addRxAllergies',
                    action: accountUpdateAllergiesRoutine,
                    conditionChoices: allergyChoices.filter(filterUndefined),
                    conditionType: 'allergy',
                    ePostPatientNum,
                    existingConditions: existingAllergies,
                    existingFreeformConditions: existingFreeformAllergies,
                    freeformConditionsLabel: freeformConditionsLabel,
                    onUpdateHealthConditions,
                    submitLabel
                })}
            </Col>
        </Row>
    );
};

const HealthProfile = ({
    className,
    includeAccordionSectionTitlePillIcon,
    onUpdateHealthConditions,
    healthConditions,
    isMembershipHealthConditions,
    isLoadingAllergies,
    isLoadingHealthConditions
}: HealthProfileProps) => {
    const { t } = useTranslation();

    const classes = classNames('health-profile-component', className);

    const {
        allergyChoices,
        existingAllergies,
        existingFreeformAllergies,
        conditionChoices,
        existingConditions,
        existingFreeformConditions,
        ePostPatientNum
    } = healthConditions;

    const medicalConditionsRendered = useMemo(() => {
        return renderHealthConditions({
            action: isMembershipHealthConditions
                ? medicalConditionsAddOrUpdateRoutine
                : accountUpdateMedicalConditionsRoutine,
            conditionChoices: conditionChoices.filter(filterUndefined),
            conditionType: 'condition',
            ePostPatientNum,
            existingConditions,
            existingFreeformConditions,
            onUpdateHealthConditions,
            submitLabel: t('components.healthConditions.labels.submitConditions'),
            formName: t('accordions.healthConditions.title')
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [healthConditions]);

    const allergiesRendered = useMemo(() => {
        return renderHealthConditions({
            action: isMembershipHealthConditions
                ? medicalConditionsAllergiesAddOrUpdateRoutine
                : accountUpdateAllergiesRoutine,
            existingConditions: existingAllergies,
            conditionChoices: allergyChoices.filter(filterUndefined),
            conditionType: 'allergy',
            ePostPatientNum,
            existingFreeformConditions: existingFreeformAllergies,
            freeformConditionsLabel: t('components.healthConditions.labels.freeformAllergiesLabel'),
            onUpdateHealthConditions,
            submitLabel: t('components.healthConditions.labels.submitAllergies'),
            formName: t('accordions.allergies.title')
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [healthConditions]);

    const isHealthProfileIncomplete = existingConditions.length === 0 || existingAllergies.length === 0;
    return (
        <div className={classes}>
            {isHealthProfileIncomplete ? (
                <ToastBox variant="warning" icon="warning">
                    <Trans i18nKey={'pages.requestTelemedicineVisit.healthProfile.healthProfileConfirmation'} />
                </ToastBox>
            ) : (
                <p className="health-profile-component_note"> {t('pages.profile.healthProfile.subHeading')}</p>
            )}

            <BirdiAccordion>
                <BirdiAccordion.Toggle
                    title={t('accordions.healthConditions.title')}
                    toggleText={t('accordions.healthConditions.toggleText')}
                    includeTitleIcon={includeAccordionSectionTitlePillIcon}
                    alwaysOpenContent={
                        isLoadingHealthConditions ? (
                            <SpinnerInline />
                        ) : (
                            <HealthConditionPills conditions={existingConditions} />
                        )
                    }
                >
                    {isLoadingHealthConditions ? <></> : medicalConditionsRendered}
                </BirdiAccordion.Toggle>
                <BirdiAccordion.Spacer />
                <BirdiAccordion.Toggle
                    title={t('accordions.allergies.title')}
                    includeTitleIcon={includeAccordionSectionTitlePillIcon}
                    toggleText={t('accordions.allergies.toggleText')}
                    alwaysOpenContent={
                        isLoadingAllergies ? <SpinnerInline /> : <HealthConditionPills conditions={existingAllergies} />
                    }
                >
                    {isLoadingAllergies ? <></> : allergiesRendered}
                </BirdiAccordion.Toggle>
            </BirdiAccordion>
        </div>
    );
};

export default HealthProfile;
