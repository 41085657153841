import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'ui-kit-v2/icon/icon';

import { transformTextToId } from 'util/string';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { TextInputProps } from './text-input.props';
import './text-input.styles.scss';

const TextInputComponent = forwardRef<HTMLInputElement, TextInputProps>(
    (
        {
            id,
            label,
            name,
            value,
            onChange,
            onBlur,
            icon,
            error,
            success,
            contentLeft,
            contentRight,
            type,
            tooltip,
            readOnly,
            onlyNumbers,
            onlyLetters,
            className,
            disabled = false,
            variant = 'default',
            onKeyUp,
            ...props
        },
        ref
    ) => {
        const MIN_INPUT_WIDTH_TO_SHOW_WARNING_ICON = 160;
        const [focused, setFocused] = useState(false);
        const [filled, setFilled] = useState(false);
        const [isLargeInput, setIsLargeInput] = useState(false);
        const inputId = useMemo(() => name || id || transformTextToId(label), [name, id, label]);
        const { width } = useWindowDimensions();
        const { t } = useTranslation();

        const hasWarningIcon = isLargeInput;
        const hasOverflowLabel = !isLargeInput;

        useEffect(() => {
            const parsedValue = String(value).trim();
            setFilled(parsedValue !== undefined && parsedValue !== null && parsedValue !== '');
        }, [value]);

        useEffect(() => {
            const checkInputWidth = () => {
                const inputElement = document.getElementById(inputId) as HTMLElement | null;
                if (inputElement) {
                    const inputWidth = inputElement.offsetWidth;
                    setIsLargeInput(inputWidth >= MIN_INPUT_WIDTH_TO_SHOW_WARNING_ICON);
                }
            };

            checkInputWidth();
            window.addEventListener('resize', checkInputWidth);
            return () => window.removeEventListener('resize', checkInputWidth);
        }, [inputId, width]);

        const handleChange = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                let newValue = event.target.value;

                if (onlyNumbers) {
                    newValue = newValue.replace(/\D/g, '');
                }

                if (onlyLetters) {
                    newValue = newValue.replace(/[^a-zA-Z\s]/g, '');
                }

                const maxLength = event.target.maxLength;
                if (maxLength > 0) {
                    newValue = newValue.slice(0, maxLength);
                }

                event.target.value = newValue;
                onChange?.(event);
            },
            [onChange, onlyNumbers, onlyLetters]
        );

        const handlePaste = useCallback(
            (event: React.ClipboardEvent<HTMLInputElement>) => {
                event.preventDefault();

                const pastedText = event.clipboardData.getData('text');
                const inputElement = event.currentTarget;

                inputElement.value = pastedText;

                handleChange({
                    ...event,
                    target: inputElement,
                    currentTarget: inputElement
                });
            },
            [handleChange]
        );

        const handleBlur = useCallback(
            (event: React.FocusEvent<HTMLInputElement>) => {
                setFocused(false);
                onBlur?.(event);
                setFilled(value !== undefined && value !== null && value !== '');
            },
            [onBlur, value]
        );

        const handleKeyUp = useCallback(
            (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (onKeyUp) {
                    onKeyUp?.(event);
                    setFocused(false);
                    setFilled(value !== undefined && value !== null && value !== '');
                }
            },
            [onKeyUp, value]
        );

        const handleContentLeftClick = useCallback(
            (event: React.MouseEvent<HTMLButtonElement>) => {
                contentLeft?.onClick?.(event);
                event.preventDefault();
                event.currentTarget.blur();
            },
            [contentLeft]
        );

        const handleAnimationStart = useCallback(
            (event: React.AnimationEvent<HTMLInputElement>) => {
                const inputElement = event.target as HTMLInputElement;

                if (event.animationName === 'autofill') {
                    let newValue = inputElement.value;

                    if (onlyNumbers) {
                        newValue = newValue.replace(/\D/g, '');
                    }

                    if (onlyLetters) {
                        newValue = newValue.replace(/[^a-zA-Z]/g, '');
                    }

                    inputElement.value = newValue.trim();

                    const syntheticEvent = new Event('input', { bubbles: true });
                    inputElement.dispatchEvent(syntheticEvent as Event & { target: HTMLInputElement });
                }
            },
            [onlyNumbers, onlyLetters]
        );

        const memoizedContentLeft = useMemo(() => {
            if (!contentLeft) return null;

            return (
                <button
                    tabIndex={0}
                    className="text-input-component__content-left"
                    style={contentLeft.onClick ? { cursor: 'pointer' } : undefined}
                    onClick={handleContentLeftClick}
                    aria-label={t('uiComponents.textInputComponent.contentLeftButton')}
                >
                    {contentLeft.children}
                    {contentLeft.tooltip && (
                        <span className="text-input-component__content-left__tooltip">{contentLeft.tooltip}</span>
                    )}
                </button>
            );
        }, [contentLeft, handleContentLeftClick, t]);

        const labelClassName = useMemo(() => {
            return `text-input-component__label ${focused || filled ? 'text-input-component__label--active' : ''}
            ${contentLeft ? 'text-input-component__label--with-left-content' : ''}
            ${error ? 'text-input-component__label--with-error' : ''}
            ${hasOverflowLabel ? 'text-input-component__label--with-overflow-label' : ''}`;
        }, [focused, filled, contentLeft, error, hasOverflowLabel]);

        const inputClassName = useMemo(() => {
            const baseClass = 'text-input-component__input';
            const filledClass = focused || filled ? 'text-input-component__input--filled' : '';
            const leftContentClass = contentLeft ? 'text-input-component__input--with-left-content' : '';
            const disabledClass = disabled ? 'text-input-component__input--disabled' : '';
            return [baseClass, filledClass, leftContentClass, disabledClass].filter(Boolean).join(' ');
        }, [focused, filled, contentLeft, disabled]);

        return (
            <div
                className={`text-input-component ${disabled || readOnly ? 'text-input-component--disabled' : ''} ${
                    !label ? 'text-input-component--no-label' : ''
                } `}
            >
                <div
                    className={`text-input-component__wrapper ${
                        variant === 'alt' ? 'text-input-component__wrapper--alt' : ''
                    } ${error ? 'text-input-component__wrapper--error' : ''} ${
                        success ? 'text-input-component__wrapper--success' : ''
                    } ${readOnly ? 'text-input-component__wrapper--readonly' : ''}
                    ${className || ''}`}
                >
                    {memoizedContentLeft}
                    <input
                        id={inputId}
                        ref={ref}
                        type={type || 'text'}
                        value={value}
                        onChange={!readOnly ? handleChange : undefined}
                        onFocus={() => setFocused(true)}
                        onBlur={handleBlur}
                        onKeyUp={handleKeyUp}
                        onPaste={handlePaste}
                        name={name}
                        aria-invalid={!!error}
                        aria-describedby={error ? `${inputId}-error` : success ? `${inputId}-success` : undefined}
                        aria-disabled={disabled || readOnly}
                        aria-readonly={readOnly}
                        disabled={disabled}
                        readOnly={readOnly}
                        onAnimationStart={handleAnimationStart}
                        tabIndex={disabled ? 0 : undefined}
                        {...props}
                        className={inputClassName}
                    />
                    {label && (
                        <label className={labelClassName} htmlFor={inputId}>
                            {label}
                        </label>
                    )}

                    {error && hasWarningIcon && (
                        <div
                            className="text-input-component__error-icon"
                            style={tooltip ? { cursor: 'pointer' } : undefined}
                            aria-describedby={`${inputId}-error`}
                        >
                            <span className="text-input-component__error-icon__icon">
                                <Icon icon="warning" color="deep-chestnut" />
                            </span>
                            {tooltip && <span className="text-input-component__error-icon__tooltip">{tooltip}</span>}
                        </div>
                    )}

                    {success && !error && (
                        <div
                            className="text-input-component__success-icon"
                            style={tooltip ? { cursor: 'pointer' } : undefined}
                            aria-describedby={`${inputId}-success`}
                        >
                            <span className="text-input-component__error-icon__icon">
                                <Icon icon="checkmark" color="puerto-rico" />
                            </span>
                            {tooltip && <span className="text-input-component__error-icon__tooltip">{tooltip}</span>}
                        </div>
                    )}

                    {contentRight && (
                        <button
                            tabIndex={disabled ? -1 : 0}
                            className="text-input-component__content-right"
                            style={contentRight.onClick ? { cursor: 'pointer' } : undefined}
                            onClick={(event) => {
                                contentRight.onClick?.(event);
                                event.preventDefault();
                                event.currentTarget.blur();
                            }}
                            aria-label={t('uiComponents.textInputComponent.contentRightButton')}
                        >
                            {contentRight.children}
                            {contentRight.tooltip && (
                                <span className="text-input-component__content-right__tooltip">
                                    {contentRight.tooltip}
                                </span>
                            )}
                        </button>
                    )}
                </div>
                {error && (
                    <div className="text-input-component__info-container">
                        <small
                            id={`${inputId}-error`}
                            className={`text-input-component__hint ${error ? 'text-input-component__hint--error' : ''}`}
                            role="alert"
                            aria-live="assertive"
                        >
                            {error}
                        </small>
                    </div>
                )}
            </div>
        );
    }
);

export default TextInputComponent;
