import axios from 'axios';
import { EASY_REFILL_API_URL } from 'gatsby-env-variables';

import {
    AuthenticateUserRequest,
    AuthenticateUserResponse,
    AutoRefillResponse,
    CreateOrUpdateAddressRequest,
    CreateOrUpdateAddressResponse,
    CreateOrUpdatePaymentRequest,
    CreateOrUpdatePaymentResponse,
    EasyRefillCaregiverPaymentData,
    EasyRefillCartPayload,
    EasyRefillPaymetricDetailsPayload,
    GetEasyRefillAddressDataResponse,
    GetEasyRefillPatientDataResponse,
    GetEasyRefillPatientFamilyDataResponse,
    GetEasyRefillPaymentCardDataResponse,
    GetEasyRefillTokenizedCardNumberResponse,
    GetTokenizedCardNumberRequest,
    ToggleAutoRefillRequest,
    VerifyUserRequest,
    VerifyUserResponse
} from 'types/easy-refill';
import { Allergies, MedicalConditions } from 'types/easy-refill-health-profile';

import axiosClient from 'util/axiosClient';
import { threatHealthConditionsPayload } from 'util/medical-conditions';
import { transformError } from 'util/service.helper';

export interface APIHealthConditionsPayload {
    messageStatus: boolean;
    messageText: string;
}
export interface APIMedicalConditionsPayload extends APIHealthConditionsPayload {
    patientCondition: MedicalConditions[];
}

export interface APIAllergiesPayload extends APIHealthConditionsPayload {
    patientAllergies: Allergies[];
}

export interface CancelOrderLineRequest {
    bearerToken: string;
    rxNumber: string;
    lineId: string;
    orderNum: string;
    epostNumPatient: string;
}

// @TODO: Implement the response interface
export interface CancelOrderLineResponse {
    cartId: number;
    messageStatus: boolean;
    messageErrorText: string;
}

export interface FamilyMembersRequest {
    key: string;
    label: string;
    value: string | string[];
}

export class EasyRefillService {
    static verifyUser() {
        return {
            post: async (payload: VerifyUserRequest): Promise<VerifyUserResponse> => {
                const response = await axiosClient.post(`${EASY_REFILL_API_URL}/Authentication/VerifyUser`, payload);
                return response.data;
            }
        };
    }
    static authenticateUser() {
        return {
            post: async (payload: AuthenticateUserRequest): Promise<AuthenticateUserResponse> => {
                const response = await axiosClient.post(`${EASY_REFILL_API_URL}/Authentication/Authenticate`, payload);
                return response.data;
            }
        };
    }
    static getSecretKeyHash() {
        return {
            get: async (payload: string): Promise<string> => {
                const response = await axiosClient.get(
                    `${EASY_REFILL_API_URL}/Authentication/CreateSecretKeyHash?securityToken=${payload}`
                );
                return response.data;
            }
        };
    }
    static getPatientData() {
        return {
            get: async (bearerToken: string): Promise<GetEasyRefillPatientDataResponse> => {
                const response = await axiosClient.get(`${EASY_REFILL_API_URL}/EasyRefill/GetEasyRefillPatientData`, {
                    headers: { Authorization: `Bearer ${bearerToken}` }
                });
                return response.data;
            }
        };
    }

    static getFamilyData() {
        return {
            get: async (bearerToken: string): Promise<GetEasyRefillPatientFamilyDataResponse> => {
                const response = await axiosClient.get<GetEasyRefillPatientFamilyDataResponse>(
                    `${EASY_REFILL_API_URL}/EasyRefill/GetEasyRefillPatientFamilyData`,
                    {
                        headers: {
                            Authorization: `Bearer ${bearerToken}`
                        }
                    }
                );
                return response.data;
            }
        };
    }

    // Address Data

    static getAddresses() {
        return {
            adresses: async (bearerToken: string): Promise<GetEasyRefillAddressDataResponse> => {
                const response = await axiosClient.get(`${EASY_REFILL_API_URL}/EasyRefill/GetAddresses`, {
                    headers: { Authorization: `Bearer ${bearerToken}` }
                });
                return response.data;
            },
            familyAddresses: async (bearerToken: string): Promise<GetEasyRefillAddressDataResponse> => {
                const response = await axiosClient.get(`${EASY_REFILL_API_URL}/EasyRefill/GetAddressesFamily`, {
                    headers: { Authorization: `Bearer ${bearerToken}` }
                });
                return response.data;
            }
        };
    }

    static addUpdateAddress() {
        return {
            post: async (
                payload: CreateOrUpdateAddressRequest & { bearerToken: string }
            ): Promise<CreateOrUpdateAddressResponse> => {
                const { bearerToken: _, ...newPayload } = payload;
                const response = await axiosClient.post(
                    `${EASY_REFILL_API_URL}/EasyRefill/AddUpdateAddress`,
                    newPayload,
                    {
                        headers: { Authorization: `Bearer ${payload.bearerToken}` }
                    }
                );

                const { messageStatus } = response.data;
                if (!messageStatus) {
                    throw transformError(response.data);
                }

                return response.data;
            }
        };
    }

    /**
     * Get payment methods for Caregivers and normal patients.
     */
    static getPaymentCards() {
        return {
            cards: async (bearerToken: string): Promise<GetEasyRefillPaymentCardDataResponse> => {
                const response = await axiosClient.get(`${EASY_REFILL_API_URL}/EasyRefill/GetPaymentCards`, {
                    headers: { Authorization: `Bearer ${bearerToken}` }
                });
                return response.data;
            },
            familyCards: async (bearerToken: string): Promise<EasyRefillCaregiverPaymentData> => {
                const response = await axiosClient.get(`${EASY_REFILL_API_URL}/EasyRefill/GetPaymentCardsFamily`, {
                    headers: { Authorization: `Bearer ${bearerToken}` }
                });
                return response.data;
            }
        };
    }

    static getPaymetricCredentials() {
        return {
            get: async (bearerToken: string): Promise<EasyRefillPaymetricDetailsPayload> => {
                const response = await axiosClient.get(`${EASY_REFILL_API_URL}/EasyRefill/GetPaymetricCredentials`, {
                    headers: { Authorization: `Bearer ${bearerToken}` }
                });
                return response.data;
            }
        };
    }

    static getTokenizedCardNumber() {
        return {
            get: async (
                payload: GetTokenizedCardNumberRequest & { bearerToken: string }
            ): Promise<GetEasyRefillTokenizedCardNumberResponse> => {
                const response = await axiosClient.get(
                    `${EASY_REFILL_API_URL}/EasyRefill/GetTokenizedCardNumber?accessToken=${payload.accessToken}`,
                    {
                        headers: { Authorization: `Bearer ${payload.bearerToken}` }
                    }
                );
                return response.data;
            }
        };
    }

    static addOrUpdatePaymentCard() {
        return {
            post: async (
                payload: CreateOrUpdatePaymentRequest & { bearerToken: string }
            ): Promise<CreateOrUpdatePaymentResponse> => {
                const { bearerToken: _, ...newPayload } = payload;
                const response = await axiosClient.post(
                    `${EASY_REFILL_API_URL}/EasyRefill/AddOrUpdatePayment`,
                    newPayload,
                    {
                        headers: { Authorization: `Bearer ${payload.bearerToken}` }
                    }
                );
                return response.data;
            }
        };
    }

    static toggleAutoRefillForRx() {
        return {
            get: async (payload: ToggleAutoRefillRequest & { bearerToken: string }): Promise<AutoRefillResponse> => {
                const response = await axiosClient.get(
                    `${EASY_REFILL_API_URL}/EasyRefill/ToggleAutoRefill?rxNum=${payload.rxNum}&newStatus=${payload.newStatus}`,
                    {
                        headers: { Authorization: `Bearer ${payload.bearerToken}` }
                    }
                );
                return response.data;
            }
        };
    }

    static medicalConditions() {
        return {
            getAll: (payload: { bearerToken: string; epostPatientNum: string }): Promise<any> => {
                return axiosClient
                    .get(
                        `${EASY_REFILL_API_URL}/EasyRefill/GetMedicalConditions?epostPatientNumber=${payload.epostPatientNum}`,
                        {
                            headers: { Authorization: `Bearer ${payload.bearerToken}` }
                        }
                    )
                    .then((response) => response.data);
            },
            post: async (payload: MedicalConditions & { bearerToken: string }): Promise<any> => {
                const { bearerToken: _, ...newPayload } = payload;
                const threatedPayload = threatHealthConditionsPayload(newPayload);
                const response = await axiosClient.post(
                    `${EASY_REFILL_API_URL}/EasyRefill/UpdateMedicalConditions`,
                    threatedPayload,
                    {
                        headers: { Authorization: `Bearer ${payload.bearerToken}` }
                    }
                );

                return response.data;
            }
        };
    }
    static allergies() {
        return {
            getAll: (payload: { bearerToken: string; epostPatientNum: string }): Promise<any> => {
                return axiosClient
                    .get(
                        `${EASY_REFILL_API_URL}/EasyRefill/GetAllergies?epostPatientNumber=${payload.epostPatientNum}`,
                        {
                            headers: { Authorization: `Bearer ${payload.bearerToken}` }
                        }
                    )
                    .then((response) => response.data);
            },
            post: async (payload: Allergies & { bearerToken: string }): Promise<any> => {
                const { bearerToken: _, ...newPayload } = payload;
                const threatedPayload = threatHealthConditionsPayload(newPayload);
                const response = await axiosClient.post(
                    `${EASY_REFILL_API_URL}/EasyRefill/UpdateAllergies`,
                    threatedPayload,
                    {
                        headers: { Authorization: `Bearer ${payload.bearerToken}` }
                    }
                );

                return response.data;
            }
        };
    }

    // DRX-2008: Unify the start order call, i an static function to manage the adapt of the data
    // for both type of patients.
    /**
     * Get Cart and Start Order
     */
    static startOrder() {
        return {
            getCart: async (payload: {
                bearerToken: string;
                epostPatientNum: string;
            }): Promise<EasyRefillCartPayload[]> => {
                const response = await axiosClient.get(
                    `${EASY_REFILL_API_URL}/EasyRefill/GetCart?epostPatientNumber=${payload.epostPatientNum}`,
                    {
                        headers: { Authorization: `Bearer ${payload.bearerToken}` }
                    }
                );
                const { refillRxs, orderHeader, ...params } = response.data;

                return [
                    {
                        order: {
                            orderHeader: {
                                ...orderHeader
                            },
                            refillRxs: refillRxs,
                            ...params
                        }
                    }
                ] as EasyRefillCartPayload[];
            },
            getCaregiverCart: async (payload: {
                bearerToken: string;
                epostPatientNum: string;
            }): Promise<EasyRefillCartPayload[]> => {
                const response = await axiosClient.get(
                    `${EASY_REFILL_API_URL}/EasyRefill/GetCaregiverCart?epostPatientNumber=${payload.epostPatientNum}`,
                    {
                        headers: { Authorization: `Bearer ${payload.bearerToken}` }
                    }
                );
                return response.data;
            },
            startOrder: async (payload: {
                bearerToken: string;
                epostPatientNum: string;
                updatedCartObject: any;
                // DRX-2008: TODO: Validate the interfaces.
            }): Promise<any> => {
                const { bearerToken, epostPatientNum, updatedCartObject } = payload;
                return axiosClient
                    .post(
                        `${EASY_REFILL_API_URL}/EasyRefill/StartOrder?epostPatientNumber=${epostPatientNum}`,
                        updatedCartObject.order,
                        {
                            headers: { Authorization: `Bearer ${bearerToken}` }
                        }
                    )
                    .then((response) => {
                        const { refillRxs, orderHeader, ...params } = response.data;

                        return {
                            epostPatientNum: updatedCartObject?.epostPatientNum,
                            order: {
                                orderHeader: {
                                    ...orderHeader
                                },
                                refillRxs: refillRxs,
                                ...params
                            }
                        };
                    });
            },
            startCaregiverOrder: async (payload: {
                bearerToken: string;
                epostPatientNum: string;
                updatedCartObject: any;
                // DRX-2008: TODO: Validate the interfaces.
            }): Promise<any> => {
                return axiosClient
                    .post(
                        `${EASY_REFILL_API_URL}/EasyRefill/StartOrderCaregiver?epostPatientNumber=${payload.epostPatientNum}`,
                        payload.updatedCartObject,
                        {
                            headers: { Authorization: `Bearer ${payload.bearerToken}` }
                        }
                    )
                    .then((response) => response.data);
            }
        };
    }

    static updateCart() {
        return {
            put: async (payload: {
                bearerToken: string;
                epostPatientNum: string;
                updatedCartObject: any;
            }): Promise<any> => {
                return axiosClient
                    .put(
                        `${EASY_REFILL_API_URL}/EasyRefill/UpdateCaregiverCart/${payload.epostPatientNum}/false`,
                        payload.updatedCartObject.order,
                        {
                            headers: { Authorization: `Bearer ${payload.bearerToken}` }
                        }
                    )
                    .then((response) => response.data);
            }
        };
    }

    static getPatientPlans() {
        return {
            get: (payload: { bearerToken: string; members: string[] }): Promise<unknown> => {
                const familyPlansRequests = payload.members.map((member) =>
                    axiosClient
                        .get(`${EASY_REFILL_API_URL}/EasyRefill/Plans/List?epostPatientNum=${member}`, {
                            headers: { Authorization: `Bearer ${payload.bearerToken}` }
                        })
                        .then((response) => {
                            return response.data.patientPlans.results;
                        })
                );

                return axios
                    .all(familyPlansRequests)
                    .then(
                        axios.spread((...responses) => {
                            return responses.flat();
                        })
                    )
                    .catch((errors) => {
                        console.error('errors => ', errors);
                    });
            }
        };
    }

    static cancelOrderLine() {
        return {
            post: async (payload: CancelOrderLineRequest): Promise<CancelOrderLineResponse> => {
                const { bearerToken, ...payloadProperties } = payload;
                return axiosClient
                    .post(`${EASY_REFILL_API_URL}/EasyRefill/CancelOrderLine`, payloadProperties, {
                        headers: { Authorization: `Bearer ${bearerToken}` }
                    })
                    .then((response) => response.data);
            }
        };
    }

    static completeOrder() {
        return {
            post: (payload: {
                bearerToken: string;
                epostPatientNum: string;
                updatedCartObject: EasyRefillCartPayload[];
            }): Promise<any> => {
                return axiosClient
                    .post(
                        `${EASY_REFILL_API_URL}/EasyRefill/CompleteOrder?epostPatientNumber=${payload.epostPatientNum}`,
                        payload.updatedCartObject && payload.updatedCartObject[0].order,
                        {
                            headers: { Authorization: `Bearer ${payload.bearerToken}` }
                        }
                    )
                    .then((response) => {
                        return [{ order: response.data }];
                    });
            },
            postCaregiver: (payload: {
                bearerToken: string;
                epostPatientNum: string;
                updatedCartObject: EasyRefillCartPayload[];
            }): Promise<any> => {
                return axiosClient
                    .post(
                        `${EASY_REFILL_API_URL}/EasyRefill/CompleteOrderCaregiver?epostPatientNumber=${payload.epostPatientNum}`,
                        payload.updatedCartObject,
                        {
                            headers: { Authorization: `Bearer ${payload.bearerToken}` }
                        }
                    )
                    .then((response) => response.data);
            }
        };
    }
}
