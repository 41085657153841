import { graphql, useStaticQuery } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { useSelector } from 'react-redux';

// UI components
import { getFilteredIntraPageMenuItems } from 'components/layouts/profile/profile.utils';
import DesktopMenu from 'components/navigation/desktop-menu/desktop-menu.component';
import MobileMenu from 'components/navigation/mobile-menu/mobile-menu.component';
// Props
import { NavigationProps, NavPath } from 'components/navigation/navigation.props';

// State
import {
    accountHasInsuranceSelector,
    accountIsMembershipSelector,
    accountProfileApiStatusSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { cartItemsTotalSelector, cartOrderPlacedSelector } from 'state/cart/cart.selectors';

// Utils
import { filterNavigationPaths } from 'util/navigation';

import { useApiStatus } from 'hooks/useApiStatus';

import './navigation.style.scss';

const Navigation = ({ isSecurePage, isLoggedIn, showMainMenu = true }: NavigationProps) => {
    const { t } = useTranslation();
    const data = MainMenusQuery();
    const profileObject = useSelector(accountProfileSelector);
    const hasInsurance = useSelector(accountHasInsuranceSelector);
    const cartItemsTotal = useSelector(cartItemsTotalSelector);
    const cartOrderPlaced = useSelector(cartOrderPlacedSelector);
    const { isLoading: isProfileLoading } = useApiStatus(accountProfileApiStatusSelector);
    const hasMembership = useSelector(accountIsMembershipSelector);
    const [isScrolled, setIsScrolled] = useState(false);
    const [cartTotal, setCartTotal] = useState(cartItemsTotal);
    const isProfileLoaded = profileObject !== undefined;
    const isCAResident = profileObject?.addresses?.find((address) => address.state === 'CA' && address.defaultShip);
    const isMembership = ENABLE_MEMBERSHIP ? hasMembership : false;
    const leftNavPaths = data.allMenuLinkContentMainLeft.nodes;
    const rightNavPaths = data.allMenuLinkContentMain.nodes;

    const getNavigationPaths = useCallback(
        (menuPaths: unknown) =>
            showMainMenu ? filterNavigationPaths(menuPaths as NavPath[], isSecurePage, isLoggedIn, isMembership) : [],
        [isMembership, isLoggedIn, isSecurePage, showMainMenu]
    );

    const rightMenuPaths: NavPath[] = useMemo(
        () => getNavigationPaths(rightNavPaths),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isProfileLoading, rightNavPaths]
    );

    const leftMenuPaths: NavPath[] = useMemo(
        () => getNavigationPaths(leftNavPaths),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isProfileLoading, leftNavPaths]
    );

    const intraPageMenuItems =
        isSecurePage || isLoggedIn
            ? getFilteredIntraPageMenuItems(
                  t,
                  isProfileLoaded,
                  hasInsurance,
                  !!isCAResident,
                  profileObject?.patientPlanAutoRefillFlag,
                  !!hasMembership
              )
            : undefined;

    const userName = profileObject ? profileObject.patientFirstName : '';

    useEffect(() => {
        setCartTotal(cartOrderPlaced ? 0 : cartItemsTotal);
    }, [cartItemsTotal, cartOrderPlaced]);

    const handleScroll = () => {
        //  if the window scrollY passes below the bottom of the navs
        if (window.scrollY >= 1) {
            setIsScrolled(true);
            return;
        }
        //  if the window scrollY passes above the bottom of the nav
        if (window.scrollY - 74 <= 0 && isScrolled) {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        if (window) {
            window.addEventListener('scroll', handleScroll);
        }
        return () => window.removeEventListener('scroll', handleScroll);
    });

    return (
        <Navbar expand="lg" sticky="top" className={`${isScrolled ? 'scrolled' : ''}`}>
            <DesktopMenu
                rightMenuPaths={rightMenuPaths}
                leftMenuPaths={leftMenuPaths}
                isScrolled={isScrolled}
                userName={userName}
                profileMenu={intraPageMenuItems}
                isSecurePage={isSecurePage}
                isLoggedIn={isLoggedIn}
                cartItemsTotal={cartTotal}
            />
            <MobileMenu
                rightMenuPaths={rightMenuPaths}
                leftMenuPaths={leftMenuPaths}
                isScrolled={isScrolled}
                userName={userName}
                profileMenu={intraPageMenuItems}
                isSecurePage={isSecurePage}
                isLoggedIn={isLoggedIn}
                cartItemsTotal={cartTotal}
            />
        </Navbar>
    );
};

export default Navigation;

export const MainMenusQuery = () => {
    return useStaticQuery(graphql`
        {
            allMenuLinkContentMain(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    isButton: field_menu_cta
                    link {
                        url
                    }
                    label: title
                    requiresAccountAuth: field_requires_account_auth
                    requiresMembership: field_requires_membership
                    isMobile: field_menu_only_mobile
                    buttonVariant: field_menu_button_variant
                    langcode
                }
            }
            allMenuLinkContentMainLeft(
                sort: { order: ASC, fields: weight }
                filter: { expanded: { eq: true }, enabled: { eq: true } }
            ) {
                nodes {
                    id
                    langcode
                    label: title
                    isButton: field_menu_cta
                    requiresAccountAuth: field_requires_account_auth
                    requiresMembership: field_requires_membership
                    link {
                        url
                    }
                    field_menu_body {
                        processed
                    }
                    children: childMenuLinkContent {
                        id
                        langcode
                        label: title
                        isButton: field_menu_cta
                        requiresAccountAuth: field_requires_account_auth
                        requiresMembership: field_requires_membership
                        link {
                            url
                        }
                        field_menu_body {
                            processed
                        }
                        # Adding parent_menu_link and weight into the query
                        # to ensure they are loaded into the schema, they are needed
                        # in the gatsby-node.js file to load menu hierarchy
                        menu_parent: parent_menu_link
                        weight
                    }
                    # Adding parent_menu_link and weight into the query
                    # to ensure they are loaded into the schema, they are needed
                    # in the gatsby-node.js file to load menu hierarchy
                    menu_parent: parent_menu_link
                    weight
                }
            }
        }
    `);
};
