import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';

import { accountFetchProfileRoutine } from 'state/account/account.routines';
import { accountProfilIsCaregiverSelector } from 'state/account/account.selectors';
import { familyProfileGetDependentsRoutine } from 'state/family-profile/family-profile.routines';

import {
    PaymentHistoryRequestPayload,
    PayPatientBalanceFamilyResponse,
    PostPatientBalanceFamilyPayload
} from 'types/payment-history';

import { TrackError } from 'util/google_optimize/optimize_helper';
import { baseEffectHandler } from 'util/sagas/sagas';

import { PaymentHistoryV2Response, PayPatientBalanceFamilyErrors } from './../../types/payment-history.d';
import { paymentsV2GetPaymentHistoryRoutine, paymentsV2PostPatientBalanceFamilyRoutine } from './payments.routines';
import PaymentsService from './payments.services';

export default function* paymentsSaga() {
    yield takeLatest(
        paymentsV2PostPatientBalanceFamilyRoutine.TRIGGER,
        function* (action: PayloadAction<PostPatientBalanceFamilyPayload>) {
            yield baseEffectHandler({
                service: PaymentsService.postPayPatientBalanceFamily().post,
                data: action.payload,
                *onResponse(data: Array<PayPatientBalanceFamilyResponse>) {
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                    yield put(paymentsV2PostPatientBalanceFamilyRoutine.success(data));
                    // Whenever the user successfully pays an amount due, it is mandatory
                    // to retrieve the fetch profile routine again, to update the values
                    // of the amount balance banner on the Medicine Cabinet and Membership Dashboard page.
                    yield put(accountFetchProfileRoutine.trigger());
                },
                *onError(error: PayPatientBalanceFamilyErrors) {
                    yield put(paymentsV2PostPatientBalanceFamilyRoutine.failure(error));
                    if (error.ErrorMessage) {
                        TrackError(
                            'payments.sagas.ts',
                            'paymentsV2PostPatientBalanceFamilyRoutine',
                            error.ErrorMessage
                        );
                        const regex = /REASON TEXT:(.*)/g;
                        const reason = error.ErrorMessage.match(regex);

                        if (reason) {
                            TrackError('account.sagas.ts', 'accountPayBalanceRoutineDetails', JSON.stringify(reason));
                        }
                    } else {
                        TrackError(
                            'payments.sagas.ts',
                            'paymentsV2PostPatientBalanceFamilyRoutine',
                            error?.SystemMessage ? error.SystemMessage : 'An unknown error occurred.'
                        );
                    }

                    // Tracking other errors for debugging prod issue with payments
                    const errorResponse = error;
                    if (errorResponse) {
                        const errorStatus = errorResponse.InError;
                        if (errorStatus) {
                            TrackError(
                                'payments.sagas.ts',
                                'accountPayBalanceRoutineDebugging',
                                `Error status: ${errorStatus}`
                            );
                        }

                        const errorMessage = errorResponse.ErrorMessage;
                        if (errorMessage) {
                            TrackError(
                                'payments.sagas.ts',
                                'accountPayBalanceRoutineDebugging',
                                `Error message: ${errorMessage}`
                            );
                        }

                        const errorDataMessage = errorResponse.SystemMessage;
                        if (errorDataMessage) {
                            TrackError(
                                'payments.sagas.ts',
                                'accountPayBalanceRoutineDebugging',
                                `Error data message: ${errorDataMessage}`
                            );
                        }
                    }

                    const { onFailure } = action.payload;
                    if (onFailure) onFailure(error.ErrorMessage ? error.ErrorMessage : error);
                }
            });
        }
    );

    yield takeLatest(
        paymentsV2GetPaymentHistoryRoutine.TRIGGER,
        function* (action: PayloadAction<PaymentHistoryRequestPayload>) {
            const isCaregiver: boolean | undefined = yield select(accountProfilIsCaregiverSelector);

            yield baseEffectHandler({
                service: PaymentsService.getPaymentHistory().v2,
                data: action.payload,
                *onResponse(data: PaymentHistoryV2Response) {
                    yield put(paymentsV2GetPaymentHistoryRoutine.success(data));
                    if (isCaregiver) {
                        yield put(familyProfileGetDependentsRoutine.trigger());
                    }
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                },
                *onError(error: any) {
                    yield put(paymentsV2GetPaymentHistoryRoutine.failure(error));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            });
        }
    );
}
