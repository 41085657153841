// General
import { useLocation } from '@reach/router';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { BIRDI_IP_ADDRESSES, ENABLE_MAINTENANCE_MODE } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Ui-kit
import Spinner from 'ui-kit/spinner/spinner';

// Components
import AcknowledgementModal from 'components/acknowledgement/acknowledgement-modal/acknowledgement-modal';
import AutoRefillBody from 'components/auto-refill-body/auto-refill-body';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

// State
import {
    accountFetchProfileRoutine,
    accountGetUserIpAddressRoutine,
    accountSetAcknowledgementRoutine
} from 'state/account/account.routines';
import {
    accountAcknowledgementSelector,
    accountAutoRefillEligibleSelector,
    accountHasInsuranceSelector,
    accountHIPAASelector,
    accountIsCaliforniaUserSelector,
    accountIsLoggedInSelector,
    accountProfileApiStatusSelector,
    accountProfileSelector,
    accountUserIpAddressSelector
} from 'state/account/account.selectors';
import { AcknowledgementPayload } from 'state/account/account.services';
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import { getCartRoutine } from 'state/cart/cart.routines';
import { cartApiStatusSelector, cartOrderPlacedSelector } from 'state/cart/cart.selectors';
import { familyProfileGetDependentsRoutine } from 'state/family-profile/family-profile.routines';
import { dependentsApiStatusSelector } from 'state/family-profile/family-profile.selectors';
import { medicineCabinetLoadRoutine } from 'state/medicine-cabinet/medicine-cabinet.routines';
import { medicineShowNewPrescriptionModalSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

// Const
import { getClientIp } from 'const/options';

// Utils
import { validateAutoRefillModalDisplay } from 'util/autorefill';
import storageHelper from 'util/storageHelper';

// Hooks
import { useApiStatus } from 'hooks/useApiStatus';
import { useGlobalLink } from 'hooks/useGlobalLink';
import useSecurePage from 'hooks/useSecurePage';

export interface WithSecurityProps {
    isLoggedIn: boolean;
    isSecurePage: boolean;
}

interface TrackingPayload {
    event: string;
    logged_in: boolean;
    user_type?: 'funded' | 'cash-card' | null;
}

const withSecurity =
    <P extends object>(Component: React.ComponentType<P>) =>
    (props: P) => {
        // Hooks
        const location = useLocation();
        const dispatch = useDispatch();
        const { t } = useTranslation();
        const { isSecurePage } = useSecurePage();
        const globalLink = useGlobalLink();
        const { handleSignOut } = globalLink;
        // Selectors
        const isLoggedIn = useSelector(accountIsLoggedInSelector);
        const profileObject = useSelector(accountProfileSelector);
        const { isIdle: isIdleProfile } = useApiStatus(accountProfileApiStatusSelector);
        const { isIdle: isIdleDependents } = useApiStatus(dependentsApiStatusSelector);
        const { isSuccess: isSuccessCart } = useApiStatus(cartApiStatusSelector);
        const accountHasInsurance = useSelector(accountHasInsuranceSelector);
        const newPrescriptionModal = useSelector(medicineShowNewPrescriptionModalSelector);
        const accountAcknowledgement: AcknowledgementPayload | undefined = useSelector(accountAcknowledgementSelector);
        const accountHIPAA: boolean | undefined = useSelector(accountHIPAASelector);
        const accountUserIpAddress = useSelector(accountUserIpAddressSelector);
        const isOrderPlaced = useSelector(cartOrderPlacedSelector);
        const isAutoRefillEligible = useSelector(accountAutoRefillEligibleSelector);
        const isCAResident = useSelector(accountIsCaliforniaUserSelector);

        const closeModalHandler = () => {
            dispatch(closeModal({}));
            storageHelper.local.setAutoRefillToggleFlag();
        };

        const dispatchAutoRefillModal = () =>
            dispatch(
                openModal({
                    showClose: true,
                    className: 'scroll-modal',
                    onClose: closeModalHandler,
                    bodyContent: (
                        <BirdiModalContent
                            icon="none"
                            title={t(`pages.autoRefill.userVerification.title`)}
                            body={<AutoRefillBody isModal onClose={closeModalHandler} />}
                        />
                    ),
                    ctas: []
                })
            );

        const pageData = useStaticQuery(graphql`
            query {
                termsOfService: allNodeLandingPage(filter: { path: { alias: { eq: "/website-terms-of-use" } } }) {
                    nodes {
                        drupal_internal__vid
                    }
                }
            }
        `);

        const currentVID = pageData?.termsOfService ? pageData?.termsOfService.nodes[0]?.drupal_internal__vid / 100 : 0;

        const showAcknowledgmentModal = (acceptPayload: unknown) => {
            const shouldDisplayAutoRefillModal = validateAutoRefillModalDisplay(isAutoRefillEligible, isCAResident);

            dispatch(
                openModal({
                    className: 'acknowledgement-modal-content',
                    showClose: false,
                    bodyContent: <AcknowledgementModal />,
                    backdrop: 'static',
                    keyboard: false, // disable "ESC" close function
                    ctas: [
                        {
                            async: true,
                            label: t('components.acknowledgement.labels.agree'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(setBusyModal(true));
                                dispatch(
                                    accountSetAcknowledgementRoutine.trigger({
                                        data: acceptPayload,
                                        onSuccess: () => {
                                            dispatch(setBusyModal(false));
                                            dispatch(closeModal({}));
                                            if (shouldDisplayAutoRefillModal && !newPrescriptionModal.show) {
                                                dispatchAutoRefillModal();
                                            }
                                        },
                                        onError: () => {
                                            dispatch(
                                                openModal({
                                                    showClose: false,
                                                    type: 'danger',
                                                    size: 'lg',
                                                    backdrop: 'static',
                                                    keyboard: false, // disable "ESC" close function
                                                    onClose: () => {
                                                        handleSignOut();
                                                        dispatch(closeModal({}));
                                                    },
                                                    headerContent: (
                                                        <BirdiModalHeaderDanger
                                                            headerText={t('components.membershipModals.errorTitle')}
                                                            icon="alert"
                                                        />
                                                    ),
                                                    bodyContent: (
                                                        <BirdiModalContentAlt
                                                            subTitle={t('registration.errors.modals.default.body')}
                                                        />
                                                    ),
                                                    ctas: []
                                                })
                                            );
                                        }
                                    })
                                );
                            },
                            dataGALocation: 'AcknowledgementModal'
                        },
                        {
                            label: t('components.acknowledgement.labels.decline'),
                            variant: 'text',
                            onClick: () => {
                                handleSignOut();
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'AcknowledgementModal'
                        }
                    ]
                })
            );
        };

        /**
         * Since this HOC works as a decorator encompassing all security pages, the API call should only
         * be made here to prevent redundancy and multiple calls to the Profile API. Therefore, after
         * profile verification, we transfer the authentication page logic to this HOC to separate
         * responsibilities and remove the firstLogin prop from local storage.
         */
        useEffect(() => {
            if (isLoggedIn && isIdleProfile && profileObject === undefined) {
                dispatch(
                    accountFetchProfileRoutine.trigger({
                        onSuccess: () => {
                            dispatch(medicineCabinetLoadRoutine.trigger({ fetchRxSubStatus: true }));
                        }
                    })
                );
            }
        }, [dispatch, isLoggedIn, isIdleProfile, profileObject]);

        useEffect(() => {
            if (ENABLE_MAINTENANCE_MODE && !accountUserIpAddress) {
                dispatch(accountGetUserIpAddressRoutine.trigger());
            }
        }, [accountUserIpAddress, dispatch]);

        useEffect(() => {
            if (typeof window !== 'undefined' && window.dataLayer) {
                const trackingPayload: TrackingPayload = {
                    event: 'pageview',
                    logged_in: isLoggedIn
                };

                if (!isLoggedIn) {
                    window.dataLayer.push(trackingPayload);
                }
                if (isLoggedIn && profileObject !== undefined) {
                    trackingPayload.user_type = isLoggedIn ? (accountHasInsurance ? 'funded' : 'cash-card') : null;
                    window.dataLayer.push(trackingPayload);
                }
            }
        }, [isLoggedIn, accountHasInsurance, profileObject]);

        // Since we've encapsulated the pages with this higher-order component,
        // we've shifted the API call logic to execute just once upon user login.
        // This approach helps us prevent redundant API calls within the components themselves.
        useEffect(() => {
            if (profileObject?.isCaregiver && isIdleDependents) {
                dispatch(familyProfileGetDependentsRoutine.trigger());
            }
        }, [dispatch, profileObject, isIdleDependents]);

        useEffect(() => {
            if (profileObject !== undefined && isSuccessCart && !isOrderPlaced) {
                dispatch(getCartRoutine.trigger());
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dispatch, profileObject, isOrderPlaced]);

        useEffect(() => {
            if (isLoggedIn && profileObject && accountAcknowledgement !== undefined && accountHIPAA !== undefined) {
                let acknowledgementVersion = 0;
                if (accountAcknowledgement.Acknowledgements && accountAcknowledgement.Acknowledgements.length > 0) {
                    acknowledgementVersion = accountAcknowledgement.Acknowledgements[0].Version
                        ? accountAcknowledgement.Acknowledgements[0].Version
                        : 0;
                }
                const acceptedHIPAA = accountHIPAA ? accountHIPAA : false;
                if (!acknowledgementVersion || currentVID > acknowledgementVersion || !acceptedHIPAA) {
                    getClientIp().then((res) => {
                        showAcknowledgmentModal({
                            patientId: profileObject.epostPatientNum,
                            clientIP: res.toString(),
                            currentVID: currentVID > acknowledgementVersion ? currentVID : undefined
                        });
                    });
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isLoggedIn, profileObject, accountAcknowledgement, accountHIPAA]);

        const shouldRedirectToMaintenancePage = useMemo(() => {
            return (
                ENABLE_MAINTENANCE_MODE &&
                typeof window !== 'undefined' &&
                window.location.pathname !== '/maintenance' &&
                typeof BIRDI_IP_ADDRESSES === 'object' &&
                !!accountUserIpAddress &&
                !BIRDI_IP_ADDRESSES.includes(accountUserIpAddress)
            );
        }, [accountUserIpAddress]);

        if (shouldRedirectToMaintenancePage) {
            navigate('/maintenance');
            return null;
        } else if (isSecurePage && !isLoggedIn && typeof window !== 'undefined') {
            // Check if dataLayer exists
            const shouldRedirect = window.dataLayer?.some(
                (logout) => logout.event === 'logout' && logout.loginStatus === 'logged out'
            );

            if (shouldRedirect) {
                // Redirect based on logout event
                navigate('/sign-in');
            } else {
                // Default redirect with query params
                navigate('/sign-in?redirect=' + location.pathname + encodeURIComponent(location.search));
            }

            return null;
        } else {
            const hideSpinner: boolean = !isSecurePage || (isSecurePage && isLoggedIn && profileObject !== undefined);
            return (
                <>
                    <Spinner isVisible={!hideSpinner} t={t} />
                    <Component {...props} isSecurePage={isSecurePage} isLoggedIn={isLoggedIn} />
                </>
            );
        }
    };

export default withSecurity;
