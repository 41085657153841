import crypto from 'crypto-browserify';

import AddTransferPrescriptionService, {
    PharmacyPayload,
    PrescriberPayload,
    TransferPrescriptionPayload
} from 'state/add-transfer-prescription/add-transfer-prescription.services';

import type { RefillRxs } from 'types/order-prescription';

import { setDefaultHeader } from './axiosClient';

const authTokenLocalStorageKey = 'Authorization';
const expiresLocalStorageKey = 'expires';
const issuedLocalStorageKey = 'issued';
const sessionExpiresLocalStorageKey = 'sessionExpires';
const clientIP = 'clientIP';
const acknowledged = 'acknowledged';
const verified = 'verified';
const tosVID = 'tosVID';
const selectedDrug = 'selectedDrug';
const sessionStorage = (typeof window !== 'undefined' && window.sessionStorage) || undefined;
const prescriptionFlowType = 'prescriptionFlowType';
const savedPrescribers = 'savedPrescribers';
const savedPharmacies = 'savedPharmacies';
const rememberMeEmailStorageKey = 'email';
const rememberMeCheckboxKey = 'rememberMe';
const telemedicineAsyncModalDismiss = 'telemedicineAsyncModal';
const discountCardRecentSearchKey = 'discountCardRecentSearches';
const confirmEmailKey = 'confirmEmail';
const resendEmailCountdownKey = 'resendEmailCountdown';

type caregiverDependentId = string | number;
type epostPatientNumber = string | number;
type communicationMethod = string;
export interface selectedDrugObject {
    drugName: string;
    drugForm: string;
    strength: string;
    qty: string;
    genericProductCode: string;
    packageDisplay: string;
    packageOption: string;
    strengthWithPackage: string;
    price: string;
    description: string;
    gpi: string;
    ndc: string;
    drugDisplayName?: string;
    rxNumber?: string;
}

/**
 * Helper function to create an unique cookie name containing the
 * account epost number, the caregiver dependent id and the
 * caregiver epost number.
 *
 * We are combining these three elements to be possible to store the
 * selected communication type of a dependent (dependent id plus
 * dependent epost number) inside each account (account epost number);
 *
 * @param accountEpostNumber mixed string | number
 * @param caregiverDependentId mixed string | number
 * @param epostPatientNumber string
 * @returns string
 */
const dependentSendInvitationCookieName = (
    accountEpostNumber: epostPatientNumber,
    caregiverDependentId: caregiverDependentId,
    epostPatientNumber: epostPatientNumber
): string => {
    // Prefix means: Dependent Send Invitation Cookie Name
    return (
        '__dsiCN_' +
        crypto
            .createHash('sha1')
            .update(`${accountEpostNumber}${caregiverDependentId}${epostPatientNumber}`)
            .digest('hex')
    );
};

const getCookie = (name: string) => {
    if (typeof document !== 'undefined') {
        const cookieArr = document.cookie.split(';');
        for (let i = 0; i < cookieArr.length; i++) {
            const cookiePair = cookieArr[i].split('=');
            if (name === cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }
    }
    return null;
};
const setCookie = (name: string, value: string | boolean, daysToLive: number | undefined) => {
    if (typeof document !== 'undefined') {
        let cookie = name + '=' + encodeURIComponent(value);
        if (typeof daysToLive === 'number') {
            cookie += '; max-age=' + daysToLive * 24 * 60 * 60;
            cookie += '; Path=/';
            document.cookie = cookie;
        }
    }
    return null;
};

const removeCookie = (name: string) => {
    if (typeof document !== 'undefined') {
        document.cookie = name + '=; Max-Age=-1; Path=/';
    }
};

const storageHelper = {
    cookies: {
        setCookieAccepted: () => setCookie('cookieAccepted', true, 365),
        getCookieAccepted: () => getCookie('cookieAccepted'),
        setBasicAuthCookie: (value: string) => setCookie('__cmsba4fe', value, 360),
        getBasicAuthCookie: () => getCookie('__cmsba4fe'),
        setEasyAccessCookie: (value: string) => setCookie('__earls4tu', value, 360),
        getEasyAccessCookie: () => getCookie('__earls4tu'),
        setDependentSendInvitationMethodCookie: (
            accountEpostNumber: epostPatientNumber,
            caregiverDependentId: caregiverDependentId,
            epostPatientNumber: epostPatientNumber,
            communicationMethod: communicationMethod
        ) =>
            setCookie(
                dependentSendInvitationCookieName(accountEpostNumber, caregiverDependentId, epostPatientNumber),
                communicationMethod,
                360
            ),
        getDependentSendInvitationMethodCookie: (
            accountEpostNumber: epostPatientNumber,
            caregiverDependentId: caregiverDependentId,
            epostPatientNumber: epostPatientNumber
        ) => getCookie(dependentSendInvitationCookieName(accountEpostNumber, caregiverDependentId, epostPatientNumber)),
        setHasOrderEasyRefillPlaced: () => setCookie('hasEasyRefillOrder', true, 1),
        getHasOrderEasyRefillPlaced: () => getCookie('hasEasyRefillOrder'),
        removeHasOrderEasyRefillPlaced: () => removeCookie('hasEasyRefillOrder')
    },
    local: {
        setAutoRefillToggleFlag: () =>
            localStorage.setItem(
                'autoRefillToggleModal',
                JSON.stringify({
                    expiration: new Date(new Date().setDate(new Date().getDate() + 90))
                })
            ),
        getAutoRefillToggleFlag: () => JSON.parse(localStorage.getItem('autoRefillToggleModal') || '{}'),
        getNewRxs: () => JSON.parse(localStorage.getItem('newRxsDisplayed') || '{}'),
        setExpiredInviteClosedFlag: (invitationId: number) =>
            localStorage.setItem(
                'membershipExpiredInviteIsClosed',
                JSON.stringify({
                    invitationId
                })
            ),
        getExpiredInviteClosedFlag: () => JSON.parse(localStorage.getItem('membershipExpiredInviteIsClosed') || '{}'),
        setCurrentDrugInfo: (currentDrug: object) => {
            localStorage.setItem('currentDrug', JSON.stringify(currentDrug));
        },
        getCurrentDrugInfo: () => localStorage.getItem('currentDrug'),
        removeCurrentDrugInfo: () => localStorage.removeItem('currentDrug'),
        setTelemedicineAsyncModalDismiss: () => localStorage?.setItem(telemedicineAsyncModalDismiss, 'true'),
        getTelemedicineAsyncModalDismiss: () => {
            const telemedicineAsyncModalDismissValue = localStorage?.getItem(telemedicineAsyncModalDismiss);
            return telemedicineAsyncModalDismissValue === 'true';
        },
        getEmailConfirmation: () => localStorage?.getItem(confirmEmailKey),
        setEmailConfirmation: (emailAddress: string) => {
            localStorage?.setItem(confirmEmailKey, emailAddress);
        },
        removeEmailConfirmation: () => {
            localStorage?.removeItem(confirmEmailKey);
        },
        getResendCountdown: () => localStorage?.getItem(resendEmailCountdownKey),
        setResendCountdown: (updatedCountdown: number) => {
            localStorage?.setItem(resendEmailCountdownKey, updatedCountdown.toString());
        },
        removeResendCountdown: () => {
            localStorage?.removeItem(resendEmailCountdownKey);
        }
    },
    session: {
        getAuthToken: () => sessionStorage?.getItem(authTokenLocalStorageKey),
        getAuthExpires: () => Number(sessionStorage?.getItem(expiresLocalStorageKey)),
        getAuthIssued: () => Number(sessionStorage?.getItem(issuedLocalStorageKey)),
        getAuthSessionExpires: () => Number(sessionStorage?.getItem(sessionExpiresLocalStorageKey)),
        setAuthToken: (token: string, issued: string, expires: string, sessionExpires: string) => {
            sessionStorage?.setItem(authTokenLocalStorageKey, token);
            sessionStorage?.setItem(expiresLocalStorageKey, expires);
            sessionStorage?.setItem(issuedLocalStorageKey, issued);
            sessionStorage?.setItem(sessionExpiresLocalStorageKey, sessionExpires);
            setDefaultHeader('Authorization', `Bearer ${token}`);
        },
        removeAuthToken: () => {
            if (sessionStorage) {
                sessionStorage.removeItem(authTokenLocalStorageKey);
                sessionStorage.removeItem(expiresLocalStorageKey);
                sessionStorage.removeItem(issuedLocalStorageKey);
                sessionStorage.removeItem(sessionExpiresLocalStorageKey);
                sessionStorage.removeItem(clientIP);
                sessionStorage.removeItem(acknowledged);
                sessionStorage.removeItem(verified);
            }
            setDefaultHeader('Authorization', ``);
        },
        getTosVID: () => sessionStorage?.getItem(tosVID),
        setTosVID: (version: string) => {
            sessionStorage?.setItem(tosVID, version);
        },
        getClientIP: () => sessionStorage?.getItem(clientIP),
        setClientIP: (ip: string) => {
            sessionStorage?.setItem(clientIP, ip);
        },
        getAcknowledged: () => sessionStorage?.getItem(acknowledged),
        setAcknowledged: (hasAcknowledged: boolean) => {
            sessionStorage?.setItem(acknowledged, hasAcknowledged.toString());
        },
        getVerified: () => sessionStorage?.getItem(verified),
        setVerified: (hasVerified: boolean) => {
            sessionStorage?.setItem(verified, hasVerified.toString());
        },
        setAuthSessionExpires: (expiration: string) => {
            sessionStorage?.setItem(sessionExpiresLocalStorageKey, expiration);
        },
        getSelectedDrug: () => {
            const drug = sessionStorage?.getItem(selectedDrug);
            let drugObject: selectedDrugObject = {
                drugName: '',
                drugForm: '',
                strength: '',
                qty: '',
                genericProductCode: '',
                packageDisplay: '',
                packageOption: '',
                strengthWithPackage: '',
                price: '',
                description: '',
                gpi: '',
                ndc: ''
            };

            if (drug) {
                drugObject = JSON.parse(drug);
            }

            return drugObject;
        },
        setSelectedDrug: (drugObject: selectedDrugObject) => {
            let drug = '';
            if (drugObject) {
                drug = JSON.stringify(drugObject);
            }
            sessionStorage?.setItem(selectedDrug, drug);
        },
        removeSelectedDrug: () => {
            sessionStorage?.removeItem(selectedDrug);
        },
        setPrescriptionFlowType: (flowType: string) => {
            sessionStorage?.setItem(prescriptionFlowType, flowType);
        },
        getPrescriptionFlowType: () => sessionStorage?.getItem(prescriptionFlowType),
        removePrescriptionFlowType: () => {
            sessionStorage?.removeItem(prescriptionFlowType);
        },
        setSubmittedPrescriber: (transferObject: TransferPrescriptionPayload) => {
            const prescriber = AddTransferPrescriptionService.extractPrescriberData(transferObject);
            let foundPrescriber = null;
            let storedPrescribers = storageHelper.session.getSubmittedPrescribers();
            if (storedPrescribers !== undefined) {
                // Make sure the prescriber isn't already in the array;
                const prescriberJSON = JSON.stringify(prescriber);
                foundPrescriber = storedPrescribers.find((savedPrescriber: RefillRxs) => {
                    return JSON.stringify(savedPrescriber) === prescriberJSON;
                });
            } else {
                storedPrescribers = [];
            }
            if (!foundPrescriber) {
                storedPrescribers.push(prescriber);
            }
            sessionStorage?.setItem(savedPrescribers, JSON.stringify(storedPrescribers));
        },
        removePrescribers: () => {
            sessionStorage?.removeItem(savedPrescribers);
        },
        getSubmittedPrescribers: (): PrescriberPayload[] => {
            const prescribers = sessionStorage?.getItem(savedPrescribers);
            return prescribers ? JSON.parse(prescribers) : [];
        },
        setSubmittedPharmacy: (transferObject: TransferPrescriptionPayload) => {
            const pharmacy: PharmacyPayload = AddTransferPrescriptionService.extractPharmacyData(transferObject);
            let foundPharmacy = null;
            let storedPharmacy = storageHelper.session.getSubmittedPharmacies();
            if (storedPharmacy !== undefined) {
                // Make sure the pharmacy isn't already in the array;
                const pharmacyJSON = JSON.stringify(pharmacy);
                foundPharmacy = storedPharmacy.find((savedPharmacy) => {
                    return JSON.stringify(savedPharmacy) === pharmacyJSON;
                });
            } else {
                storedPharmacy = [];
            }
            if (!foundPharmacy && pharmacy.PharmacyName) {
                storedPharmacy.push(pharmacy);
            }
            sessionStorage?.setItem(savedPharmacies, JSON.stringify(storedPharmacy));
        },
        getSubmittedPharmacies: (): PharmacyPayload[] => {
            const pharmacies = sessionStorage?.getItem(savedPharmacies);
            return pharmacies ? JSON.parse(pharmacies) : [];
        },
        removePharmacies: () => {
            sessionStorage?.removeItem(savedPharmacies);
        },
        setRememberMe: (email: string, rememberMe: string) => {
            sessionStorage?.setItem(rememberMeEmailStorageKey, email);
            sessionStorage?.setItem(rememberMeCheckboxKey, rememberMe);
        },
        removeRememberMe: () => {
            sessionStorage?.removeItem(rememberMeEmailStorageKey);
            sessionStorage?.removeItem(rememberMeCheckboxKey);
        },
        getRememberMeEmail: (defaultValue: string): string => {
            const emailStored = sessionStorage?.getItem(rememberMeEmailStorageKey);
            return emailStored || defaultValue;
        },
        getRememberMeCheckBox: (): boolean => {
            const checkboxStored = sessionStorage?.getItem(rememberMeCheckboxKey);
            return checkboxStored === 'true' ? true : false;
        },
        getDiscountCardRecentSearches: (): string[] => {
            return JSON.parse(sessionStorage?.getItem(discountCardRecentSearchKey) || '[]');
        },
        setDiscountCardRecentSearches: (drugName: string, shouldRemoveItem = false) => {
            if (typeof drugName !== 'string') return;

            const recentSearches = JSON.parse(sessionStorage?.getItem(discountCardRecentSearchKey) || '[]');

            if (shouldRemoveItem) {
                const filteredSearches = recentSearches.filter((search: string) => search !== drugName);
                sessionStorage?.setItem(discountCardRecentSearchKey, JSON.stringify(filteredSearches));
                return;
            }

            if (!recentSearches.includes(drugName)) {
                recentSearches.push(drugName);
                if (recentSearches.length > 5) recentSearches.shift();
                sessionStorage?.setItem(discountCardRecentSearchKey, JSON.stringify(recentSearches));
            }
        },
        removeDiscountCardRecentSearches: () => {
            sessionStorage?.removeItem(discountCardRecentSearchKey);
        }
    }
};

export default storageHelper;
