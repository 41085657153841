import publicIp from 'public-ip';

export const getDaysOfMonthOptions = () => {
    const options = [];

    for (let i = 1; i <= 31; i++) {
        options.push({
            key: i.toString(),
            label: i.toString(),
            value: i
        });
    }

    return options;
};
export const invoiceMinDate = '2020-01-01';
export const monthOptions = [
    {
        key: 'January',
        label: 'January',
        value: 'January'
    },
    {
        key: 'February',
        label: 'February',
        value: 'February'
    },
    {
        key: 'March',
        label: 'March',
        value: 'March'
    },
    {
        key: 'April',
        label: 'April',
        value: 'April'
    },
    {
        key: 'May',
        label: 'May',
        value: 'May'
    },
    {
        key: 'June',
        label: 'June',
        value: 'June'
    },
    {
        key: 'July',
        label: 'July',
        value: 'July'
    },
    {
        key: 'August',
        label: 'August',
        value: 'August'
    },
    {
        key: 'September',
        label: 'September',
        value: 'September'
    },
    {
        key: 'October',
        label: 'October',
        value: 'October'
    },
    {
        key: 'November',
        label: 'November',
        value: 'November'
    },
    {
        key: 'December',
        label: 'December',
        value: 'December'
    }
];

export const creditCardMonthOptions = [
    {
        key: 'January',
        label: '01 - January',
        value: 'January'
    },
    {
        key: 'February',
        label: '02 - February',
        value: 'February'
    },
    {
        key: 'March',
        label: '03 - March',
        value: 'March'
    },
    {
        key: 'April',
        label: '04 - April',
        value: 'April'
    },
    {
        key: 'May',
        label: '05 - May',
        value: 'May'
    },
    {
        key: 'June',
        label: '06 - June',
        value: 'June'
    },
    {
        key: 'July',
        label: '07 - July',
        value: 'July'
    },
    {
        key: 'August',
        label: '08 - August',
        value: 'August'
    },
    {
        key: 'September',
        label: '09 - September',
        value: 'September'
    },
    {
        key: 'October',
        label: '10 - October',
        value: 'October'
    },
    {
        key: 'November',
        label: '11 - November',
        value: 'November'
    },
    {
        key: 'December',
        label: '12 - December',
        value: 'December'
    }
];

export const getYearOptions = ({ min }: { min: number }) => {
    const currentYear = new Date().getFullYear();
    const options = [];

    for (let i = min; i <= currentYear; i++) {
        options.push({
            key: i.toString(),
            label: i.toString(),
            value: i
        });
    }

    return options.reverse();
};

export const LIMITE_AGE_DETERMINE_IS_MINOR = 18;

export const isUserUnderAgeLimit = (patientDob: string) => {
    return getAge(new Date(patientDob)) < LIMITE_AGE_DETERMINE_IS_MINOR;
};

export const getAge = (birthDate: Date) => {
    const today = new Date();

    const yearsDifference = today.getUTCFullYear() - birthDate.getUTCFullYear();
    const oneOrZero =
        today.getUTCMonth() < birthDate.getUTCMonth() ||
        (today.getUTCMonth() === birthDate.getUTCMonth() && today.getUTCDate() < birthDate.getUTCDate())
            ? 1
            : 0;
    return yearsDifference - oneOrZero;
};

export const getExpYears = ({ dates }: { dates: number }) => {
    const currentYear = new Date().getFullYear();
    const max = currentYear + dates;
    const years = [];

    for (let i = currentYear; i <= max; i++) {
        years.push({
            key: i,
            label: i,
            value: i
        });
    }

    return years;
};

export const addressTypeOptions = [
    {
        key: 'HOME',
        label: 'HOME',
        value: 'HOME',
        typeNum: '1'
    },
    {
        key: 'TEMPORARY',
        label: 'TEMPORARY',
        value: 'TEMPORARY',
        typeNum: '5'
    }
];

export const stateOptions = [
    { key: 'Alabama', label: 'Alabama', value: 'AL' },
    { key: 'Alaska', label: 'Alaska', value: 'AK' },
    { key: 'Arizona', label: 'Arizona', value: 'AZ' },
    { key: 'Arkansas', label: 'Arkansas', value: 'AR' },
    { key: 'California', label: 'California', value: 'CA' },
    { key: 'Colorado', label: 'Colorado', value: 'CO' },
    { key: 'Connecticut', label: 'Connecticut', value: 'CT' },
    { key: 'Delaware', label: 'Delaware', value: 'DE' },
    { key: 'District of Columbia', label: 'District of Columbia', value: 'DC' },
    { key: 'Florida', label: 'Florida', value: 'FL' },
    { key: 'Georgia', label: 'Georgia', value: 'GA' },
    { key: 'Guam', label: 'Guam', value: 'GU' },
    { key: 'PR', label: 'Puerto Rico', value: 'PR' },
    { key: 'Hawaii', label: 'Hawaii', value: 'HI' },
    { key: 'Idaho', label: 'Idaho', value: 'ID' },
    { key: 'Illinois', label: 'Illinois', value: 'IL' },
    { key: 'Indiana', label: 'Indiana', value: 'IN' },
    { key: 'Iowa', label: 'Iowa', value: 'IA' },
    { key: 'Kansas', label: 'Kansas', value: 'KS' },
    { key: 'Kentucky', label: 'Kentucky', value: 'KY' },
    { key: 'Louisiana', label: 'Louisiana', value: 'LA' },
    { key: 'Maine', label: 'Maine', value: 'ME' },
    { key: 'Maryland', label: 'Maryland', value: 'MD' },
    { key: 'Massachusetts', label: 'Massachusetts', value: 'MA' },
    { key: 'Michigan', label: 'Michigan', value: 'MI' },
    { key: 'Minnesota', label: 'Minnesota', value: 'MN' },
    { key: 'Mississippi', label: 'Mississippi', value: 'MS' },
    { key: 'Missouri', label: 'Missouri', value: 'MO' },
    { key: 'Montana', label: 'Montana', value: 'MT' },
    { key: 'Nebraska', label: 'Nebraska', value: 'NE' },
    { key: 'Nevada', label: 'Nevada', value: 'NV' },
    { key: 'New Hampshire', label: 'New Hampshire', value: 'NH' },
    { key: 'New Jersey', label: 'New Jersey', value: 'NJ' },
    { key: 'New Mexico', label: 'New Mexico', value: 'NM' },
    { key: 'New York', label: 'New York', value: 'NY' },
    { key: 'North Carolina', label: 'North Carolina', value: 'NC' },
    { key: 'North Dakota', label: 'North Dakota', value: 'ND' },
    { key: 'Ohio', label: 'Ohio', value: 'OH' },
    { key: 'Oklahoma', label: 'Oklahoma', value: 'OK' },
    { key: 'Oregon', label: 'Oregon', value: 'OR' },
    { key: 'Pennsylvania', label: 'Pennsylvania', value: 'PA' },
    { key: 'Rhode Island', label: 'Rhode Island', value: 'RI' },
    { key: 'South Carolina', label: 'South Carolina', value: 'SC' },
    { key: 'South Dakota', label: 'South Dakota', value: 'SD' },
    { key: 'Tennessee', label: 'Tennessee', value: 'TN' },
    { key: 'Texas', label: 'Texas', value: 'TX' },
    { key: 'Utah', label: 'Utah', value: 'UT' },
    { key: 'Vermont', label: 'Vermont', value: 'VT' },
    { key: 'Virginia', label: 'Virginia', value: 'VA' },
    { key: 'Washington', label: 'Washington', value: 'WA' },
    { key: 'West Virginia', label: 'West Virginia', value: 'WV' },
    { key: 'Wisconsin', label: 'Wisconsin', value: 'WI' },
    { key: 'Wyoming', label: 'Wyoming', value: 'WY' }
];

export const messageOptions = [
    {
        key: 'Contact Us',
        label: 'Contact Us',
        value: 'Contact Us'
    },
    {
        key: 'Ask The Pharmacist',
        label: 'Ask the Pharmacist',
        value: 'Ask The Pharmacist'
    }
];

export const notificationOptions = [
    {
        key: 'Do Not Notify',
        label: 'Do Not Notify',
        value: 'none'
    },
    {
        key: 'Email',
        label: 'Emails',
        value: 'Email'
    },
    {
        key: 'Phone',
        label: 'Automated Calls',
        value: 'Phone'
    },
    {
        key: 'Text',
        label: 'SMS Text Messages',
        value: 'Text'
    }
];

export const marketingNotificationOptions = [
    {
        key: 'Do Not Notify',
        label: 'Do Not Notify',
        value: 'none'
    },
    {
        key: 'Email',
        label: 'Emails',
        value: 'Email'
    }
];

export const getClientIp = async () => await publicIp.v4();

export const monthOptionsNumberValue = [
    {
        key: 'January',
        label: 'January',
        value: '01'
    },
    {
        key: 'February',
        label: 'February',
        value: '02'
    },
    {
        key: 'March',
        label: 'March',
        value: '03'
    },
    {
        key: 'April',
        label: 'April',
        value: '04'
    },
    {
        key: 'May',
        label: 'May',
        value: '05'
    },
    {
        key: 'June',
        label: 'June',
        value: '06'
    },
    {
        key: 'July',
        label: 'July',
        value: '07'
    },
    {
        key: 'August',
        label: 'August',
        value: '08'
    },
    {
        key: 'September',
        label: 'September',
        value: '09'
    },
    {
        key: 'October',
        label: 'October',
        value: '10'
    },
    {
        key: 'November',
        label: 'November',
        value: '11'
    },
    {
        key: 'December',
        label: 'December',
        value: '12'
    }
];

export const defineMessageAuthor = (author: string) => {
    const dateMatcher = /\(\d{2}\/\d{2}\/\d{2,4}\)/g;
    const parsedMessage = `${author.toLowerCase().replace(/\b[a-z](?=[a-z])/g, (char) => char.toUpperCase())}`;

    if (author.length) {
        if (author.match(dateMatcher)) {
            return parsedMessage.replace(dateMatcher, '');
        }

        return parsedMessage;
    }

    return null;
};

export const getMonthNumberFromName = (monthName: string) => {
    const year = new Date().getFullYear();
    const monthNumber = new Date(`${monthName} 1, ${year}`).getMonth() + 1;
    return String(monthNumber).padStart(2, '0');
};

export const formatDate = (originalDate: string) => {
    const [month, day, year] = originalDate.split(' ');

    return `${getMonthNumberFromName(month)}/${day?.replace(/\D+/g, '')}/${year}`;
};
