import { useFormik } from 'formik';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CheckboxComponent from 'ui-kit-v2/checkbox/checkbox';
import DropdownComponent from 'ui-kit-v2/dropdown/dropdown';
import { toDropdownOption } from 'ui-kit-v2/dropdown/dropdown.props';
import Icon from 'ui-kit-v2/icon/icon';
import TextInputComponent from 'ui-kit-v2/text-input/text-input';
import { Text } from 'ui-kit-v2/text/text';
import { Tooltip } from 'ui-kit-v2/tooltip/tooltip';

import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import { ccBrands } from 'ui-kit/icons/creditcard-icon/creditcard-icon.props';
import ToastBox from 'ui-kit/toast-box/toast-box';

import { ProfileAddPaymentObjectPayload } from 'state/account/account.services';

import { BREAKPOINTS } from 'const/breakpoints';
import { getExpYears, monthOptionsNumberValue } from 'const/options';

import { PAYMENT_SCHEMA_V3 } from 'schema/payment';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { PaymentFormHandle, PaymentProps } from './payment-form.props';
import './payment-form.styles.scss';

export const PaymentForm = forwardRef<PaymentFormHandle, PaymentProps>(
    ({ creditCard, onSubmit, inputVariant }, ref) => {
        const { cardNumber, nameOnCard, expMonth, expYear, zipCode, fsaCard } = creditCard || {};

        const { width } = useWindowDimensions();
        const { t } = useTranslation();

        const monthsOptions = useMemo(() => toDropdownOption(monthOptionsNumberValue, 'key', 'key', 'value'), []);
        const expYearsOptions = useMemo(() => toDropdownOption(getExpYears({ dates: 9 }), 'key', 'key', 'label'), []);
        const acceptedCards = useMemo(
            () =>
                ccBrands.map((brand) => (
                    <CreditCardIcon variant={brand} key={brand} style={{ width: 36, height: 24 }} />
                )),
            []
        );

        const initialValues: ProfileAddPaymentObjectPayload = {
            cardNumber: cardNumber ?? '',
            nameOnCard: nameOnCard ?? '',
            expMonth: expMonth ?? '',
            expYear: expYear ?? '',
            zipCode: zipCode ?? '',
            fsaCard: fsaCard ?? false,
            cvcNumber: ''
        };

        const formik = useFormik({
            initialValues,
            validateOnChange: true,
            validateOnBlur: false,
            validationSchema: PAYMENT_SCHEMA_V3(t),
            onSubmit
        });

        const isInvalidDateError =
            formik.errors.expMonth && !formik.errors.expYear && !formik.errors.expMonth.includes('required');

        // Exposing formik.submitForm() to parent through ref
        useImperativeHandle(ref, () => ({
            ...formik
        }));

        return (
            <form className="payment-form" noValidate onSubmit={formik.handleSubmit}>
                <div className="payment-form__container">
                    <TextInputComponent
                        {...formik.getFieldProps('cardNumber')}
                        maxLength={16}
                        onlyNumbers
                        label={t('forms.payment.labels.cardNumber')}
                        error={formik.touched.cardNumber && formik.errors.cardNumber}
                        variant={inputVariant}
                        autoComplete="billing cc-number"
                    />

                    <div className="payment-form__field-group">
                        <DropdownComponent
                            {...formik.getFieldProps('expMonth')}
                            canSearch
                            onlyNumbers
                            maxLength={2}
                            label={t('membership.paymentMethod.form.labels.expMonth')}
                            options={monthsOptions}
                            onSelect={(selectedValue) => formik.setFieldValue('expMonth', selectedValue)}
                            variant={inputVariant}
                            autoComplete="billing cc-exp-month"
                            error={
                                (formik.touched.expMonth &&
                                    formik.errors.expMonth?.includes('required') &&
                                    formik.errors.expMonth) ||
                                isInvalidDateError
                            }
                        />
                        <DropdownComponent
                            {...formik.getFieldProps('expYear')}
                            canSearch
                            onlyNumbers
                            maxLength={4}
                            label={t('membership.paymentMethod.form.labels.expYear')}
                            options={expYearsOptions}
                            onSelect={(selectedValue) => formik.setFieldValue('expYear', selectedValue)}
                            variant={inputVariant}
                            autoComplete="billing cc-exp-year"
                            error={(formik.touched.expYear && formik.errors.expYear) || isInvalidDateError}
                        />
                        <TextInputComponent
                            {...formik.getFieldProps('cvcNumber')}
                            label={t('membership.paymentMethod.form.labels.cvcNumber')}
                            maxLength={4}
                            onlyNumbers
                            contentRight={{
                                children: (
                                    <Tooltip
                                        position={width <= BREAKPOINTS.sm ? 'left' : 'top'}
                                        tip={t('pages.profile.payment.labels.cvcNumberToolTip')}
                                    >
                                        <Icon icon="help-info" style={{ cursor: 'pointer' }} />
                                    </Tooltip>
                                )
                            }}
                            autoComplete="billing cc-csc"
                            variant={inputVariant}
                            error={formik.touched.cvcNumber && formik.errors.cvcNumber}
                        />
                    </div>

                    {isInvalidDateError && formik.errors.expMonth && (
                        <ToastBox variant="error-v2" icon="error-v2">
                            {t(formik.errors.expMonth)}
                        </ToastBox>
                    )}

                    <div className="payment-form__field">
                        <TextInputComponent
                            {...formik.getFieldProps('nameOnCard')}
                            onlyLetters
                            label={t('forms.payment.labels.nameOnCard')}
                            variant={inputVariant}
                            autoComplete="billing cc-name"
                            error={formik.touched.nameOnCard && formik.errors.nameOnCard}
                        />
                    </div>

                    <div className="payment-form__field">
                        <TextInputComponent
                            onlyNumbers
                            maxLength={5}
                            {...formik.getFieldProps('zipCode')}
                            label={t('membership.paymentMethod.form.labels.zipCode')}
                            variant={inputVariant}
                            autoComplete="billing postal-code"
                            error={formik.touched.zipCode && formik.errors.zipCode}
                        />
                    </div>

                    <div className="payment-form__field payment-form__field--checkbox">
                        <CheckboxComponent
                            {...formik.getFieldProps('fsaCard')}
                            label={t('membership.paymentMethod.form.labels.fsaHsaCard')}
                            hasError={formik.touched.fsaCard && !!formik.errors.fsaCard}
                            labelColor="slate"
                        />
                    </div>

                    <Text letterSpacing={1} lineHeight={21} size={14} color="slate" className="payment-form__info">
                        <Trans>{t('membership.paymentMethod.form.labels.paymentInfoCopy')}</Trans>
                    </Text>

                    <div className="payment-form__accepted-cards">{acceptedCards}</div>
                </div>
            </form>
        );
    }
);
